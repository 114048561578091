import React, { useContext } from 'react';
import moment from 'moment';
import { FlexMiddle } from '../../Flex';
import ConfigContext from '../../../contexts/ConfigContext';

const DateChip = ({ value }) => {
  const { shortDateFormat } = useContext(ConfigContext);
  return <FlexMiddle>{moment(value).format(shortDateFormat)}</FlexMiddle>;
};

export default DateChip;
