import { useQuery } from 'react-query';
import createCachedSelector from 're-reselect';
import * as api from '../../services/api';
import { mappedArray } from '../../utils/array';
import useCRUD from '../useCRUD';
import { SORT_BY_STRING } from '../../utils/sorting';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data = []) => {
    const sorter = SORT_BY_STRING('slug');

    const fields = (data || []).map(({ slug, ...rest }) => ({ slug, name: slug, ...rest })).sort(sorter);
    const mappedFields = mappedArray(data || []);

    return { fields, mappedFields };
  }
)({
  keySelector: (_, storeKey) => `${storeKey.join('#')}`
});

const usePayrollFields = () => {
  const { accountId } = useAccount();

  const storeKey = ['payroll-fields', accountId];

  const { isLoading, data } = useQuery(storeKey, () => api.fetchPayrollFields({ accountId }), {
    select: (payrollFieldsData) => selector(payrollFieldsData, storeKey)
  });

  const { addItem, editItem, deleteItem } = useCRUD(
    storeKey,
    {
      addApi: (field) => api.addPayrollField({ accountId, field }),
      editApi: ({ id, ...field }) => api.updatePayrollField({ accountId, fieldId: id, field }),
      deleteApi: ({ id }) => api.deletePayrollField({ accountId, fieldId: id })
    },
    { refetchOnSuccessDelete: true }
  );

  const { fields = [], mappedFields = {} } = data || {};

  return {
    isLoading,
    fields,
    addField: addItem,
    updateField: editItem,
    deleteField: deleteItem,
    mappedFields
  };
};

export default usePayrollFields;
