import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { SORT_BY_STRING, SORT_BY_DATE } from '@ubeya/shared/utils/sorting';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { Table as BaseTable } from '@ubeya/shared-web/components';
import { DetailsCell } from './DetailsCell';
import { StatusCell } from './StatusCell';
import { OrderStatusSelect } from './OrderStatusSelect';

const SORT_MAP = {
  dates: SORT_BY_DATE('date'),
  projectName: SORT_BY_STRING('projectName'),
  status: SORT_BY_STRING('status'),
  canceledBy: SORT_BY_STRING('canceledBy'),
  reason: SORT_BY_STRING('reason')
};

const Container = styled.div`
  padding: 20px 40px;
  width: 100%;
`;

const Table = styled(BaseTable)`
  margin-bottom: 20px;
  height: 300px;
`;

const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export const OrdersTab = ({ onOpenOrderDetails, data, isLoading }) => {
  const { t, shortDateFormat } = useContext(ConfigContext);
  const [statusFilter, setStatusFilter] = useState();
  const [sort, setSort] = useState({ sortBy: 'dates', sortDirection: 'asc' });

  const columns = [
    { Header: t('dates'), dataKey: 'dates' },
    { Header: t('projectName'), dataKey: 'projectName' },
    {
      Header: t('status'),
      dataKey: 'status',
      Cell: StatusCell,
      cellRendererProps: {
        style: {
          width: '100%',
          height: '100%'
        }
      }
    },
    { Header: t('canceledBy'), dataKey: 'canceledBy' },
    { Header: t('reason'), dataKey: 'reason' },
    {
      Header: t('details'),
      dataKey: 'id',
      Cell: ({ rowData: { id } }) => {
        return (
          <DetailsCell
            onClick={() => onOpenOrderDetails(data.find(({ id: orderId }) => orderId === id))}
            key={`details_column_${id}`}
          />
        );
      },
      sortable: false,
      cellRendererProps: {
        style: {
          margin: '0 auto'
        }
      }
    }
  ];

  const dateFormat = `${shortDateFormat}, ddd`;

  const tableData = useMemo(
    () =>
      data
        .filter(({ status }) => !statusFilter || status === statusFilter)
        .map((order) => ({
          id: order.id,
          date: order.project.date,
          dates: order.project.isMultiDay
            ? `${moment(order.project.date).format(dateFormat)} - ${moment(order.project.endDate).format(dateFormat)}`
            : moment(order.project.date).format(dateFormat),
          projectName: order.project.name,
          status: order.status,
          canceledBy: order.canceledBy && `${order.canceledBy.firstName} ${order.canceledBy.lastName}`,
          reason: order.cancellationReason
        }))
        .sort((a, b) => (sort.sortDirection === 'asc' ? SORT_MAP[sort.sortBy](a, b) : SORT_MAP[sort.sortBy](b, a))),
    [data, dateFormat, sort, statusFilter]
  );

  return (
    <Container>
      <Filters>
        <OrderStatusSelect value={statusFilter} onChange={setStatusFilter} />
      </Filters>
      <Table
        setSort={setSort}
        sortBy={sort.sortBy}
        sortDirection={sort.sortDirection}
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        sortable
      />
    </Container>
  );
};
