import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PDFIcon } from '@ubeya/shared-web/assets/pdf-file.svg';
import { ReactComponent as PPTXIcon } from '@ubeya/shared-web/assets/pptx-file.svg';
import { ReactComponent as DOCXIcon } from '@ubeya/shared-web/assets/docx-file.svg';
import { ReactComponent as XLSIcon } from '@ubeya/shared-web/assets/xls-file.svg';
import { ReactComponent as DefaultFileIcon } from '@ubeya/shared-web/assets/default-file.svg';
import { TextWithTooltip } from '@ubeya/shared-web/components';

const Doc = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div.attrs(() => ({ className: 'document-title' }))`
  font-size: 10px;
  padding: 2px;
`;

const SubTitle = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.gray400};
`;

const DocumentWrapper = styled.div.attrs(() => ({ className: 'document-wrapper' }))`
  display: flex;
  width: max-content;
  height: 50px;
  border-radius: 8px;
  height: 50px;
  padding: 2px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  ${({ onClick }) => !!onClick && 'cursor: pointer'};
`;

const DocumentPreview = ({ subTitle, title, extension, onClick }) => {
  const FileIcon = () => {
    switch (extension.toLowerCase()) {
      case 'pdf':
        return <PDFIcon />;
      case 'pptx':
        return <PPTXIcon />;
      case 'ppt':
        return <PPTXIcon />;
      case 'docx':
        return <DOCXIcon />;
      case 'xlsx':
        return <XLSIcon />;
      case 'xls':
        return <XLSIcon />;
      default:
        return <DefaultFileIcon />;
    }
  };
  return (
    <DocumentWrapper onClick={onClick}>
      <FileIcon />
      <Doc>
        <Title>
          <TextWithTooltip>{title?.slice(0, 30)}</TextWithTooltip>
        </Title>
        {subTitle && <SubTitle>{subTitle.toUpperCase()}</SubTitle>}
      </Doc>
    </DocumentWrapper>
  );
};

export default DocumentPreview;
