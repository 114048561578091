import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { toaster } from '../atoms/shared';

const useShowToaster = () => {
  const [messages, setMessages] = useRecoilState(toaster);

  const removeFirst = useCallback(() => setMessages(messages.slice(1)), [messages, setMessages]);

  const removeByIndex = useCallback((index) => setMessages(messages.toSpliced(index, 1)), [messages, setMessages]);

  return { data: messages, removeFirst, removeByIndex };
};

export default useShowToaster;
