import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';

const BooleanChip = ({ value }) => {
  const { t } = useContext(ConfigContext);

  if (Array.isArray(value)) {
    return <>{value.map((type) => (type ? t('yes') : t('no'))).join(', ')}</>;
  }

  return <>{value ? t('yes') : t('no')}</>;
};

export default BooleanChip;
