import React, { useContext } from 'react';
import { ORDER_STATUSES } from '@ubeya/shared/constants';
import styled from 'styled-components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const Status = styled.div`
  padding: 6px 10px;
  width: 100%;
  text-align: center;
`;

export const StatusCell = ({ rowData: { status } }) => {
  const { t } = useContext(ConfigContext);
  const orderStatus = Object.values(ORDER_STATUSES).find(({ label }) => label === status);

  const style = {};

  if (orderStatus.hideInSchedulingContext) {
    return <></>;
  }

  if (orderStatus) {
    style.background = orderStatus.background;
    style.color = orderStatus.color;
  }

  return <Status style={style}>{t(orderStatus.label)}</Status>;
};
