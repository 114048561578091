import styled from 'styled-components';

const DotBadge = styled.span`
  margin-left: 4px;
  min-width: 9px;
  min-height: 9px;
  font-weight: normal;
  font-size: 13rem;
  line-height: 20rem;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || 'rgba(0, 0, 0, 0.08)'};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ borderColor, theme }) => borderColor || theme.colors.gray200};
`;

export default DotBadge;
