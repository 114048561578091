import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '@ubeya/shared-web/components';
import ConfigContext from '../../contexts/ConfigContext';

const ViewButton = styled(Button)`
  background: transparent;
`;

export const DetailsCell = ({ onClick }) => {
  const { t } = useContext(ConfigContext);

  return (
    <ViewButton type="button" tertiary onClick={onClick}>
      {t('view')}
    </ViewButton>
  );
};
