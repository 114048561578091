import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { QueryClientProvider, QueryClient } from 'react-query';

import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import interceptorCreator from '@ubeya/shared/utils/refreshTokenInterceptor';
import GlobalStyle from '@ubeya/shared-web/style/global';
import themeMaker from '@ubeya/shared-web/style/themeMaker';
import { Toaster } from '@ubeya/shared-web/components';
import App from 'app';

const history = createBrowserHistory();
interceptorCreator('admin');

const REACT_QUERY_CONFIG = { defaultOptions: { queries: { staleTime: Infinity, refetchOnWindowFocus: false } } };

const theme = themeMaker({
  primary: '#181D23',
  primaryLight: '#404B59',
  primarySemiLight: '#404B59',
  primaryDark: '#282F38',
  backgroundDark: '#282F38'
});

const queryClient = new QueryClient(REACT_QUERY_CONFIG);

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <GlobalStyle />
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Toaster />
          <App />
        </QueryClientProvider>
      </RecoilRoot>
    </Router>
  </ThemeProvider>
);
