import React, { useState, useCallback, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer } from 'react-virtualized';
import { InfiniteList } from '../List';
import useMenu from '../../hooks/useMenu';
import { FlexMiddle } from '../Flex';
import { Card } from '../Card';
import { body } from '../Typography';
import { Loader as BaseLoader } from '../Loader';
import usePressKey from '../../hooks/usePressKey';

const Wrapper = styled(FlexMiddle)`
  position: relative;
  height: 100%;
  border-radius: 3px;

  > svg {
    position: absolute;
    right: 10px;
  }
`;

const Overlay = styled.span`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  ${body};
  border-radius: 5px;
  padding: 10px;
  &:focus {
    box-shadow: ${({ theme }) => theme.colors.inputShadow};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
`;

const OptionsWrapper = styled(Card).attrs(() => ({ className: 'options' }))`
  z-index: 5;
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}
`;

const OptionWrapper = styled.div`
  > div {
    cursor: pointer;
  }

  &:hover > div {
    background: ${({ theme }) => theme.colors.background};
  }

  ${({ theme, isSelected }) => isSelected && ` background: ${theme.colors.background};`}
`;

const Options = ({
  wrapperRef,
  menuRef,
  style,
  supportEmptyState,
  width,
  handleSelect,
  renderRow,
  hook,
  params,
  search,
  renderBottom
}) => {
  const { data: allOptions, fetchMore, canFetchMore, isLoading } = hook({ enabled: true, search, ...params });

  const data = useMemo(
    () => (supportEmptyState && !isLoading ? [...allOptions, { emptyState: true, value: search }] : allOptions),
    [allOptions, supportEmptyState, isLoading, search]
  );

  const menuOptionsRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);

  // TAB
  usePressKey({
    which: 9,
    ref: wrapperRef,
    onSelect: () => menuOptionsRef.current.querySelector(`.option-${selectedIndex}`)?.firstChild?.click?.()
  });

  // ENTER
  usePressKey({
    which: 13,
    ref: wrapperRef,
    onSelect: () => menuOptionsRef.current.querySelector(`.option-${selectedIndex}`)?.firstChild?.click?.()
  });

  // UP
  usePressKey({
    which: 38,
    ref: wrapperRef,
    onSelect: () => {
      const newSelectedIndex = selectedIndex > 0 ? selectedIndex - 1 : selectedIndex;
      setSelectedIndex(newSelectedIndex);
      menuOptionsRef.current.querySelector(`.option-${newSelectedIndex}`).scrollIntoView({ block: 'nearest' });
    }
  });

  // DOWN
  usePressKey({
    which: 40,
    ref: wrapperRef,
    onSelect: () => {
      const newSelectedIndex = selectedIndex < data.length - 1 ? selectedIndex + 1 : selectedIndex;
      setSelectedIndex(newSelectedIndex);
      menuOptionsRef.current.querySelector(`.option-${newSelectedIndex}`).scrollIntoView({ block: 'nearest' });
    }
  });

  return (
    <>
      {isLoading && <Loader size="small" />}

      <OptionsWrapper
        ref={(node) => {
          menuRef(node);
          menuOptionsRef.current = node;
        }}
        innerStyle={style}>
        <InfiniteList
          height={Math.min(200, data.length * 40)}
          rowCount={data.length}
          rowHeight={40}
          width={width || 0}
          overscanRowCount={10}
          canFetchMore={canFetchMore}
          fetchMore={fetchMore}
          rowRenderer={({ index, style: rowStyle }) => (
            <OptionWrapper
              key={index}
              style={rowStyle}
              className={`option-${index}`}
              isSelected={index === selectedIndex}
              onClick={handleSelect}>
              {renderRow({ rowData: data[index] })}
            </OptionWrapper>
          )}
        />
        {renderBottom}
      </OptionsWrapper>
    </>
  );
};

// hook must return data, fetchMore and canFetchMore
const AsyncSearch = ({
  hook,
  params = {},
  renderRow,
  className,
  placeholder,
  supportEmptyState = false,
  usePortal,
  renderBottom,
  overlay = false
}) => {
  const [value, setValue] = useState('');
  const { Portal, wrapperRef, isOpen, setOpen, setClose, style, menuRef } = useMenu({ usePortal });

  const handleSelect = useCallback(() => {
    setClose();
    setValue('');
  }, [setClose]);

  return (
    <>
      {overlay && isOpen && <Overlay />}

      <Wrapper ref={wrapperRef} className={className}>
        <Input value={value} onChange={(e) => setValue(e.target.value)} onFocus={setOpen} placeholder={placeholder} />

        {isOpen && (
          <AutoSizer disableHeight>
            {({ width }) => (
              <Portal>
                <Options
                  {...{
                    wrapperRef,
                    menuRef,
                    style,
                    width,
                    supportEmptyState,
                    handleSelect,
                    renderRow,
                    hook,
                    params,
                    search: value,
                    renderBottom
                  }}
                />
              </Portal>
            )}
          </AutoSizer>
        )}
      </Wrapper>
    </>
  );
};

export default AsyncSearch;
