import { useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  convertToMs,
  getLastActivityTime,
  removeLastActivityTime,
  setLastActivityTime,
  toFixedNumber
} from './inactivityUtils';

const INACTIVITY_INTERVAL_STEP = 10 * 1000; // 10 seconds
let defaultInactivityTimeout = 20 * 60 * 1000; // 20 minutes
let inactivityIntervalInstance = null;

const resetInactivityInterval = () => {
  if (inactivityIntervalInstance) {
    setLastActivityTime();
  }
};

// debounce - instead of writing to the local storage on each movement, add debounce to sustain the writing
const debounceResetInactivityInterval = debounce(resetInactivityInterval, 200);

const getLastActiveTimeDiff = () => {
  const lastActivityTime = getLastActivityTime();
  return lastActivityTime > 0 ? toFixedNumber(Date.now() - lastActivityTime) : 0;
};

const addInactiveListener = () => {
  window.addEventListener('mousemove', debounceResetInactivityInterval);
  window.addEventListener('click', debounceResetInactivityInterval);
  window.addEventListener('scroll', debounceResetInactivityInterval);
};

const removeInactiveListener = () => {
  window.removeEventListener('mousemove', debounceResetInactivityInterval);
  window.removeEventListener('click', debounceResetInactivityInterval);
  window.removeEventListener('scroll', debounceResetInactivityInterval);
};

const useInactivityTimeout = ({ isAuth, logout, isUbeyaAdmin }) => {
  const isUserInactive = useCallback(() => {
    const lastActivityTime = getLastActivityTime();
    const diff = moment.duration(moment(Date.now()).diff(moment(lastActivityTime))).asSeconds();
    return toFixedNumber(defaultInactivityTimeout / 1000 - diff) <= 0;
  }, []);

  const stopInactivityInterval = useCallback(() => {
    clearInterval(inactivityIntervalInstance);
    inactivityIntervalInstance = null;
    removeInactiveListener();
    removeLastActivityTime();
  }, []);

  const inactivityInterval = useCallback(() => {
    if (isUserInactive()) {
      stopInactivityInterval();
      removeLastActivityTime();
      logout('admin');
    }
  }, [isUserInactive, logout, stopInactivityInterval]);

  const startInactivityInterval = useCallback(
    (options = {}) => {
      // remove leftovers
      removeInactiveListener();

      const { accountInactivityInterval = 0, accountInactivityIntervalPeriod = 'minutes' } = options;
      if (!!inactivityIntervalInstance || accountInactivityInterval === 0 || !isAuth || isUbeyaAdmin) {
        return;
      }

      // from settings
      defaultInactivityTimeout = convertToMs(accountInactivityInterval, accountInactivityIntervalPeriod);
      const lastActiveTime = getLastActiveTimeDiff();

      if (!lastActiveTime) {
        setLastActivityTime();
      }

      // run immediately to check if user need to be logged out
      inactivityInterval();
      addInactiveListener();

      // check inactivity status every INACTIVITY_INTERVAL_STEP
      inactivityIntervalInstance = setInterval(inactivityInterval, INACTIVITY_INTERVAL_STEP);
      return stopInactivityInterval;
    },
    [inactivityInterval, isAuth, isUbeyaAdmin, stopInactivityInterval]
  );

  return {
    startInactivityInterval,
    stopInactivityInterval,
    setLastActivityTime,
    getLastActivityTime,
    removeLastActivityTime,
    resetInactivityInterval
  };
};

export default useInactivityTimeout;
