import React, { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { ReactComponent as BaseOrderIndicationIcon } from '@ubeya/shared-web/assets/ListView/exclamation_mark.svg';
import Banner from '../Banner';

const OrderIndicationIcon = styled(BaseOrderIndicationIcon)`
  fill: ${({ theme }) => theme.colors.surface};
`;

const HandleCancellationBanner = () => {
  const { t } = useContext(ConfigContext);

  return (
    <Banner
      Icon={OrderIndicationIcon}
      headerText={t('handleCancelation')}
      bodyText={<Trans>canceledOrderDontForgetToUnbook</Trans>}
    />
  );
};

export default HandleCancellationBanner;
