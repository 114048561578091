import React from 'react';
import { Field } from 'react-final-form';
import Label from '../Label';
import RatingBar from '../RatingBar';

const RatingField = ({ title, name, validate, className, ...restProps }) => (
  <Field
    name={name}
    validate={validate}
    render={({ input }) => (
      <Label title={title} className={className}>
        <RatingBar {...input} {...restProps} />
      </Label>
    )}
  />
);

export default RatingField;
