import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';
import ConfigContext from '../../contexts/ConfigContext';
import { FlexCenter } from '../Flex';
import Select from '../Select';

const Wrapper = styled.div`
  position: relative;
  min-width: 300px;

  .CalendarMonth {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CalendarMonth_caption {
    max-width: 65%;
    padding-top: 18px;

    ${({ renderSelects }) =>
      renderSelects
        ? css`
            padding-top: 18px;
          `
        : css`
            padding-bottom: 60px;
            padding-top: 30px;
          `}

    ${isMobileOnly &&
    css`
      max-width: 60%;
    `}
  }

  .CalendarDay__default {
    border: none;
  }

  .DayPickerNavigation_button__default {
    height: 48px;
    width: 48px;
    display: flex;
    border-color: #d9d9d9;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    :hover {
      border-color: rgba(25, 25, 25, 0.6);
    }
  }

  .CalendarDay__today {
    background-color: ${({ theme }) => theme.colors.calendarToday};
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  .CalendarMonthGrid,
  .CalendarMonthGrid_1,
  .CalendarMonthGrid__horizontal,
  .CalendarMonthGrid__horizontal_2 {
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(${({ isRtl }) => (isRtl ? 0 : -300)}) !important;
  }

  .DayPicker,
  .DayPicker_1,
  .DayPicker__horizontal,
  .DayPicker__horizontal_2 {
    width: 100% !important;
    ${({ renderSelects }) =>
      renderSelects &&
      css`
        min-width: 420px;
      `}
    max-width: 440px;

    > div > div {
      width: unset !important;
    }

    ${isMobileOnly &&
    css`
      min-width: unset;
    `}
  }

  .DayPicker_transitionContainer,
  .DayPicker_transitionContainer_1,
  .DayPicker_transitionContainer__horizontal,
  .DayPicker_transitionContainer__horizontal_2 {
    width: 100% !important;
  }

  .CalendarMonthGrid,
  .CalendarMonthGrid_1,
  .CalendarMonthGrid__horizontal,
  .CalendarMonthGrid__horizontal_2 {
    width: 100% !important;
  }

  .DayPicker_weekHeaders,
  .DayPicker_weekHeaders_1,
  .DayPicker_weekHeaders__horizontal,
  .DayPicker_weekHeaders__horizontal_2 {
    width: 100% !important;
    top: 20px;
    margin-left: 0;
  }

  .CalendarMonth_table,
  .CalendarMonth_table_1 {
    width: 100% !important;
  }

  .DayPicker_weekHeaders,
  .DayPicker_weekHeaders_1,
  .DayPicker_weekHeaders__horizontal,
  .DayPicker_weekHeaders__horizontal_2 > div {
    width: 100% !important;
  }

  #datepicker-container > div > div > div {
    width: 100% !important;
  }

  .DayPicker_weekHeader_ul,
  .DayPicker_weekHeader_ul_1 {
    display: flex;
    justify-content: space-around;
  }

  .DayPicker_weekHeader_li,
  .DayPicker_weekHeader_li_1 {
    width: unset !important;
    flex: 1;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }
`;

const CalendarSelect = styled(Select)`
  .select {
    border-radius: 4px;
    height: 48px;
  }

  .select-menu {
    max-height: 200px;
  }
`;

const CalendarSelectMonth = styled(CalendarSelect)`
  flex: 2;
`;

const CalendarSelectYear = styled(CalendarSelect)`
  flex: 1;
  min-width: 100px;
  ${isMobileOnly &&
  css`
    flex: 2;
  `}
`;

const Header = styled(FlexCenter)`
  z-index: 10;
  gap: 8px;
`;

const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const DatePicker = ({
  value,
  onChange,
  className,
  menuRef,
  style,
  isDayBlocked,
  isOutsideRange,
  renderSelects = true,
  ...restProps
}) => {
  const { t } = useContext(ConfigContext);
  const { isRtl } = useContext(ConfigContext);

  const months = useMemo(() => MONTHS.map((month, index) => ({ label: t(`long_${month}`), value: index })), [t]);

  const years = useMemo(
    () =>
      [...Array(200)].map((_, index) => ({
        label: (moment().year() - index + 100).toString(),
        value: (moment().year() - index + 100).toString()
      })),
    []
  );

  return (
    <Wrapper className={className} ref={menuRef} style={style} isRtl={isRtl} renderSelects={renderSelects}>
      <DayPickerSingleDateController
        date={value ? moment(value) : moment()}
        onDateChange={(date) => onChange(date.toISOString())}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        focused
        transitionDuration={0}
        isRTL={isRtl}
        isDayBlocked={isDayBlocked} // specify individual dates that should be blocked
        isOutsideRange={isOutsideRange} // specify which dates should be considered outside of the valid range of selectable dates
        renderWeekHeaderElement={(day) => t(day.toLowerCase())}
        renderMonthElement={
          !renderSelects
            ? undefined
            : ({ month, onMonthSelect, onYearSelect }) => {
                const selectedMonth = month.month();
                const selectedYear = month.format('YYYY');
                return (
                  <Header>
                    <CalendarSelectMonth
                      value={selectedMonth}
                      onChange={(newMonth) => onMonthSelect(month, newMonth)}
                      options={months}
                      usePortal
                    />
                    <CalendarSelectYear
                      value={selectedYear}
                      onChange={(newYear) => onYearSelect(month, newYear)}
                      options={years}
                      usePortal
                    />
                  </Header>
                );
              }
        }
        {...restProps}
      />
    </Wrapper>
  );
};

export default DatePicker;
