import React from 'react';
import useCRM from '@ubeya/shared/hooks/account/useCRM';
import { FlexMiddle } from '../../Flex';

const RestrictedClientChip = ({ value }) => {
  const { mappedClients } = useCRM();

  return <FlexMiddle>{mappedClients[value]?.name}</FlexMiddle>;
};

export default RestrictedClientChip;
