import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import star from '../../assets/star.svg';
import starFill from '../../assets/star-fill.svg';
import { Small } from '../Typography';
import { FlexMiddle } from '../Flex';
import ConfigContext from '../../contexts/ConfigContext';
import Tooltip from '../Tooltip/Tooltip';

const Container = styled(FlexMiddle)`
  flex-direction: row-reverse;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-end')};
`;

const StarIcon = styled.div`
  width: 18px;
  height: 18px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  background: url(${({ selected }) => (selected ? starFill : star)});
  background-repeat: no-repeat;
  margin-right: 2px;

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover ~ &,
      &:hover {
        background: url(${starFill}) !important;
        background-repeat: no-repeat !important;
      }
    `}
`;

const Text = styled(Small)`
  color: ${({ theme }) => theme.colors.gray400};
`;

const RatingDescription = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray200};
  font-size: 12px;
  font-weight: lighter;
`;

const RatingBar = ({
  isLocked,
  className,
  rating,
  reviews,
  onChange,
  stars = 5,
  center = true,
  hideZeroRating = false
}) => {
  const { t } = useContext(ConfigContext);

  if (!rating && hideZeroRating) {
    return <></>;
  }

  return (
    <div className={className}>
      {reviews && <Text>{t('ratingBaseOnReviews', { rating, reviews })}</Text>}

      <Container $center={center}>
        {[...Array(stars)].map((_, index) => (
          <Tooltip key={index} disabled={isLocked} body={t(`employeeRating${stars - index}`)}>
            <StarIcon
              key={index}
              selected={rating >= 5 - index}
              onClick={
                isLocked
                  ? null
                  : onChange
                  ? (e) => {
                      e.stopPropagation();
                      return onChange(5 - index);
                    }
                  : undefined
              }
            />
          </Tooltip>
        ))}
      </Container>

      {rating && <RatingDescription>{t(`employeeRating${parseInt(rating)}`)}</RatingDescription>}
    </div>
  );
};

export default RatingBar;
