import React from 'react';
import { DocumentPreview } from '..';
import { getExtension } from '../../utils/files';

const DocumentField = ({ metadata }) => {
  const extension = metadata ? getExtension(metadata, 'path') : '';

  return <DocumentPreview title={metadata?.title || ''} subTitle={extension} extension={extension} />;
};

export default DocumentField;
