import React from 'react';
import BaseTextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';
import scrollbar from '../../style/scrollbar';
import { body } from '../Typography';

const TextareaAutosize = styled(
  ({ showResizeIcon: _showResizeIcon, textAreaRef, autofocus, error: _error, ...rest }) => {
    const props = {
      ...(textAreaRef ? { ref: textAreaRef } : {}),
      ...(autofocus ? { autoFocus: true } : {}),
      ...rest
    };
    return <BaseTextareaAutosize {...props} />;
  }
)`
  ${body};
  ${scrollbar};
  border-radius: 2px;
  width: ${({ enablePreviewModal }) => (enablePreviewModal ? '90%' : '100%')};
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  outline: 0;
  padding: 10px;
  background: ${({ theme }) => theme.colors.surface};
  min-height: 44px;
  resize: vertical;

  ${({ theme, error }) => error && `border-color: ${theme.colors.failure}; background:${theme.colors.failureLight};`}
  ${({ showResizeIcon }) => !showResizeIcon && 'resize: none'};

  &:disabled {
    color: ${({ theme }) => theme.colors.gray800};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
  }

  &:read-only {
    background: transparent;
  }

  ${({ $isPreview, theme }) =>
    $isPreview &&
    css`
      background-color: ${theme.colors.positions.labelBackground};
      border: none;
      padding: 20px;
      width: 100%;

      &:hover {
        background-color: ${theme.colors.positions.labelBackground} !important;
      }
    `};
`;

export default TextareaAutosize;
