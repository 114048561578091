import React, { useContext } from 'react';
import { PAYROLL_PROCESS_STATUS } from '@ubeya/shared-web/constants';
import ConfigContext from '../../../contexts/ConfigContext';

const PayrollProcessStatusChip = ({ value }) => {
  const { t } = useContext(ConfigContext);

  return <>{value.map((item) => t(PAYROLL_PROCESS_STATUS[item])).join(', ')}</>;
};

export default PayrollProcessStatusChip;
