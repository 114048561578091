import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { BaseErrorTextStyle } from './InputStyle';

const Wrapper = styled.div.attrs(() => ({ className: 'error-text-wrapper' }))``;

const ErrorTextWrapper = styled(BaseErrorTextStyle)``;

const ErrorText = ({ className, error }) => (
  <Wrapper className={className}>
    <ErrorTextWrapper>
      <Trans>{error}</Trans>
    </ErrorTextWrapper>
  </Wrapper>
);

export default ErrorText;
