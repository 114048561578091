import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.img`
  max-width: 40px;
  max-height: 100%;
`;

const EmptyWrapper = styled.div`
  max-width: 40px;
  max-height: 100%;
`;

const PhotoRenderer = ({ rowData: { image } }) => {
  return image ? <Wrapper src={image} /> : <EmptyWrapper> N / A</EmptyWrapper>;
};
export default PhotoRenderer;
