import { useLayoutEffect, useState, useCallback } from 'react';

const useDimensions = (dependencies = [], nodeRef) => {
  const [node, setNode] = useState(nodeRef?.current);
  const ref = useCallback((newNode) => {
    setNode(newNode);
  }, []);

  // Keep track of measurements
  const [dimensions, setDimensions] = useState({
    x: 0,
    y: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0
  });

  // Define measure function
  const measure = useCallback((innerNode) => {
    const rect = innerNode.getBoundingClientRect();
    setDimensions({
      x: rect.left,
      y: rect.top,
      left: rect.left,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      width: rect.width,
      height: rect.height
    });
  }, []);

  useLayoutEffect(() => {
    if (!node) {
      return;
    }

    // Set initial measurements
    measure(node);

    // Observe resizing of element
    const resizeObserver = new ResizeObserver(() => {
      measure(node);
    });

    resizeObserver.observe(node);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, measure, ...dependencies]);

  useLayoutEffect(() => {
    if (nodeRef?.current) {
      setNode(nodeRef?.current);
      measure(nodeRef?.current);
    }
  }, [measure, nodeRef]);

  return { ref, dimensions, node };
};

export default useDimensions;
