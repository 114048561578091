import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import {
  CHAT_FILTERS_SLUGS,
  FORECASTING_FILTERS_EVENTS_SLUGS,
  GENERAL_FILTERS_SLUGS,
  PAY_RATES_FILTERS_EVENTS_SLUGS,
  PAYROLL_FILTERS_EVENTS_SLUGS,
  PROJECT_SCHEDULING_FILTERS_SLUGS,
  TIMESHEET_FILTERS_SLUGS,
  TIMESHEET_TAB_FILTERS_SLUGS
} from '@ubeya/shared-web/constants';
import { FILTERS_IDS } from '../../constants';
import * as api from '../../services/api';
import { uuidv4 } from '../../utils/uuid';
import useAccount from './useAccount';
import useFields, { FieldTypes } from './useFields';

const branchesFilterId = uuidv4();
const approvedFilterId = uuidv4();
const costCenterFilterId = uuidv4();
const payrollProcessId = uuidv4();

const TIMESHEET_TIME_TYPES_FILTERS = [
  GENERAL_FILTERS_SLUGS.WITH_CLOCK_IN,
  GENERAL_FILTERS_SLUGS.WITH_CLOCK_OUT,
  GENERAL_FILTERS_SLUGS.WITH_CHECK_IN,
  GENERAL_FILTERS_SLUGS.WITH_CHECK_OUT,
  GENERAL_FILTERS_SLUGS.WITH_BREAKS
].map((slug) => ({ id: uuidv4(), slug }));

const PROJECT_SCHEDULING_TYPES_FILTERS = [
  PROJECT_SCHEDULING_FILTERS_SLUGS.positions,
  PROJECT_SCHEDULING_FILTERS_SLUGS.locations,
  PROJECT_SCHEDULING_FILTERS_SLUGS.startTime,
  PROJECT_SCHEDULING_FILTERS_SLUGS.endTime,
  PROJECT_SCHEDULING_FILTERS_SLUGS.suppliers,
  PROJECT_SCHEDULING_FILTERS_SLUGS.branchCostCenters
].map((slug) => ({ id: uuidv4(), slug }));

const useFilters = () => {
  const { accountId } = useAccount();
  const { mappedFields } = useFields();
  const { enableCostCenterFeature, enablePayrollProcessFeature } = useContext(ConfigContext);

  const storeKey = ['filters', accountId];
  const { isLoading, data } = useQuery(storeKey, () => api.getFilters({ accountId }));

  const allFiltersData = useMemo(() => data?.data || {}, [data?.data]);

  const filters = useMemo(() => {
    const forecastingReportBasic = (allFiltersData?.events?.basic || [])?.filter(({ slug }) =>
      FORECASTING_FILTERS_EVENTS_SLUGS.includes(slug)
    );

    forecastingReportBasic?.push({ id: branchesFilterId, slug: GENERAL_FILTERS_SLUGS.BRANCHES });

    const timesheetBasic = [
      ...(allFiltersData?.employees?.basic || []).filter(({ slug }) => TIMESHEET_FILTERS_SLUGS.includes(slug)),
      ...(allFiltersData?.events?.basic || []).filter(({ slug }) => TIMESHEET_FILTERS_SLUGS.includes(slug)),
      { id: approvedFilterId, slug: GENERAL_FILTERS_SLUGS.APPROVED },
      ...TIMESHEET_TIME_TYPES_FILTERS
    ];

    const timesheetTabBasic = [
      ...(allFiltersData?.employees?.basic || []).filter(({ slug }) => TIMESHEET_TAB_FILTERS_SLUGS.includes(slug)),
      ...(allFiltersData?.events?.basic || []).filter(({ slug }) => TIMESHEET_TAB_FILTERS_SLUGS.includes(slug)),
      { id: approvedFilterId, slug: GENERAL_FILTERS_SLUGS.APPROVED },
      ...TIMESHEET_TIME_TYPES_FILTERS
    ];

    const payrollBasic = [
      ...(allFiltersData?.employees?.basic || []).filter(({ id }) =>
        [FILTERS_IDS.POSITIONS_FILTER_ID, FILTERS_IDS.START_EMPLOYMENT_DATE].includes(id)
      ),
      ...(allFiltersData?.events?.basic || []).filter(({ slug }) => PAYROLL_FILTERS_EVENTS_SLUGS.includes(slug)),
      { id: approvedFilterId, slug: GENERAL_FILTERS_SLUGS.APPROVED }
    ];

    if (payrollBasic) {
      if (enableCostCenterFeature) {
        payrollBasic.push({ id: costCenterFilterId, slug: GENERAL_FILTERS_SLUGS.COST_CENTER });
      }
      if (enablePayrollProcessFeature) {
        payrollBasic.push({ id: payrollProcessId, slug: 'payrollProcessStatus' });
      }
    }

    const projectSchedulingBasic = PROJECT_SCHEDULING_TYPES_FILTERS.map((currFilter) => {
      return {
        ...currFilter,
        kind: 'project-scheduling'
      };
    }).filter(({ slug }) => {
      return slug === PROJECT_SCHEDULING_FILTERS_SLUGS.branchCostCenters ? !!enableCostCenterFeature : true;
    });

    const projectSchedulingManageBasic = [
      ...projectSchedulingBasic,
      {
        id: 1,
        slug: PROJECT_SCHEDULING_FILTERS_SLUGS.bookingIssues
      },
      {
        id: 2,
        slug: PROJECT_SCHEDULING_FILTERS_SLUGS.bookingStatus
      }
    ].map((currFilter) => {
      return {
        ...currFilter,
        kind: 'project-scheduling-manage'
      };
    });

    const bulkEditShiftsHoursBasic = [
      { id: 1, kind: 'bulk-edit-shifts-hours', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.positions },
      { id: 2, kind: 'bulk-edit-shifts-hours', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.locations },
      { id: 3, kind: 'bulk-edit-shifts-hours', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.startTime },
      { id: 4, kind: 'bulk-edit-shifts-hours', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.endTime }
    ];

    const projectLiveViewBasic = [
      { id: 1, kind: 'project-live-view', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.positions },
      { id: 2, kind: 'project-live-view', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.locations },
      { id: 3, kind: 'project-live-view', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.startTime }
    ];

    const projectOrderStaffBasic = [
      { id: 1, kind: 'project-order-staff', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.positions },
      { id: 2, kind: 'project-order-staff', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.locations },
      { id: 3, kind: 'project-order-staff', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.startTime }
    ];

    const chatBasic = [
      { id: 1, kind: 'chat', slug: CHAT_FILTERS_SLUGS.tags },
      { id: 2, kind: 'chat', slug: CHAT_FILTERS_SLUGS.branches },
      { id: 3, kind: 'chat', slug: CHAT_FILTERS_SLUGS.statuses }
    ];

    const employeesWTDFilteredFields = (allFiltersData?.employees?.fields || []).filter(
      (id) =>
        mappedFields?.[id]?.fieldTypeId === FieldTypes.FIELD_TYPE_OPTIONS ||
        mappedFields?.[id]?.fieldTypeId === FieldTypes.FIELD_TYPE_ADDRESS
    );

    const payRatesFields = {
      basic: [
        ...[
          { id: -1, kind: 'events', slug: GENERAL_FILTERS_SLUGS.BRANCHES },
          { id: -2, kind: 'events', slug: GENERAL_FILTERS_SLUGS.POSITIONS }
        ],
        ...(allFiltersData?.events?.basic || []).filter(({ slug }) => PAY_RATES_FILTERS_EVENTS_SLUGS.includes(slug))
      ],
      fields: []
    };

    return {
      ...allFiltersData,
      'forecasting-report': {
        basic: forecastingReportBasic,
        fields: []
      },
      // timesheet: { basic: timesheetBasic, fields: [] },
      timesheet: { basic: timesheetBasic, fields: allFiltersData?.employees?.fields },
      'timesheet-tab': { basic: timesheetTabBasic, fields: [] },
      payroll: { basic: payrollBasic, fields: [] },
      'project-scheduling': {
        basic: projectSchedulingBasic,
        fields: []
      },
      'project-scheduling-manage': {
        basic: projectSchedulingManageBasic,
        fields: []
      },
      'bulk-edit-shifts-hours': {
        basic: bulkEditShiftsHoursBasic,
        fields: []
      },
      'project-live-view': {
        basic: projectLiveViewBasic,
        fields: []
      },
      'project-order-staff': {
        basic: projectOrderStaffBasic,
        fields: []
      },
      chat: {
        basic: chatBasic,
        fields: []
      },
      'employees-wtd': { basic: [], fields: employeesWTDFilteredFields },
      payrates: payRatesFields
    };
  }, [allFiltersData, enableCostCenterFeature, enablePayrollProcessFeature, mappedFields]);

  return {
    isLoading,
    filters
  };
};

export default useFilters;
