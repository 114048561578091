import React, { useMemo } from 'react';

const getDrawerCheckBoxActions = ({ checkboxData }) => {
  if (!checkboxData) return {};

  return {
    checkAllDrawer: ({ checked, rows }) =>
      checkboxData.toggleBulkIds({ ids: rows.map((row) => row[checkboxData.valueProp]), checkedStatus: checked }),

    getDrawerCheckedStatus: (subRows = []) => {
      const isAllDrawerChecked = subRows.every((row) => checkboxData.isChecked(row));
      return checkboxData.isAllChecked ? !isAllDrawerChecked : isAllDrawerChecked;
    }
  };
};

const useSpecificCheckboxColumn = ({ columns, checkboxData, checkboxRenderer: CheckBox, isGroupedData = true }) => {
  const { getDrawerCheckedStatus, checkAllDrawer } = getDrawerCheckBoxActions({ checkboxData });

  const newColumns = useMemo(() => {
    return checkboxData
      ? [
          {
            Header: () => (
              <CheckBox
                name={`header-checkbox-${Math.random()}`}
                value={checkboxData.isAllChecked}
                onChange={checkboxData.toggleAll}
                indeterminate={!!(checkboxData.value.include || checkboxData.value.exclude)}
              />
            ),
            ...(isGroupedData && {
              Aggregate: ({ rowData: { subRows = [] } }) => {
                if (!subRows.length) return <></>;

                const rows = subRows || [];
                const value = getDrawerCheckedStatus?.(rows);
                const onChange = (checked) => checkAllDrawer?.({ checked, rows });

                return <CheckBox name={`drawer-checkbox-${Math.random()}`} value={value} onChange={onChange} />;
              }
            }),
            dataKey: 'checkbox',
            minWidth: 0,
            maxWidth: 60,
            width: 60,
            sortable: false,
            captureRowClick: false,
            frozen: true,
            Cell: ({ rowData }) => (
              <CheckBox
                name={Math.random()}
                value={checkboxData.isAllChecked ? !checkboxData.isChecked(rowData) : checkboxData.isChecked(rowData)}
                onChange={(checked) => checkboxData.toggleCheck(rowData, checked)}
              />
            )
          },
          ...columns
        ]
      : columns;
  }, [checkAllDrawer, checkboxData, columns, getDrawerCheckedStatus, isGroupedData]);

  return newColumns;
};

export default useSpecificCheckboxColumn;
