import React from 'react';
import { Form } from 'react-final-form';
import { useRecoilValue } from 'recoil';
import Modal from '../Modal';
import usePortalHook from '../../hooks/usePortal';
import { fileUpload } from '../../../shared/atoms/shared';

const FormModal = ({
  initialValues,
  onSubmit,
  validate,
  decorators,
  mutators,
  onClose,
  className,
  children,
  title,
  disabledOnInvalid = false,
  disabledOnPristine = true,
  keepDirtyOnReinitialize = false,
  subscription = undefined,
  ...formModalProps
}) => {
  const { Portal } = usePortalHook({ enabled: true, closeOnClickOutside: false, portalId: 'modal' });
  const isUploadingFile = useRecoilValue(fileUpload);

  return (
    <Portal>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        decorators={decorators}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        mutators={mutators}
        subscription={subscription}
        render={(props) => (
          <Modal
            isForm
            onSubmit={props.handleSubmit}
            className={className}
            closeOnClickOutside={false}
            onClose={onClose}
            disabledConfirm={
              (disabledOnInvalid ? !props.valid : false) || (disabledOnPristine ? props.pristine : false)
            }
            formProps={props}
            loadingConfirm={props.submitting || isUploadingFile}
            submit
            prompt={!props.pristine}
            pristine={props.pristine}
            formSubmitFailed={props.submitFailed && !props.valid}
            title={typeof title === 'function' ? title(props) : title}
            {...formModalProps}>
            {typeof children === 'function' ? children(props) : children}
          </Modal>
        )}
      />
    </Portal>
  );
};

export default FormModal;
