import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const EmptyOptions = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
`;

const CostCenterFilter = ({
  defaultValue,
  handleClose,
  onApply,
  search,
  onChange,
  externalOptions,
  hideLayout = false
}) => {
  const { t } = useContext(ConfigContext);
  const costCenterOptions = (externalOptions.costCenter || []).map(({ id, value }) => ({ value: id, label: value }));
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];

      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? (costCenterOptions || []).filter(
            ({ label }) =>
              t('costCenter').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : costCenterOptions,
    [costCenterOptions, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('costCenter')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions && filteredOptions?.length > 0 ? (
        filteredOptions.map(({ value, label }) => (
          <DropDown.Item key={value}>
            <CheckBox
              name={`cost-center-${value}`}
              value={values.includes(value)}
              label={label}
              onChange={() => handleChange(value)}
            />
          </DropDown.Item>
        ))
      ) : (
        <EmptyOptions>{t('noOptions')}</EmptyOptions>
      )}
    </FilterLayout>
  );
};

export default CostCenterFilter;
