import { useState, useEffect } from 'react';

const usePersistedState = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const currentValue = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    const localValue = key && localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : currentValue;
    return localValue;
  });

  useEffect(() => {
    if (key) {
      value ? localStorage.setItem(key, JSON.stringify(value)) : localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
};

export default usePersistedState;
