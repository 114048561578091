import React from 'react';
import styled from 'styled-components';
import { FlexColumn } from '../Flex';
import ErrorText from './ErrorText';
import { baseInputStyle } from './InputStyle';

const Wrapper = styled(FlexColumn)`
  position: relative;
  width: 100%;
`;

const BaseInput = styled.input`
  ${baseInputStyle};

  .error-text-wrapper {
    position: absolute;
    bottom: -20px;
    left: 10px;
  }
`;

const Input = ({ placeholder, value, onChange, inputRef, type, ...restProps }) => (
  <Wrapper>
    <BaseInput
      onWheel={(e) => e.target.blur()}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      ref={inputRef}
      type={type}
      step="any"
      {...restProps}
    />
    {!!restProps?.error && <ErrorText error={restProps?.error} />}
  </Wrapper>
);

export default Input;
