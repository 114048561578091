export const getSource = (item, attr = 'link') => (item.file ? URL.createObjectURL(item.file) : item[attr]);

export const getExtension = (item, attr = 'link') => {
  const link = item?.file ? item?.file.name : item[attr];
  if (!link) {
    return null;
  }
  return link.split('.').slice(-1).pop().split('?')?.[0];
};

export const getExtensionSigned = (item, attr = 'link') => {
  const link = item?.file ? item?.file.name : item[attr];
  return link.split('?')?.[0]?.split('.').slice(-1).pop();
};
