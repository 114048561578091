import React from 'react';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { FlexMiddle } from '../Flex';
import Button from '../Button';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'banner-wrapper' }))`
  width: 100%;
  height: 60px;
  gap: 10px;
  padding: ${isMobileOnly ? '15px' : '10px 70px'};
  background: ${({ theme }) => theme.colors.mainSideBar};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.surface};
    }
  }
`;

const Container = styled(FlexMiddle).attrs(() => ({ className: 'banner-container' }))`
  color: ${({ theme }) => theme.colors.surface};
  gap: 24px;
`;

const Text = styled(FlexMiddle).attrs(() => ({ className: 'banner-text' }))`
  flex-direction: row;
  text-align: center;
  gap: 10px;
`;

const Header = styled.div`
  font-weight: 600;
`;

const BannerButton = styled(Button)`
  color: ${({ theme }) => theme.colors.surface};
  background: transparent;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.surface}`};
`;

const Banner = ({ Icon, onClick, showButton, headerText, bodyText, buttonText }) => {
  return (
    <Wrapper>
      <Container>
        {Icon && <Icon />}

        <Text>
          <Header>{headerText}</Header>

          {bodyText}
        </Text>

        {showButton && (
          <BannerButton narrow onClick={onClick}>
            {buttonText}
          </BannerButton>
        )}
      </Container>
    </Wrapper>
  );
};

export default Banner;
