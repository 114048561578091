import styled, { css } from 'styled-components';
import { smallBodySemiBold } from '../Typography';
import media from '../../style/media';

const activeTab = css`
  color: ${({ theme }) => theme.colors.gray100};
  border-bottom-color: ${({ theme }) => theme.colors.primary};
`;

export const linkTab = css`
  ${smallBodySemiBold};
  color: ${({ theme }) => theme.colors.gray400};
  text-decoration: none !important;
  margin-right: 20px;
  ${media.desktop`margin-right: 20px;`}

  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding: 10px 0;

  &.active {
    ${activeTab};
  }

  ${({ isActive }) => isActive && activeTab};

  &:hover {
    color: ${({ theme }) => theme.colors.gray100};
  }
`;

export const LinkTab = styled.div.attrs(({ isActive }) => ({ className: isActive ? 'active' : '' }))`
  ${linkTab};
`;
