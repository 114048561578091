import React, { useCallback, useState } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { NumberInput } from '../components';
import { Flex } from '../../Flex';

const Wrapper = styled(Flex)`
  padding: 0 6px;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const AgGridNumberInputEditor = ({ data, colDef: { field }, onValueChange }) => {
  const [value, setValue] = useState(Number(get(data, field) || 0));

  const onChange = useCallback(
    (newValue) => {
      const v = Number(newValue);
      setValue(v);
      onValueChange(v);
    },
    [onValueChange]
  );

  return (
    <Wrapper>
      <NumberInput
        value={value}
        onChange={onChange}
        min={0}
        style={{ width: '100%', height: '100%' }}
        onWheel={(e) => e.target.blur()}
      />
    </Wrapper>
  );
};

export default AgGridNumberInputEditor;
