import { ReactComponent as Comm } from './comm..svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Clients } from './clients.svg';
import { ReactComponent as Finance } from './finance.svg';
import { ReactComponent as Reports } from './reports.svg';
import { ReactComponent as Suppliers } from './suppliers.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Timesheet } from './timesheet.svg';
import { ReactComponent as Workers } from './workers.svg';

import { ReactComponent as CommDark } from './dark/communication.svg';
import { ReactComponent as CalendarDark } from './dark/scheduling.svg';
import { ReactComponent as ClientsDark } from './dark/clients.svg';
import { ReactComponent as FinanceDark } from './dark/finance.svg';
import { ReactComponent as ReportsDark } from './dark/reports.svg';
import { ReactComponent as SuppliersDark } from './dark/suppliers.svg';
import { ReactComponent as SettingsDark } from './dark/settings.svg';
import { ReactComponent as TimesheetDark } from './dark/timesheet.svg';
import { ReactComponent as WorkersDark } from './dark/workers.svg';

import { ReactComponent as CommActive } from './active/communication.svg';
import { ReactComponent as CalendarActive } from './active/scheduling.svg';
import { ReactComponent as ClientsActive } from './active/clients.svg';
import { ReactComponent as FinanceActive } from './active/finance.svg';
import { ReactComponent as ReportsActive } from './active/reports.svg';
import { ReactComponent as SuppliersActive } from './active/suppliers.svg';
import { ReactComponent as SettingsActive } from './active/settings.svg';
import { ReactComponent as TimesheetActive } from './active/timesheet.svg';
import { ReactComponent as WorkersActive } from './active/workers.svg';

import { ReactComponent as NewModule } from './see-submodules.svg';

const ModuleIconsLight = { Calendar, Clients, Comm, Finance, Reports, Settings, Suppliers, Timesheet, Workers };
const ModuleIconsDark = {
  CommDark,
  CalendarDark,
  ClientsDark,
  FinanceDark,
  ReportsDark,
  SuppliersDark,
  SettingsDark,
  TimesheetDark,
  WorkersDark
};
const ModuleIconsActive = {
  CommActive,
  CalendarActive,
  ClientsActive,
  FinanceActive,
  ReportsActive,
  SuppliersActive,
  SettingsActive,
  TimesheetActive,
  WorkersActive
};
const CommonModuleIcons = {
  NewModule
};

const ModuleIcons = { ModuleIconsLight, ModuleIconsDark, ModuleIconsActive, CommonModuleIcons };
export default ModuleIcons;
