export const RULE_PERIOD_MAP_BY_SLUG = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
};

export const RULE_TYPE_MAP_BY_SLUG = {
  BLOCK: 'block',
  FLAG: 'flag'
};
