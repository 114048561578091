import React from 'react';
import useTranslatedOptions from '@ubeya/shared/hooks/useTranslatedOptions';

const EMPLOYEE_TYPE_OPTIONS = [
  { label: 'daily', value: 0 },
  { label: 'shift', value: 1 }
];

const EmployeeTypeChip = ({ value }) => {
  const employeeTypeOptions = useTranslatedOptions(EMPLOYEE_TYPE_OPTIONS);

  return (
    <>
      {value
        .map((item) => employeeTypeOptions.find((employeeTypeOption) => employeeTypeOption.value === item)?.label)
        .join(', ')}
    </>
  );
};

export default EmployeeTypeChip;
