import React, { useContext } from 'react';
import { Field, useField } from 'react-final-form';
import styled from 'styled-components';
import { required } from '@ubeya/shared/utils/form';
import InputField from '../../FormFields/InputField';
import BaseDocumentUpload from '../../FileUpload/DocumentUpload';
import ConfigContext from '../../../contexts/ConfigContext';
import { EditableByAdmins } from './EditableByAdmins';

const DocumentUpload = styled(BaseDocumentUpload)`
  height: 250px;
`;

const DocumentFieldForm = ({ showLimitEditAdminsToggle = false }) => {
  const { t } = useContext(ConfigContext);
  const {
    input: { value, onChange }
  } = useField('src');

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        validate={required}
        required
        autoFocus
        className="full"
      />
      <Field
        name="metadata"
        validate={required}
        render={({ input, meta }) => {
          return (
            <DocumentUpload
              value={value || input.value}
              onChange={(data) => {
                input.onChange(data);
                onChange(data);
              }}
              error={meta.touched && meta.error}
            />
          );
        }}
      />
      {showLimitEditAdminsToggle && <EditableByAdmins />}
    </>
  );
};

export default DocumentFieldForm;
