import React, { useContext } from 'react';
import styled from 'styled-components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { ReactComponent as HiddenIcon } from '@ubeya/shared-web/assets/black-eye-hidden.svg';
import Banner from '../Banner';

const Wrapper = styled.div`
  .banner-wrapper {
    justify-content: center;
    background: ${({ theme }) => theme.colors.schedulingPalette.overbookDark};

    .banner-container {
      gap: 8px;
    }

    .banner-text {
      gap: 0;
      color: ${({ theme }) => theme.colors.gray200};
    }

    svg {
      path {
        fill: ${({ theme }) => theme.colors.gray200};
      }
    }
  }
`;

const OverbookingShiftsBanner = () => {
  const { t } = useContext(ConfigContext);

  return (
    <Wrapper>
      <Banner Icon={HiddenIcon} bodyText={t('overbookingBanner')} />
    </Wrapper>
  );
};

export default OverbookingShiftsBanner;
