import React, { useContext } from 'react';
import { Field, useField } from 'react-final-form';
import styled from 'styled-components';
import { required } from '@ubeya/shared/utils/form';
import InputField from '../../FormFields/InputField';
import { ImageUpload as BaseImageUpload } from '../../FileUpload';
import ConfigContext from '../../../contexts/ConfigContext';
import { EditableByAdmins } from './EditableByAdmins';

const ImageUpload = styled(BaseImageUpload)`
  border-radius: 0;
  height: 250px;
  &&& {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  ${({ $error, theme }) => $error && `border: 1px solid ${theme.colors.failure}`}
`;

const ImageFieldForm = ({ showLimitEditAdminsToggle = false }) => {
  const { t } = useContext(ConfigContext);
  const {
    input: { value, onChange }
  } = useField('src');

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        validate={required}
        required
        autoFocus
        className="full"
      />
      <Field
        name="metadata.path"
        validate={required}
        render={({ input, meta }) => (
          <ImageUpload
            className="full"
            value={value || input.value}
            onChange={(data, files) => {
              input.onChange(data);
              onChange(files);
            }}
            $error={meta.touched && meta.error}
          />
        )}
      />
      {showLimitEditAdminsToggle && <EditableByAdmins />}
    </>
  );
};

export default ImageFieldForm;
