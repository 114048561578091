import React from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';
import sanitizeHtml from 'sanitize-html';

const P = styled.p`
  min-height: 22px;
  overflow-wrap: break-word;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.link};
  text-decoration: none;
  cursor: pointer;
`;

const componentDecorator = (href, text, key) => (
  <Link href={sanitizeHtml(href)} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </Link>
);

const NewlineText = ({ text }) =>
  (text || '').split('\n').map((str, index) => (
    <P key={index}>
      <Linkify componentDecorator={componentDecorator}>{str}</Linkify>
    </P>
  ));

const TextWithLinks = React.memo(({ children }) => <NewlineText text={children} />);

export default TextWithLinks;
