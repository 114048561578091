import styled from 'styled-components';

export const ColumnsListActions = styled.div`
  position: sticky;
  right: 0;
  bottom: 0;
  top: 0;
  ${'' /* opacity: 0; */}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--header-background-color);
  box-shadow: -3px 0 5px -2px rgb(136 136 136 / 30%);
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  grid-column: -1;
`;

export const HeaderRowWrapper = styled.div.attrs(() => ({ className: 'header-row' }))`
  contain: content;
  contain: layout paint;
  display: grid;
  grid-template-columns: var(--template-columns);
  width: var(--row-width);
  position: sticky;
  background-color: var(--header-background-color);
  font-weight: bold;
  z-index: 3;
  grid-template-rows: var(--header-row-height);
  height: var(--header-row-height);
  line-height: var(--header-row-height);
  top: 0;
  touch-action: none;
  min-width: 100%;

  ${'' /* &:hover > ${ColumnsListActions} {
    opacity: 1;
  } */}
`;
