// On adding a privilege here update privilegesMapping variable"
const privileges = {
  UBEYA_ADMIN: -1,
  ACCOUNT_ADMIN: 1,
  BRANCH_ADMIN: 2,
  MANAGER_ADMIN: 3,
  EDIT_PERMISSIONS: 4,
  TIMESHEET: 5,
  PAYROLL: 6,
  DIRECTORY: 7,
  COMMUNICATION: 8,
  CRM: 9,
  SCHEDULING: 10,
  FEED: 11,
  EDIT_HR: 12,
  SEQUENCE: 13,
  BILLING: 14,
  CLIENT_BILLING: 15,
  COMPLIANCE: 16,
  REPORTS: 17,
  DELETE_EMPLOYEE: 18,
  UNLOCK_PAYROLL: 19,
  EXPORT_PROCESS_PAYROLL: 20,
  SUPPLIERS: 21,
  APPROVE_TIMESHEET: 22,
  EDIT_SCHEDULING: 23,
  ADD_EMPLOYEE: 24,
  INVOICE: 25
};

export const PRIVILEGES_CATEGORIES = {
  EMPLOYEES: 'employees',
  SCHEDULING: 'scheduling',
  TIMESHEET: 'timesheet',
  COMMUNICATION: 'communication',
  REPORTS: 'reports',
  CRM: 'crm',
  FINANCE: 'finance',
  WORKFLOWS: 'workflows',
  COMPLIANCE: 'compliance',
  ADDITIONAL: 'additional',
  SUPPLIERS: 'suppliers',
  INVOICE: 'invoice'
};

export const privilegesMappingOrder = {
  [PRIVILEGES_CATEGORIES.EMPLOYEES]: 1,
  [PRIVILEGES_CATEGORIES.SCHEDULING]: 2,
  [PRIVILEGES_CATEGORIES.TIMESHEET]: 3,
  [PRIVILEGES_CATEGORIES.COMMUNICATION]: 4,
  [PRIVILEGES_CATEGORIES.REPORTS]: 5,
  [PRIVILEGES_CATEGORIES.CRM]: 6,
  [PRIVILEGES_CATEGORIES.SUPPLIERS]: 7,
  [PRIVILEGES_CATEGORIES.FINANCE]: 8,
  [PRIVILEGES_CATEGORIES.WORKFLOWS]: 9,
  [PRIVILEGES_CATEGORIES.COMPLIANCE]: 10,
  [PRIVILEGES_CATEGORIES.ADDITIONAL]: 11,
  [PRIVILEGES_CATEGORIES.INVOICE]: 12
};

export const privilegesMapping = {
  [privileges.EDIT_PERMISSIONS]: PRIVILEGES_CATEGORIES.ADDITIONAL,
  [privileges.TIMESHEET]: PRIVILEGES_CATEGORIES.TIMESHEET,
  [privileges.APPROVE_TIMESHEET]: PRIVILEGES_CATEGORIES.TIMESHEET,
  [privileges.PAYROLL]: PRIVILEGES_CATEGORIES.FINANCE,
  [privileges.CRM]: PRIVILEGES_CATEGORIES.CRM,
  [privileges.SUPPLIERS]: PRIVILEGES_CATEGORIES.SUPPLIERS,
  [privileges.SCHEDULING]: PRIVILEGES_CATEGORIES.SCHEDULING,
  [privileges.FEED]: PRIVILEGES_CATEGORIES.COMMUNICATION,
  [privileges.EDIT_HR]: PRIVILEGES_CATEGORIES.EMPLOYEES,
  [privileges.SEQUENCE]: PRIVILEGES_CATEGORIES.WORKFLOWS,
  [privileges.BILLING]: PRIVILEGES_CATEGORIES.ADDITIONAL,
  [privileges.CLIENT_BILLING]: PRIVILEGES_CATEGORIES.CRM,
  [privileges.COMPLIANCE]: PRIVILEGES_CATEGORIES.COMPLIANCE,
  [privileges.REPORTS]: PRIVILEGES_CATEGORIES.REPORTS,
  [privileges.DELETE_EMPLOYEE]: PRIVILEGES_CATEGORIES.EMPLOYEES,
  [privileges.UNLOCK_PAYROLL]: PRIVILEGES_CATEGORIES.FINANCE,
  [privileges.EXPORT_PROCESS_PAYROLL]: PRIVILEGES_CATEGORIES.FINANCE,
  [privileges.EDIT_SCHEDULING]: PRIVILEGES_CATEGORIES.SCHEDULING,
  [privileges.ADD_EMPLOYEE]: PRIVILEGES_CATEGORIES.EMPLOYEES,
  [privileges.INVOICE]: PRIVILEGES_CATEGORIES.FINANCE
};

export default privileges;

// make a map from privileges
export const mapPrivileges = (privilegesData = []) =>
  privilegesData.reduce((prev, id) => ({ ...prev, [id]: true }), {});

export const allPrivilegesExcept = (...exceptPrivileges) =>
  Object.values(privileges).filter((value) => !exceptPrivileges.includes(value));

export const ubeyaPrivileges = allPrivilegesExcept(privileges.BRANCH_ADMIN, privileges.MANAGER_ADMIN).reduce(
  (prev, value) => ({ ...prev, [value]: true }),
  { [privileges.UBEYA_ADMIN]: true }
);
