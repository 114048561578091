import { useCallback, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import useUser from '@ubeya/shared/hooks/auth/useUser';
import useOnClickOutside from './useOnClickOutside';

const usePortal = ({ enabled, portalId, closeOnClickOutside = true, onClose }) => {
  const {
    data: { id: adminId }
  } = useUser();
  const [coordinates, setCoordinates] = useState();
  const ref = useRef();

  useOnClickOutside(
    ref,
    () => {
      setCoordinates();
      onClose?.();
    },
    closeOnClickOutside && !!coordinates,
    portalId
  );

  const setOpen = useCallback(() => setCoordinates(ref.current?.getBoundingClientRect?.()), []);

  const setClose = useCallback(() => setCoordinates(), []);

  const setToggle = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (adminId === 22194) {
        // eslint-disable-next-line no-console
        console.log('Portal coordinates', { coordinates });
      }
      coordinates ? setClose() : setOpen();
    },
    [adminId, coordinates, setClose, setOpen]
  );

  const Portal = useCallback(
    ({ children }) => {
      if (enabled) {
        return ReactDOM.createPortal(children, document.getElementById(portalId));
      }
      return children;
    },
    [portalId, enabled]
  );

  return { Portal, isOpen: !!coordinates, setToggle, setOpen, setClose, clickRef: ref, coordinates };
};

export default usePortal;
