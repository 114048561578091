import React, { useMemo, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { removeFilter } from '../../utils/filters';
import { FlexMiddle } from '../Flex';
import DropDown from '../DropDown';
import FilterOptions from '../Filter/FilterOptions';
import ConfigContext from '../../contexts/ConfigContext';
import Chip from './Chip';
import PositionChip from './CustomChips/PositionsChip';
import RatingChip from './CustomChips/RatingChip';
import EmployeeTypeChip from './CustomChips/EmployeeTypeChip';
import AdminChip from './CustomChips/AdminChip';
import TagsChip from './CustomChips/TagsChip';
import ClientsChip from './CustomChips/ClientsChip';
import RestrictedClientChip from './CustomChips/RestrictedClientChip';
import BookedInProjectChip from './CustomChips/BookedInProjectChip';
import LocationsChip from './CustomChips/LocationsChip';
import FieldChip from './CustomChips/FieldChip';
import TempFieldChip from './CustomChips/TempFieldChip';

const COMPONENTS_MAP = {
  positions: PositionChip,
  rating: RatingChip,
  employeeType: EmployeeTypeChip,
  adminType: AdminChip,
  tags: TagsChip,
  eventBooker: AdminChip,
  clients: ClientsChip,
  locations: LocationsChip,
  previouslyWorkedClient: BookedInProjectChip,
  restrictedClient: RestrictedClientChip,
  bookedInProjects: BookedInProjectChip
};

const Wrapper = styled(FlexMiddle)`
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -10px;
`;

const FiltersRow = ({ selectedKey, filters, setFilters, disabledFilters = [], usePortal, className }) => {
  const { t } = useContext(ConfigContext);

  const options = useMemo(() => {
    const { basic } = filters || {};
    const basicOptions = [
      {
        id: 10,
        kind: 'events',
        type: 'basic',
        slug: 'Previously Worked for client',
        value: basic?.find(({ id }) => id === 10)?.value
      },
      { id: 11, kind: 'events', type: 'basic', slug: 'locations', value: basic?.find(({ id }) => id === 11)?.value },
      { id: 1, kind: 'employees', type: 'basic', slug: 'rating', value: basic?.find(({ id }) => id === 1)?.value }
    ];

    const fieldsOptions = [];

    return [...basicOptions, ...fieldsOptions].filter(({ value }) => Number.isInteger(value) || !isEmpty(value));
  }, [filters]);

  const handleRemoveFilter = useCallback(
    ({ type, id }) => {
      setFilters(removeFilter({ type, id, allFilters: filters }));
    },
    [filters, setFilters]
  );

  return (
    <Wrapper className={className}>
      {(options || []).map(({ slug, value, type, id }, index) => {
        const isDisabled = disabledFilters.includes(slug);
        const Container = isDisabled ? ({ renderValue }) => renderValue : DropDown;
        const Component = type === 'basic' ? COMPONENTS_MAP[slug] || TempFieldChip : FieldChip;

        return (
          <Container
            key={index}
            openFromRight={!usePortal}
            usePortal={usePortal}
            showArrow={false}
            renderValue={
              <Chip
                label={t(slug)}
                value={Component ? <Component id={id} value={value} /> : Array.isArray(value) ? value.join(',') : value}
                onRemove={isDisabled ? undefined : () => handleRemoveFilter({ type, id })}
              />
            }>
            <FilterOptions {...{ selectedKey, filters, setFilters, selectedOpenFilter: { type, id, slug, value } }} />
          </Container>
        );
      })}
    </Wrapper>
  );
};

export default FiltersRow;
