import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Mixpanel } from '@ubeya/shared-web/analytics/Mixpanel';
import BaseConfirmationModal from '../ConfirmationModal';
import scrollbar from '../../style/scrollbar';
import { SYSTEM_ERROR } from '../../analytics/events';

const ConfirmationModal = styled(BaseConfirmationModal)`
  color: ${({ theme }) => theme.colors.failure};
  padding: 23px 0;
  > div:first-child {
    padding-bottom: 20px;
  }

  > div {
    overflow: hidden;
  }
`;

const Stack = styled.div`
  overflow: hidden auto;
  text-align: left !important;
  padding: 0 40px;
  margin: -20px 0;
  color: ${({ theme }) => theme.colors.failure};
  ${scrollbar}
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    if (error?.toString?.()?.includes?.('Loading chunk')) {
      window.location.reload();
      return;
    }
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children, t, withMessage = true } = this.props;

    if (error) {
      if (!withMessage) {
        return null;
      }

      Mixpanel.track(SYSTEM_ERROR, {
        error: error?.toString(),
        errorInfo: errorInfo?.componentStack?.toString?.(),
        changeDate: '29-06-2023 13:00'
      });

      return (
        <ConfirmationModal
          onClose={() => this.setState({ error: null })}
          onConfirm={() => this.setState({ error: null })}
          title={t('somethingWentWrong')}
          showCancelButton={false}
          confirmText="ok">
          <Stack>
            {error.toString()}

            {process.env.NODE_ENV !== 'production' && (
              <>
                <br />
                <br />
                {errorInfo?.componentStack?.toString?.()}
              </>
            )}
          </Stack>
        </ConfirmationModal>
      );
    }

    return <>{children}</>;
  }
}

export default withTranslation()(ErrorBoundary);
