import React from 'react';
import useCRM from '@ubeya/shared/hooks/account/useCRM';
import { FlexMiddle } from '../../Flex';

const PreferredClientsChip = ({ value }) => {
  const { mappedClients } = useCRM();

  return <FlexMiddle>{value.map((item) => mappedClients[item]?.name).join(', ')}</FlexMiddle>;
};

export default PreferredClientsChip;
