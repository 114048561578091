import React, { useContext, useState } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';
import DropDown from '../../DropDown';
import Slider from '../../Slider';
import FilterLayout from '../FilterLayout';

const AgeFilter = ({ defaultValue, handleClose, onApply, search, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const [range, setRange] = useState(defaultValue || [0, 120]);

  if (search && !t('age').toLowerCase().includes(search.toLowerCase())) {
    return null;
  }

  return (
    <FilterLayout name={t('age')} handleClose={handleClose} hideLayout={hideLayout} onApply={() => onApply(range)}>
      <DropDown.Item>
        <Slider value={range} onChange={setRange} min={0} max={120} showValue />
      </DropDown.Item>
    </FilterLayout>
  );
};

export default AgeFilter;
