import React from 'react';

const AutoCompleteOff = () => (
  <>
    <input type="hidden" name="username" style={{ opacity: '0', position: 'absolute', zIndex: -999 }} />
    <input type="hidden" name="email" style={{ opacity: '0', position: 'absolute', zIndex: -999 }} />
    <input type="hidden" name="password" style={{ opacity: '0', position: 'absolute', zIndex: -999 }} />
  </>
);

export default AutoCompleteOff;
