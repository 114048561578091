import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const TextWithYoutubeLink = ({ children }) => {
  const youtubeLink = children.match(
    /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/
  );

  if (youtubeLink?.[1]) {
    return (
      <Wrapper>
        <Iframe src={`https://www.youtube.com/embed/${youtubeLink[1]}`} />
      </Wrapper>
    );
  }
  return null;
};

export default TextWithYoutubeLink;
