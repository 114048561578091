import React, { useCallback, useMemo, useState } from 'react';
import { flatten, isEmpty } from 'lodash';
import { CheckBox } from '../../index';

// Default group to support selection of rows without grouping
const DEFAULT_GROUP = 0;

const objectToArray = (obj) => Object.keys(obj).map((key) => obj[key]);

const isRowSelectable = ({ selectable }) => !selectable === false;

const formatGroupKey = (value) => {
  return btoa(value || DEFAULT_GROUP);
};

const getSelectableRows = (rows, initial = {}) =>
  rows.reduce((acc, row) => (isRowSelectable(row) ? { ...acc, [row.id]: row } : acc), initial);

// If checked is true, return all subRows of a group; otherwise, return the current rowSelection.
const getRowSelectionByGroup = (rowSelection, groupKey, rowData, checked) => {
  const { [groupKey]: groupSelection = {}, ...rest } = rowSelection;
  if (checked) {
    const newGroupSelection = getSelectableRows(rowData.subRows, groupSelection);
    return isEmpty(newGroupSelection) ? rest : { ...rest, [groupKey]: newGroupSelection };
  }
  return rest;
};

// If checked is true, return all subRows of all groups; otherwise, return the current rowSelection.
const getAllGroupsRowSelection = (rows, rowSelection, checked) =>
  rows.reduce(
    (acc, row) => ({
      ...acc,
      ...getRowSelectionByGroup(rowSelection, formatGroupKey(row?.groupValue), row, checked)
    }),
    {}
  );

const useCheckboxSelection = ({ columns, rows, checkboxSelection, rowGroup }) => {
  const [rowSelection, setRowSelection] = useState({});
  const dataRows = useMemo(() => rows.filter(({ summarized }) => !summarized), [rows]);
  const selectableDataRows = useMemo(() => dataRows.filter(isRowSelectable), [dataRows]);

  // Object containing all groups with the corresponding subRows count value.
  const rowGroupsCountMap = useMemo(
    () =>
      dataRows.reduce((acc, row) => {
        if (row?.subRows) {
          const groupKey = formatGroupKey(row?.groupValue);
          acc[groupKey] = row.subRows.filter(isRowSelectable).length;
        }
        return acc;
      }, {}),
    [dataRows]
  );

  const clearRowSelection = useCallback(() => setRowSelection({}), []);

  // Returns flat array of all selected rows
  const getRowSelection = useCallback(() => flatten(Object.values(rowSelection).map(objectToArray)), [rowSelection]);

  // If checked, select all selectable subRows from all groups or all selected rows, otherwise return empty object
  const onHeaderCheckboxClick = useCallback(
    (checked) => {
      if (!rowGroup) {
        const newGroupSelection = getSelectableRows(dataRows);
        setRowSelection(checked ? { [DEFAULT_GROUP]: newGroupSelection } : {});
      } else {
        const newRowSelection = getAllGroupsRowSelection(dataRows, rowSelection, checked);
        setRowSelection(checked ? newRowSelection : {});
      }
    },
    [dataRows, rowGroup, rowSelection]
  );

  // If checked, select all selectable subRows from a specific group
  const onGroupCheckboxClick = useCallback(
    ({ checked, groupKey, rowData }) => {
      const newRowSelection = getRowSelectionByGroup(rowSelection, groupKey, rowData, checked);
      setRowSelection(newRowSelection);
    },
    [rowSelection]
  );

  // If checked, select single subRow from a group, otherwise, return previous rowSelection
  const onRowCheckboxClick = useCallback(
    ({ checked, groupKey, rowData }) => {
      if (!isRowSelectable(rowData)) {
        return;
      }
      setRowSelection((prevRowSelection) => {
        const { [groupKey]: groupSelection = {}, ...rowSelectionRest } = prevRowSelection;
        const { [rowData.id]: row = rowData, ...groupSelectionRest } = groupSelection;
        const newGroupSelection = checked
          ? { [groupKey]: { ...groupSelectionRest, [rowData.id]: row } }
          : Object.keys(groupSelectionRest).length
          ? { [groupKey]: groupSelectionRest }
          : {};
        return { ...rowSelectionRest, ...newGroupSelection };
      });
    },
    [setRowSelection]
  );

  // Determine the checked and indeterminate states of the Header checkbox.
  const calculateHeaderCheckboxState = useCallback(
    ({ gridApi }) => {
      const selectedGroups = rowGroup
        ? Object.keys(gridApi.rowSelection).filter(
            (key) => Object.keys(gridApi.rowSelection[key]).length === rowGroupsCountMap[key]
          )
        : Object.keys(gridApi.rowSelection?.[DEFAULT_GROUP] || []);

      const selectionCount = selectedGroups.length;
      const rowsCount = selectableDataRows.length;
      const value = selectionCount > 0 && selectionCount === rowsCount;
      const indeterminate =
        (Object.keys(gridApi.rowSelection).length > 0 && !value) || (selectionCount > 0 && selectionCount < rowsCount);

      return { indeterminate, value };
    },
    [selectableDataRows, rowGroup, rowGroupsCountMap]
  );

  // Determine the checked and indeterminate states of the Aggregate checkbox.
  const calculateAggregateCheckboxState = useCallback(
    ({ gridApi, groupKey }) => {
      const groupSelection = gridApi.rowSelection?.[groupKey] || {};
      const subRowsSelectionCount = Object.keys(groupSelection).length;
      const rowGroupCount = rowGroupsCountMap[groupKey] || 0;
      const indeterminate = subRowsSelectionCount > 0 && subRowsSelectionCount < rowGroupCount;
      const value = subRowsSelectionCount > 0 && subRowsSelectionCount === rowGroupCount;

      return { indeterminate, value };
    },
    [rowGroupsCountMap]
  );

  // Determine the checked states of the Cell checkbox.
  const calculateCellCheckboxState = useCallback(({ gridApi, rowData, groupKey }) => {
    const groupSelection = gridApi.rowSelection[groupKey];
    return groupSelection?.[rowData.id] !== undefined;
  }, []);

  const checkboxColumn = {
    dataKey: 'checkboxSelection',
    maxWidth: 80,
    minWidth: 60,
    frozen: true,
    visible: true,
    sortable: false,
    captureRowClick: false,
    Header: ({ gridApi }) => {
      const { indeterminate, value } = calculateHeaderCheckboxState({ gridApi });
      return (
        <CheckBox
          name={`header-checkbox-${Math.random()}`}
          value={value}
          indeterminate={indeterminate}
          onChange={(checked) => onHeaderCheckboxClick(indeterminate ? false : checked)}
        />
      );
    },
    ...(rowGroup && {
      Aggregate: ({ rowData, gridApi }) => {
        const groupKey = formatGroupKey(rowData?.groupValue);
        const { indeterminate, value } = calculateAggregateCheckboxState({ gridApi, groupKey });
        if (rowData?.summarized) return <></>;
        return (
          <CheckBox
            name={`aggregate-checkbox-${Math.random()}`}
            value={value}
            indeterminate={indeterminate}
            onChange={(checked) =>
              onGroupCheckboxClick({ checked: indeterminate ? false : checked, groupKey, rowData })
            }
            disabled={rowData?.selectable === false}
          />
        );
      }
    }),
    Cell: ({ rowData, gridApi }) => {
      const groupKey = formatGroupKey(rowData?.groupValue);
      const value = calculateCellCheckboxState({ gridApi, rowData, groupKey });
      if (rowData?.summarized) return <></>;
      return (
        <CheckBox
          name={`cell-checkbox-${rowData.id}`}
          value={value}
          onChange={(checked) => onRowCheckboxClick({ checked, groupKey, rowData })}
          disabled={rowData?.selectable === false}
        />
      );
    }
  };

  return {
    columns: checkboxSelection ? [checkboxColumn, ...columns] : columns,
    rowSelection,
    clearRowSelection,
    getRowSelection
  };
};

export default useCheckboxSelection;
