import React, { useContext } from 'react';
import PrivilegesContext from '../contexts/PrivilegesContext';

const withPrivileges = (WrappedComponent) => {
  const ProtectedComponent = ({
    requiredPrivileges = [],
    atLeastOneRequired = true,
    disableProtected = false,
    ...props
  }) => {
    const privileges = useContext(PrivilegesContext);

    const hasPrivilege =
      requiredPrivileges.length === 0 ||
      (atLeastOneRequired
        ? requiredPrivileges.some((privilege) => privileges[privilege])
        : requiredPrivileges.every((privilege) => privileges[privilege]));

    if (!hasPrivilege && !disableProtected) {
      return null;
    }

    const disabledProps = disableProtected
      ? { isDisabled: props.isDisabled || !hasPrivilege, disabled: props.disabled || !hasPrivilege }
      : {};

    return <WrappedComponent {...props} {...disabledProps} />;
  };

  return ProtectedComponent;
};

export default withPrivileges;
