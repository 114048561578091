import React, { useContext, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import useAccount from '@ubeya/shared/hooks/account/useAccount';
import { FlexCenter, FlexColumn, FlexMiddle, NavigationMenu } from '@ubeya/shared-web/components';
import { media } from '@ubeya/shared-web/style';
import { thinPrimaryScrollbar } from '@ubeya/shared-web/style/scrollbar';
import ConfigContext from '../../contexts/ConfigContext';
import { AppSideBarItem } from './AppSideBarItem';

const Wrapper = styled(FlexColumn).attrs(() => ({ className: 'app-side-bar-wrapper' }))`
  position: relative;
  display: none;
  padding-top: 16px;
  padding-left: 6px;
  padding-right: 6px;
  ${media.tablet`display: flex`};
  justify-content: space-between;
  background: ${({ theme, isHost }) => (isHost ? theme.colors.isHostSideBar : theme.colors.mainSideBar)};
  transition: 200ms;
  width: ${({ isHost }) => (isHost ? 80 : 60)}px;
`;

const CircleIcon = styled(FlexCenter)`
  border-radius: 8px;
  cursor: pointer;
  transition: 200ms;
  width: 40px;
  height: 40px;
  margin-bottom: 2px;
`;

const Link = styled(FlexMiddle)`
  text-decoration: none;
  padding: 10px 8px;
  width: 100%;
  transition: 300ms;
  justify-content: center;
  flex-direction: column;
  ${({ $mainIcon }) => $mainIcon && 'margin-bottom: 48px;'}
  &.active {
    > ${CircleIcon} {
      background: #6467ef;
    }
  }

  &:hover {
    ${() =>
      css`
        > ${CircleIcon} {
          background: ${({ theme }) => theme.colors.sidebarHover};
        }
      `}
  }
`;

const Links = styled(FlexColumn)`
  ${thinPrimaryScrollbar};
`;

const AppSideBar = ({
  links,
  linkRenderer: LinkRenderer = NavLink,
  children,
  mobileRenderer = children,
  shouldChangePageTitle = true
}) => {
  const { t, isRtl } = useContext(ConfigContext);
  const {
    account: { name, isHost }
  } = useAccount();

  const theme = useTheme();
  const [openPopoverIndex, setOpenPopoverIndex] = useState(-1);
  const { pathname } = useLocation();
  const flatLinks = useMemo(() => links.flat(), [links]);
  return (
    <>
      <NavigationMenu linkRenderer={LinkRenderer} links={flatLinks}>
        {React.cloneElement(mobileRenderer, { isMobile: true })}
      </NavigationMenu>

      <Wrapper isHost={isHost}>
        <FlexColumn>
          {links.map((subLinks, index) => (
            <Links key={index}>
              {subLinks
                .filter(({ isVisible = true }) => isVisible)
                .map(
                  (
                    { to, icon: Icon, mainIcon, label, secondaryMenu, isNew, includePath, hasBottomBorder, disabled },
                    linkIndex
                  ) => (
                    <AppSideBarItem
                      mainIcon={mainIcon}
                      isNew={isNew}
                      includePath={includePath}
                      isRtl={isRtl}
                      hasBottomBorder={hasBottomBorder}
                      shouldChangePageTitle={shouldChangePageTitle}
                      key={linkIndex}
                      disabled={disabled}
                      isHost={isHost}
                      index={linkIndex}
                      openPopoverIndex={openPopoverIndex}
                      onHover={(value) => setOpenPopoverIndex(value | linkIndex)}
                      to={to}
                      pathname={pathname}
                      Icon={Icon}
                      t={t}
                      theme={theme}
                      label={label}
                      secondaryMenu={secondaryMenu}
                      LinkRenderer={LinkRenderer}
                      accountName={name}
                    />
                  )
                )}
            </Links>
          ))}
        </FlexColumn>
        <Link $userLink>{children}</Link>
      </Wrapper>
    </>
  );
};

export default AppSideBar;
