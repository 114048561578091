import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { fileUpload } from '@ubeya/shared/atoms/shared';
import useToaster from '@ubeya/shared/hooks/useToaster';
import { Loader } from '../Loader';

const FileUpload = ({
  onDrop,
  onUploaded,
  apiFunc,
  children,
  loaderSize,
  accept,
  className,
  multiple = true,
  enabled = true
}) => {
  const setIsFileUpload = useSetRecoilState(fileUpload);
  const { addError } = useToaster();

  const { mutateAsync: uploadFile, isLoading } = useMutation(apiFunc, {
    onMutate: () => setIsFileUpload(true),
    onSuccess: () => setIsFileUpload(false),
    onError: () => {
      addError('Invalid file format or size');
      return setIsFileUpload(false);
    }
  });

  const handleDrop = useCallback(
    async (files) => {
      if (files.length === 0) {
        return;
      }

      onDrop?.(multiple ? files : files[0]);
      if (!apiFunc) {
        return;
      }
      const result = await Promise.all(
        files.map(async (file) => {
          const uploadResult = await uploadFile([file]);
          return { file, title: file?.name, path: uploadResult.data.path };
        })
      );

      onUploaded?.(multiple ? result : result[0], multiple ? files : files[0]);
    },
    [onDrop, multiple, apiFunc, onUploaded, uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple,
    disabled: !enabled,
    accept
  });

  return (
    <div {...getRootProps()} className={`${className} ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      {isLoading ? <Loader size={loaderSize} /> : children}
    </div>
  );
};

export default FileUpload;
