import React from 'react';
import { Field } from 'react-final-form';
import { TextInput, TextArea } from '../Input';

const InputField = ({
  title,
  name,
  validate,
  type,
  placeholder,
  controlledValue = false,
  className,
  readOnly,
  onChange = (value) => value,
  mask,
  isDisabled,
  isMultiline,
  innerRef,
  autoFocus,
  required,
  showErrorMessage = true,
  linkTo,
  linkText,
  clearable,
  onClear,
  metadata,
  enableShowLinks = false,
  autocomplete,
  autocapitalize
}) => {
  const maxLength = metadata?.maxNumberOfChar ? metadata.maxNumberOfChar : undefined;

  return (
    <Field
      name={name}
      validate={validate}
      type={type}
      render={({ input, meta }) =>
        isMultiline ? (
          <TextArea
            {...input}
            title={title}
            placeholder={placeholder}
            className={className}
            innerRef={innerRef}
            required={required}
            error={meta.touched && meta.error && (showErrorMessage ? meta.error : ' ')}
            maxLength={maxLength}
            readOnly={readOnly}
            disabled={isDisabled}
          />
        ) : (
          <TextInput
            type={type}
            {...input}
            value={input.value}
            onChange={(value) => (controlledValue ? onChange(value) : input.onChange(onChange(value)))}
            title={title}
            readOnly={readOnly}
            placeholder={placeholder}
            className={className}
            mask={mask}
            maxLength={maxLength}
            disabled={isDisabled}
            autoFocus={autoFocus}
            error={meta.touched && meta.error && (showErrorMessage ? meta.error : ' ')}
            required={required}
            linkTo={linkTo}
            linkText={linkText}
            clearable={clearable}
            onClear={onClear}
            enableShowLinks={enableShowLinks}
            autocomplete={autocomplete}
            autocapitalize={autocapitalize}
          />
        )
      }
    />
  );
};

export default InputField;
