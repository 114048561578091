import { ATTACHMENTS_TYPES } from '@ubeya/shared/constants';
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as PDFIcon } from '@ubeya/shared-web/assets/pdf-file.svg';
import { ReactComponent as ArrowIcon } from '@ubeya/shared-web/assets/arrow.svg';
import { ImagesCarousel, TextWithTooltip, ContextMenu as BaseContextMenu, PdfModal } from '..';
import { getExtension, getSource } from '../../utils/files';
import ConfigContext from '../../contexts/ConfigContext';

const attachment = css`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-top: 10px;
  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }
`;
const Image = styled.div.attrs(({ src }) => ({ style: { backgroundImage: `url(${src})` } }))`
  ${attachment};
  background-position: center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
`;

const Video = styled.video.attrs(() => ({ controls: true }))`
  ${attachment};
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div``;
const Doc = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DocumentWrapper = styled.a`
  ${attachment};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray400};
  display: flex;
  width: max-content;
  height: 40px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  ${({ onClick, href }) => (!!onClick || !!href ? 'cursor: pointer' : '')};
`;

const Title = styled.div`
  font-size: 10px;
  padding: 2px;
  max-width: 80px;
`;

const SubTitle = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.gray400};
`;

const ContextMenu = styled(BaseContextMenu)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Arrow = styled(ArrowIcon).attrs(() => ({ className: 'arrow' }))`
  border-radius: 50%;
  position: absolute;
  transform: rotate(90deg);
  right: 12px;
  top: 5px;
  width: 10px;
  height: 10px;
  visibility: hidden;
  padding: 3px;
`;

const ComponentWrapper = styled.div`
  position: relative;

  &:hover {
    .arrow {
      visibility: visible;
      background-color: ${({ theme }) => theme.colors.gray400};
      > path {
        fill: ${({ theme }) => theme.colors.surface};
        stroke: none;
      }
    }
  }
`;

const Document = ({ children, title, src, onClick, extension }) => {
  return (
    <DocumentWrapper
      onClick={extension === 'pdf' ? onClick : undefined}
      href={extension === 'pdf' ? undefined : src}
      target="_blank">
      <PDFIcon />
      <Doc>
        <Title>
          <TextWithTooltip>{title}</TextWithTooltip>
        </Title>
        <SubTitle>{children.toUpperCase()}</SubTitle>
      </Doc>
    </DocumentWrapper>
  );
};

const COMPONENTS_MAP = {
  document: Document,
  image: Image,
  video: Video
};

const DocumentRenderer = ({ rowData, dataKey }) => {
  const { t } = useContext(ConfigContext);
  const [openPdf, setOpenPdf] = useState();
  const [isOpenIndex, setIsOpenIndex] = useState();

  const item = rowData[dataKey];
  if (!item) {
    return null;
  }
  const extension = getExtension(item)?.toLowerCase();
  if (!extension) {
    return null;
  }
  const type = ATTACHMENTS_TYPES[extension];
  if (!type) {
    return null;
  }

  const src = getSource(item);
  const Component = COMPONENTS_MAP[type];
  const name = type === 'document' && extension;
  const title = item?.title;

  const fileOptions = [
    ...(extension === 'pdf'
      ? [
          {
            label: t('view'),
            onClick: () => setOpenPdf(src)
          }
        ]
      : []),
    {
      label: t('download'),
      onClick: () => window.open(src)
    }
  ];

  const handleClick = () => {
    if (extension === 'pdf') {
      return setOpenPdf(src);
    }
    return setIsOpenIndex(0);
  };

  return (
    <Container>
      <ComponentWrapper>
        <Component onClick={handleClick} title={title} extension={extension} src={src} preload="false">
          {name || ''}
        </Component>
        <ContextMenu options={fileOptions} usePortal>
          <Arrow />
        </ContextMenu>
      </ComponentWrapper>
      {!!openPdf && <PdfModal url={openPdf} onClose={() => setOpenPdf(undefined)} />}
      <ImagesCarousel views={[{ source: src }]} currentIndex={isOpenIndex} onClose={() => setIsOpenIndex()} />;
    </Container>
  );
};

export default DocumentRenderer;
