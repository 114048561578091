import React, { useMemo } from 'react';

const useCheckboxColumn = ({ columns, checkboxData, checkboxRenderer: CheckBox }) => {
  const newColumns = useMemo(() => {
    return checkboxData
      ? [
          {
            Header: () => (
              <CheckBox
                name={`header-checkbox-${Math.random()}`}
                value={checkboxData.isAllChecked}
                onChange={checkboxData.toggleAll}
                indeterminate={!!(checkboxData.value.include || checkboxData.value.exclude)}
              />
            ),
            dataKey: 'checkbox',
            className: 'checkbox-column',
            minWidth: 0,
            maxWidth: 60,
            width: 60,
            sortable: false,
            captureRowClick: false,
            Cell: ({ rowData }) => (
              <CheckBox
                name={Math.random()}
                value={checkboxData.isAllChecked ? !checkboxData.isChecked(rowData) : checkboxData.isChecked(rowData)}
                onChange={(checked) => checkboxData.toggleCheck(rowData, checked)}
              />
            )
          },
          ...columns
        ]
      : columns;
  }, [columns, checkboxData]);

  return newColumns;
};

export default useCheckboxColumn;
