import React, { useContext } from 'react';
import styled from 'styled-components';
import { required } from '@ubeya/shared/utils/form';
import { useField } from 'react-final-form';
import InputField from '../../FormFields/InputField';
import BaseCheckBoxField from '../../FormFields/CheckBoxField';
import ConfigContext from '../../../contexts/ConfigContext';
import { SelectField } from '../../FormFields';
import Label from '../../Label';
import ToggleField from '../../FormFields/ToggleField';
import { EditableByAdmins } from './EditableByAdmins';

const CheckBoxField = styled(BaseCheckBoxField)`
  margin-left: -20px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
`;

const TextFieldForm = ({ showLimitEditAdminsToggle = false }) => {
  const { t } = useContext(ConfigContext);

  const limitTypeOptions = [
    {
      label: t('text_limit_max'),
      value: 'max'
    },
    {
      label: t('text_limit_exact'),
      value: 'exact'
    }
  ];

  const {
    input: { value: maxNumberOfChar, onChange }
  } = useField('metadata.maxNumberOfChar');

  const {
    input: { value: enableLimit }
  } = useField('enableLimit', {
    initialValue: maxNumberOfChar.length > 0
  });

  if (!enableLimit) {
    onChange(undefined);
  }

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        validate={required}
        required
        autoFocus
        className="full"
      />

      <ToggleField name="enableLimit" text={t('text_enable_limit')} className="full" />
      {enableLimit && (
        <>
          <Label title={t('maxNumberOfChar')} className="full">
            <Container>
              <SelectField name="metadata.limitType" options={limitTypeOptions} isMulti={false} />
              <InputField name="metadata.maxNumberOfChar" />
            </Container>
          </Label>

          <CheckBoxField name="isMultiline" title={t('isMultiline')} className="full" />
        </>
      )}
      {showLimitEditAdminsToggle && <EditableByAdmins />}
    </>
  );
};

export default TextFieldForm;
