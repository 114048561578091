import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const selectedBranches = atom({ key: 'selectedBranches', default: null });

export const isChatOpen = atom({ key: 'isChatOpen', default: false });

export const selectedBranch = atom({ key: 'selectedBranch', default: null });

export const viewAsClient = atom({ key: 'viewAsClient', default: false });

export const toaster = atom({ key: 'toaster', default: [] });

export const confetti = atom({ key: 'confetti', default: false });

export const selectedEmployee = atom({ key: 'selectedEmployee', default: null });

export const chatCount = atom({ key: 'chatCount', default: null });

export const openChatRoom = atom({ key: 'openChatRoom', default: {} });

export const controlledColumnsMenuOpenData = atom({ key: 'controlledColumnsMenuOpenData', default: null });

export const fileUpload = atom({ key: 'fileUpload', default: false });

export const chatIframeRef = atom({ key: 'chatIframeRef', default: null });

export const auditLogSidebarRef = atom({ key: 'auditLogSidebarRef', default: { isOpen: false, payload: {} } });

export const requestsModal = atom({ key: 'requestsModal', default: false });

export const plansModal = atom({ key: 'plansModal', default: false });

export const openHelpCenter = atom({ key: 'openHelpCenter', default: false });

export const premiumModal = atom({ key: 'premiumModal', default: null });

export const refreshNeededModal = atom({ key: 'refreshNeeded', default: false });

export const chatFilters = atom({
  key: 'chatFilters',
  default: { tagIds: [], branchIds: [], statuses: [] },
  effects_UNSTABLE: [persistAtom]
});
