import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@ubeya/shared-web/components';
import { FlexCenter, flexCenter } from '../Flex';

const Circle = styled(FlexCenter).attrs(() => ({ className: 'out-source-logo-circle' }))`
  height: 25px;
  width: 25px;
  border-radius: 12px;
`;

const Logo = styled.img.attrs(() => ({ className: 'out-source-logo-basic' }))`
  border-radius: 100%;
  height: 25px;
  width: 25px;
`;

const Image = styled.div.attrs(() => ({ className: 'out-source-logo-image' }))`
  position: relative;
  ${flexCenter};
  border-radius: 100%;
  height: 25px;
  width: 25px;
  background: #e9ebf0;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-weight: 500;
  font-size: 15px;
`;

const OutsourceLogo = ({ logo, withName = false, name }) => {
  return (
    <Circle>
      <Tooltip body={name} disabled={withName}>
        {logo ? <Logo src={logo} /> : <Image>{name?.charAt?.(0)}</Image>}
      </Tooltip>
    </Circle>
  );
};

export default OutsourceLogo;
