import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled, { css } from 'styled-components';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import { FlexCenter } from '../../Flex/FlexCenter';
import ConfigContext from '../../../contexts/ConfigContext';
import { CHAT_FILTERS_SLUGS } from '../../../constants';

const internalCss = css`
  max-width: 200px;
`;

const EmptyOptions = styled(FlexCenter)`
  height: 20px;
`;

const ChatTagsFilter = ({
  defaultValue,
  handleClose,
  onApply,
  search,
  onChange,
  externalOptions,
  hideLayout = false
}) => {
  const { t } = useContext(ConfigContext);
  const tagsOptions = (externalOptions[CHAT_FILTERS_SLUGS.tags] || []).map(({ _id, name }) => ({
    value: _id,
    label: name
  }));
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];

      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? (tagsOptions || []).filter(
            ({ label }) =>
              t('chatTags').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : tagsOptions,
    [tagsOptions, search, t]
  );

  if (!filteredOptions.length) {
    return search ? <></> : <EmptyOptions>{t('noOptions')}</EmptyOptions>;
  }

  return (
    <FilterLayout
      name={t('chatTags')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value} internalCss={internalCss}>
          <CheckBox
            name={`chat-tags-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ChatTagsFilter;
