import React, { useMemo, useContext } from 'react';
import styled, { css } from 'styled-components';
import DropDownContext from '../../contexts/DropDownContext';
import { FlexMiddle, FlexCenter } from '../Flex';
import { body } from '../Typography';
import TextWithTooltip from '../Tooltip/TextWithTooltip';
import Tooltip from '../Tooltip/Tooltip';

const hoverOption = css`
  background: ${({ theme }) => theme.colors.backgroundLight};
`;

const Option = styled(FlexMiddle).attrs(() => ({ className: 'context-option' }))`
  padding: 8px;
  border-radius: 5px;
  white-space: nowrap;
  text-decoration: none;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.colors.gray200};

  ${body};
  ${({ internalCss }) => internalCss}

  ${({ isTooltip }) => isTooltip && 'max-width:200px'};

  &:hover {
    ${({ isClickable }) => isClickable && hoverOption};
  }

  ${({ isSelected }) => isSelected && hoverOption};

  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      background: ${theme.colors.gray910}!important;
      color: ${theme.colors.gray500}!important;
      cursor: no-drop;
    `}
`;

const IconWrapper = styled(FlexCenter)`
  margin-right: 10px;
`;

const DropDownItem = ({
  icon: Icon,
  children,
  onClick,
  closeOnClick = true,
  isDisabled,
  isVisible = true,
  internalCss,
  useTextWithTooltip,
  customTooltip = null
}) => {
  const { closeMenu } = useContext(DropDownContext);

  const handleClick = useMemo(
    () =>
      !isDisabled && onClick
        ? (e) => {
            e.stopPropagation();
            onClick(e);
            closeOnClick && closeMenu?.(false);
          }
        : undefined,
    [closeMenu, closeOnClick, isDisabled, onClick]
  );

  if (!isVisible) {
    return <></>;
  }

  return (
    <Tooltip body={customTooltip} disabled={!customTooltip}>
      <Option
        isClickable={!!onClick}
        onClick={handleClick}
        isDisabled={isDisabled}
        internalCss={internalCss}
        isTooltip={!!useTextWithTooltip}>
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}

        {useTextWithTooltip && !customTooltip ? <TextWithTooltip> {children}</TextWithTooltip> : children}
      </Option>
    </Tooltip>
  );
};

export default DropDownItem;
