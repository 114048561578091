import React, { useContext } from 'react';
import styled from 'styled-components';
import { useField } from 'react-final-form';
import { FlexSpace } from '../Flex';
import { ToggleField } from '../FormFields';
import ConfigContext from '../../contexts/ConfigContext';
import SelectField from '../FormFields/SelectField';

const Container = styled.div`
  padding: 20px 0;
  width: 100%;
`;

const Header = styled(FlexSpace)`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const BranchSelector = styled.div`
  display: block;
  height: 48px;
`;

const Name = styled.h2`
  font-size: 16px;
  font-weight: 500;
`;

export const BranchAccess = ({ config, branchesOptions }) => {
  const { t } = useContext(ConfigContext);
  const {
    input: { value: branchEnabled }
  } = useField(`branchEnabled[branch_${config.branch.id}]`, {
    initialValue: ['enabled', 'custom'].includes(config.accessType)
  });

  const {
    input: { value: clientBranchesEnabled }
  } = useField(`clientBranchesEnabled[branch_${config.branch.id}]`, {
    initialValue: config.enabledBranches
  });

  return (
    <Container>
      <Header>
        <Name>{config.branch.name}</Name>
        <ToggleField
          name={`branchEnabled[branch_${config.branch.id}]`}
          value={branchEnabled}
          tooltip={branchEnabled ? t('branches_mapping__toggle_on') : t('branches_mapping__toggle_off')}
        />
      </Header>
      <BranchSelector>
        {branchEnabled && (
          <SelectField
            name={`clientBranchesEnabled[branch_${config.branch.id}]`}
            placeholder={clientBranchesEnabled.length > 0 ? t('search') : t('allBranches')}
            isDisabled={!branchEnabled}
            options={branchesOptions}
            value={clientBranchesEnabled}
          />
        )}
      </BranchSelector>
    </Container>
  );
};
