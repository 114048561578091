const RENDER_BATCH_SIZE = 8;

const useViewportRows = ({ rawRows, rowHeight, clientHeight, scrollTop }) => {
  const overscanThreshold = 4;
  const rowVisibleStartIdx = Math.floor(scrollTop / rowHeight);
  const rowVisibleEndIdx = Math.min(rawRows.length - 1, Math.floor((scrollTop + clientHeight) / rowHeight));
  const rowOverscanStartIdx = Math.max(
    0,
    Math.floor((rowVisibleStartIdx - overscanThreshold) / RENDER_BATCH_SIZE) * RENDER_BATCH_SIZE
  );
  const rowOverscanEndIdx = Math.min(
    rawRows.length - 1,
    Math.ceil((rowVisibleEndIdx + overscanThreshold) / RENDER_BATCH_SIZE) * RENDER_BATCH_SIZE
  );

  return {
    rowVisibleStartIdx,
    rowOverscanStartIdx,
    rowOverscanEndIdx,
    rows: rawRows
  };
};

export default useViewportRows;
