import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const Bar = styled.div.attrs(() => ({ className: 'progress-bar-height' }))`
  position: relative;
  background: ${({ theme }) => theme.colors.progressBar.background};
  flex: 1;
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
`;

const Progress = styled.div`
  background: ${({ theme }) => theme.colors.progressBar.green};
  width: ${({ $width }) => `${$width}%`};
  height: 100%;
  border-radius: 8px;
`;

const Counter = styled.p.attrs(() => ({ className: 'progress-bar-counter' }))``;
const Percentage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 500;
`;

const ProgressBar = ({ current, total, showCounter = false, showPercentage = false }) => {
  const value = total > 0 ? (current / total) * 100 : 0;

  return (
    <Container>
      <Bar className="bar">
        <Progress $width={value} />
        {showPercentage && <Percentage>{Math.round(value)}%</Percentage>}
      </Bar>
      {showCounter && (
        <Counter className="counter">
          {current} / {total}
        </Counter>
      )}
    </Container>
  );
};

export default ProgressBar;
