export const localStorageRecoilEffect = (key, defaultValue) => ({ setSelf, onSet }) => {
  // Initialize with the value from localStorage
  const currentValue = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  const localValue = key && localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : currentValue;

  setSelf(localValue);

  // Subscribe to changes in the atom and sync them with localStorage
  onSet((newValue) => {
    if (key) {
      newValue ? localStorage.setItem(key, JSON.stringify(newValue)) : localStorage.removeItem(key);
    }
  });
};
