import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import useTags from '@ubeya/shared/hooks/account/useTags';
import DropDown from '../../DropDown';
import BaseCheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const CheckBox = styled(BaseCheckBox)`
  flex: 1;
`;

const Color = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: ${({ color }) => color};
  align-self: center;
`;

const EmptyOptions = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
`;

const TagsFilter = ({ defaultValue, handleClose, onApply, search, onChange, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const { tagsOptions } = useTags();
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? tagsOptions.filter(
            ({ label }) =>
              t('tags').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : tagsOptions,
    [search, tagsOptions, t]
  );

  return (
    <FilterLayout name={t('tags')} handleClose={handleClose} onApply={() => onApply(values)} hideLayout={hideLayout}>
      {filteredOptions && filteredOptions?.length > 0 ? (
        filteredOptions.map(({ value, label, color }) => (
          <DropDown.Item key={value}>
            <CheckBox
              name={`tag-${value}`}
              value={values.includes(value)}
              label={label}
              onChange={() => handleChange(value)}
            />
            <Color color={color} />
          </DropDown.Item>
        ))
      ) : (
        <EmptyOptions>{t('noOptions')}</EmptyOptions>
      )}
    </FilterLayout>
  );
};

export default TagsFilter;
