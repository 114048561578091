import React, { useContext } from 'react';
import styled from 'styled-components';
import { DynamicField as BaseDynamicField } from '../FormFields';
import AppPreview from '../AppPreview';
import ConfigContext from '../../contexts/ConfigContext';

const DynamicField = styled(BaseDynamicField)`
  margin-bottom: 15px;
`;

const FieldsPreview = ({ fields, children, className }) => {
  const { t } = useContext(ConfigContext);

  return (
    <AppPreview className={className}>
      {(fields || []).map((field, index) => (
        <DynamicField
          key={index}
          title={field?.isGeneric ? t(field.name?.toString?.()) : field.name?.toString?.() || t(field.slug)}
          {...field}
          name={`field-${index}`}
        />
      ))}
      {children}
    </AppPreview>
  );
};

export default FieldsPreview;
