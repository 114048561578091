import React, { useContext } from 'react';
import styled from 'styled-components';
import useClientBranchesAccess from '@ubeya/shared/hooks/account/useClientBranchesAccess';
import useClient from '@ubeya/shared/hooks/account/useClient';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { LoaderArea } from '../Loader';
import { BranchAccess } from './BranchAccess';

const Container = styled.div`
  padding: 20px 40px;
  width: 100%;
`;

const InfoText = styled.div`
  margin-right: 10px;
`;

export const BranchesTab = ({ clientId }) => {
  const { t } = useContext(ConfigContext);
  const { data: clientData, loading: isLoadingClient } = useClient({ clientId });
  const { configurations, isLoading } = useClientBranchesAccess({ clientId });

  if (isLoading || isLoadingClient) {
    return <LoaderArea />;
  }

  const branchesOptions = (clientData.branches || []).map(({ id, name }) => ({
    value: id,
    label: name
  }));

  return (
    <Container>
      <InfoText>{t('branches_mapping__tab_info')}</InfoText>
      {configurations.map((config) => (
        <BranchAccess key={config.branch.id} config={config} branchesOptions={branchesOptions} />
      ))}
    </Container>
  );
};
