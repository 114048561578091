import React, { useContext } from 'react';
import usePositions from '@ubeya/shared/hooks/account/usePositions';
import useSuppliers from '@ubeya/shared/hooks/account/useSuppliers';
import ConfigContext from '../../../contexts/ConfigContext';

const PositionChip = ({ value }) => {
  const { t } = useContext(ConfigContext);
  const { mappedPositions } = usePositions();
  const { mappedSupplierPositions } = useSuppliers();

  return (
    <>
      {value
        .map(
          (position) => mappedPositions[position]?.label ?? mappedSupplierPositions[position]?.label ?? t('noPosition')
        )
        .join(', ')}
    </>
  );
};

export default PositionChip;
