import React, { useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import usePortalHook from '../../hooks/usePortal';
import { media } from '../../style';
import { FlexCenter, FlexColumn, FlexMiddle, FlexColumnCenter } from '../Flex';
import { body as cssBody, smallBody as cssSmallBody, Header2SemiBold } from '../Typography';
import Button from '../Button';
import CircleIcon from '../CircleIcon';
import { ReactComponent as BaseInfoIcon } from '../../assets/info.svg';
import { card } from '../Card';
import ConfigContext from '../../contexts/ConfigContext';
import { useModal } from '../Modal';

const Wrapper = styled(FlexCenter)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(19, 21, 41, 0.3);
  z-index: 6;

  ${({ confirmationModalWrapperStyle }) => confirmationModalWrapperStyle};
`;

const InfoIcon = styled(BaseInfoIcon).attrs(() => ({ className: 'info-icon' }))``;

const Container = styled(FlexColumn)`
  position: relative;
  ${card};
  width: 90vw;
  max-height: 60vh;
  padding: 40px 35px;
  ${media.mobile`max-width: 500px;`}

  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(0,0,0,.2)

 ${({ $blockTypeModal, theme }) =>
   $blockTypeModal &&
   css`
     width: 50vh;
     max-height: 80vh;
     min-height: 25vh;
     border: unset;

     border-bottom: 10px solid #ff6464;

     .title {
       color: ${theme.colors.gray000};
     }
     .info-icon {
       g {
         circle {
           stroke: red;
         }
         path {
           fill: red;
         }
       }
     }
   `} 
  }

  ${({ confirmationModalContainerStyle }) => confirmationModalContainerStyle};
  
`;

const TitleColumn = styled(FlexColumnCenter)`
  color: ${({ theme }) => theme.colors.primary};
`;

const CloseButton = styled(CircleIcon)`
  position: absolute;
  top: 28px;
  right: 33px;
`;

const Title = styled(Header2SemiBold).attrs(() => ({ className: 'title' }))`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin: 12px 0 0;
`;

const Content = styled(FlexColumn).attrs(() => ({ className: 'content' }))`
  flex: 1;
  ${cssBody};
  padding: 34px 0;
  text-align: center;
`;

const SecondaryContent = styled(FlexColumn).attrs(() => ({ className: 'secondary-content' }))`
  flex: 1;
  ${cssSmallBody};
  padding-bottom: 10px;
  text-align: center;
`;

const Buttons = styled(FlexMiddle)`
  justify-content: center;
  flex-direction: row-reverse;
  padding: 10px;

  > button {
    min-width: 145px;
    &:not(:first-child) {
      margin-right: 10px;
    }
  }
`;

const ConfirmationModal = ({
  children,
  onClose,
  title,
  body,
  secondaryBody,
  confirmText = 'update',
  onConfirm,
  showExtraButton,
  loadingExtraButton,
  onExtraButtonClick,
  extraButtonText = '',
  disabledConfirm = false,
  loadingConfirm = false,
  cancelText = 'cancel',
  closeOnClickOutside = true,
  onCancelClick = undefined,
  className,
  renderButtons,
  usePortal,
  showCancelButton = true,
  blockTypeModal = false,
  wrapperClassName,
  confirmationModalContainerStyle,
  confirmationModalWrapperStyle,
  secondaryBodyStyle
}) => {
  const { t } = useContext(ConfigContext);

  const container = useRef();

  const { Portal } = usePortalHook({
    enabled: usePortal,
    closeOnClickOutside: false,
    portalId: 'modal'
  });

  const { attachModal, detachModal } = useModal();
  useEffect(() => {
    attachModal({ onClose });

    return () => {
      detachModal({ onClose });
    };
    // ignore onClose as in most cases it's not memoized and causes infinite rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachModal, detachModal]);

  return (
    <Portal>
      <Wrapper
        onClick={() => closeOnClickOutside && onClose()}
        data-modal="confirmationModal"
        className={wrapperClassName}
        confirmationModalWrapperStyle={confirmationModalWrapperStyle}>
        <Container
          confirmationModalContainerStyle={confirmationModalContainerStyle}
          ref={container}
          $blockTypeModal={blockTypeModal}
          className={className}
          onClick={(e) => e.stopPropagation()}>
          <TitleColumn>
            <InfoIcon />
            <Title>{t(title)}</Title>

            <CloseButton icon={CloseIcon} onClick={() => onClose()} />
          </TitleColumn>

          <Content>{body ? t(body) : children}</Content>

          {secondaryBody && <SecondaryContent style={secondaryBodyStyle}>{t(secondaryBody)}</SecondaryContent>}

          {!blockTypeModal && (
            <Buttons>
              <Button
                primary
                type="button"
                disabled={disabledConfirm}
                isLoading={loadingConfirm}
                onClick={onConfirm}
                className="confirmation-confirm-button"
                autoFocus>
                {t(confirmText)}
              </Button>
              {renderButtons?.({ loadingConfirm })}

              {showExtraButton && (
                <Button secondary type="button" isLoading={loadingExtraButton} onClick={onExtraButtonClick}>
                  {t(extraButtonText)}
                </Button>
              )}

              {showCancelButton && (
                <Button
                  secondary
                  type="button"
                  className="confirmation-cancel-button"
                  onClick={onCancelClick || onClose}>
                  {t(cancelText)}
                </Button>
              )}
            </Buttons>
          )}
        </Container>
      </Wrapper>
    </Portal>
  );
};

export default ConfirmationModal;
