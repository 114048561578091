import React from 'react';
import { Field } from 'react-final-form';
import Select from '../Select';

const SelectField = React.forwardRef(
  (
    {
      title,
      name,
      validate,
      type,
      options,
      isMulti = true,
      onChange,
      onBlur,
      showInitialErrorWithoutTouch = false,
      ...restProps
    },
    ref
  ) => {
    return (
      <Field
        name={name}
        validate={validate}
        type={type}
        render={({ input, meta }) => {
          return (
            <Select
              ref={ref}
              {...input}
              value={input.value}
              onChange={(value) => {
                if (onChange) {
                  onChange(value, input);
                } else {
                  input.onChange(value);
                }
              }}
              onBlur={onBlur}
              title={title}
              isMulti={isMulti}
              options={options}
              error={(meta.touched || showInitialErrorWithoutTouch) && meta.error}
              {...restProps}
            />
          );
        }}
      />
    );
  }
);

export default SelectField;
