import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { ReactComponent as MenuIcon } from '../../assets/navigation.svg';

const SortableIcon = styled(MenuIcon).attrs(() => ({ viewBox: '0 0 16 24' }))`
  stroke: ${({ theme }) => theme.colors.gray400};
  width: 16px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

const SortableHandler = SortableHandle(({ className, visible = true }) => (
  <SortableIcon className={className} $visible={visible} />
));

export default SortableHandler;
