import { css } from 'styled-components';
import { flexSpace } from '../components/Flex';
import media, { defaultMediaBreakpoints } from './media';

const convertToMedia = (func) =>
  Object.keys(defaultMediaBreakpoints).reduce(
    (memo, val) => ({
      ...memo,
      [val]: css`
        ${func(val)}
      `
    }),
    {}
  );

export const responsiveRow = convertToMedia(
  (breakPoint) => css`
    flex-direction: column;
    ${media[breakPoint]`flex-direction: row;`}
  `
);

export const responsiveColumn = convertToMedia(
  (breakPoint) => css`
    flex-direction: row;
    ${media[breakPoint]`flex-direction: column;`}
  `
);

export const responsiveHide = convertToMedia(
  (breakPoint) => css`
    display: inherit;
    ${media[breakPoint]`display:none;`}
  `
);

export const responsiveShow = convertToMedia(
  (breakPoint) => css`
    display: none;
    ${media[breakPoint]`display:inherit;`}
  `
);
export const responsiveShowFlex = convertToMedia(
  (breakPoint) => css`
    display: none;
    ${media[breakPoint]`display:flex;`}
  `
);

export const responsiveFields = convertToMedia(
  (breakPoint) => css`
    ${flexSpace};
    flex-wrap: wrap;
    margin-top: -30px;

    > div {
      width: 100%;
      margin-top: 20px;
      align-self: flex-start;
    }

    ${media[breakPoint]`
      > div {
        width: 48%;
      }

      > .full {
        width: 100%;
      }

      >.only{
        margin-right: 48%;
      }
    `}
  `
);

export const preventLineBreak = css`
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;
