import { useMemo } from 'react';
import useDimensions from './useDimensions';

const useMenuDirection = ({ dependencies = [], wrapperRef } = {}) => {
  const { ref: containerRef, dimensions: containerDimensions } = useDimensions(dependencies, wrapperRef);
  const { ref: menuRef, dimensions: menuDimensions } = useDimensions(dependencies);

  const direction = useMemo(() => {
    const inModal = document.getElementById('modal-container')?.contains?.(wrapperRef.current);
    const targetDimensions = (inModal
      ? document.getElementById('modal-container')
      : document.body
    )?.getBoundingClientRect?.();

    if (containerDimensions?.bottom + menuDimensions.height > targetDimensions?.bottom) {
      if (containerDimensions?.top - menuDimensions.height < targetDimensions?.top) {
        return 'down';
      }
      return 'up';
    }
    return 'down';
  }, [containerDimensions?.bottom, containerDimensions?.top, menuDimensions.height, wrapperRef]);

  return { containerRef, menuRef, direction, containerDimensions, menuDimensions };
};

export default useMenuDirection;
