import { useContext, useEffect, useMemo } from 'react';
import ConfigContext from '../contexts/ConfigContext';
import useMenuDirection from './useMenuDirection';
import usePortalHook from './usePortal';

const useMenu = ({ usePortal, openFromRight, menuOffset = {}, portalId = 'select', onClose, scrollingContainer }) => {
  const { isRtl } = useContext(ConfigContext);

  const { Portal, clickRef: wrapperRef, isOpen, setOpen, setClose, setToggle, coordinates } = usePortalHook({
    enabled: usePortal,
    portalId,
    onClose
  });

  const {
    menuRef,
    direction,
    containerDimensions: { height: containerHeight, width: containerWidth, left: containerLeft },
    menuDimensions: { height: menuHeight, width: menuWidth }
  } = useMenuDirection({ wrapperRef, dependencies: [isOpen] });

  const style = useMemo(() => {
    if (!usePortal) {
      const base = {
        position: 'absolute',
        minWidth: containerWidth,
        right: openFromRight ? menuOffset.right || 0 : undefined,
        left: openFromRight ? undefined : menuOffset.left || 0
      };
      return direction === 'up'
        ? { ...base, bottom: containerHeight + (menuOffset.top || 0) }
        : { ...base, top: containerHeight + (menuOffset.top || 0) };
    }

    const location = (condition) =>
      condition
        ? containerLeft - menuWidth + containerWidth + (menuOffset.left || 0)
        : containerLeft + (menuOffset.left || 0);

    const base = {
      position: 'fixed',
      minWidth: containerWidth,
      left: isRtl ? undefined : location(openFromRight),

      right: !isRtl ? undefined : location(!openFromRight)
    };
    return direction === 'up'
      ? { ...base, top: coordinates?.y - menuHeight + (menuOffset.top || 0) }
      : { ...base, top: coordinates?.y + containerHeight + (menuOffset.top || 0) };
  }, [
    containerHeight,
    containerLeft,
    containerWidth,
    coordinates?.y,
    direction,
    isRtl,
    menuHeight,
    menuOffset.left,
    menuOffset.right,
    menuOffset.top,
    menuWidth,
    openFromRight,
    usePortal
  ]);

  useEffect(() => {
    if (usePortal && scrollingContainer) {
      const node = scrollingContainer.current;
      node.addEventListener('scroll', setClose);
      return () => node.removeEventListener('scroll', setClose);
    }
  }, [scrollingContainer, setClose, usePortal]);

  return { Portal, wrapperRef, isOpen, setOpen, setClose, setToggle, style, menuRef };
};

export default useMenu;
