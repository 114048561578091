import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';

const BookedInProjectChip = () => {
  const { t } = useContext(ConfigContext);

  return <div>{t('event')}</div>;
};

export default BookedInProjectChip;
