import React, { useCallback, useState, useMemo, useEffect, useRef, useContext, useImperativeHandle } from 'react';
import styled from 'styled-components';
import useMenu from '../../../hooks/useMenu';
import usePressKey from '../../../hooks/usePressKey';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow.svg';
import { FlexMiddle } from '../../Flex';
import Label from '../../Label';
import ConfigContext from '../../../contexts/ConfigContext';
import { Loader as BaseLoader } from '../../Loader';
import ErrorText from '../../Input/ErrorText';
import { Card } from '../../Card';
import Options from './Options';
import Value from './Value';
import MultiValue from './MultiValue';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'select' }))`
  position: relative;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  outline: 0;
  background: ${({ theme }) => theme.colors.surface};
  ${({ $isDisabled }) => $isDisabled && 'background-color: #f8f8f8; cursor: not-allowed;'};

  .error-text-wrapper {
    position: absolute;
    bottom: -15px;
  }

  ${({ theme, error }) => error && `border-color: ${theme.colors.error};`}

  ${({ theme, isOpen }) =>
    isOpen && `box-shadow: ${theme.colors.inputShadow};  border: 1px solid ${theme.colors.primary};`}

  &:hover, &:focus {
    border: 1px solid rgba(25, 25, 25, 0.6);
  }

  ${({ $noBorder }) =>
    $noBorder &&
    `border: none;
    box-shadow:none;
    &:hover, &:focus {
      border: none;
      box-shadow:none;
      }
`}
`;

const Arrow = styled(ArrowIcon).attrs(() => ({ className: 'select-arrow' }))`
  pointer-events: none;
  width: 10px;
  transform: rotate(${({ $isOpen }) => ($isOpen ? 270 : 90)}deg);
  transition: 200ms;
  > path {
    ${({ $isDisabled, theme }) => $isDisabled && `fill: ${theme.colors.gray500};`}
  }
`;

const Loader = styled(BaseLoader).attrs(() => ({ size: 18 }))`
  margin-right: 10px;
`;

const NoResults = styled(Card).attrs(() => ({ className: 'select-menu' }))`
  z-index: 10;
  max-height: 40px;
  min-height: 40px;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  border-radius: 6px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
`;

const ArrowContainer = styled(FlexMiddle).attrs(() => ({ className: 'select-arrow-container' }))`
  padding-right: 10px;
  height: 100%;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'normal' : 'pointer')};
`;

const MENU_OFFSET = { top: 7 };

const BaseSelect = React.forwardRef((props, ref) => {
  const {
    title,
    required,
    className,
    menuClassName,
    value,
    onChange,
    options,
    formatValue,
    formatOption,
    filterFunc,
    defaultValue,
    placeholder,
    isMulti = false,
    usePortal = false,
    isDisabled = false,
    checkCloseUponOptionSelectFunc,
    error,
    isRaw = false,
    // eslint-disable-next-line
    onFocus,
    creatable = false,
    searchable = true,
    optionStyle,
    clearable = false,
    hideTitle = false,
    isLoading = false,
    setControlledSearch,
    getOptionSpecificStyle,
    valuesComparator = (a, b) => a === b,
    onBlur,
    noBorder,
    ...restProps
  } = props;
  const { t } = useContext(ConfigContext);
  const [search, setInnerSearch] = useState('');
  const inputRef = useRef();

  const setSearch = useCallback(
    (val) => {
      setInnerSearch(val);
      setControlledSearch?.(val);
    },
    [setControlledSearch]
  );

  const { Portal, wrapperRef, isOpen, setOpen, setClose, setToggle, style, menuRef } = useMenu({
    usePortal,
    menuOffset: MENU_OFFSET
  });

  useImperativeHandle(
    ref,
    () => ({
      setOpen
    }),
    [setOpen]
  );

  const handleSelect = useCallback(
    (newValue) => {
      if (isMulti) {
        onChange([...value, newValue]);
      } else {
        onChange(newValue);
      }
      setSearch('');

      (checkCloseUponOptionSelectFunc ? checkCloseUponOptionSelectFunc(newValue) : true) && setClose();
    },
    [isMulti, onChange, checkCloseUponOptionSelectFunc, setClose, setSearch, value]
  );

  const handleRemoveChip = useCallback((selectedValue) => onChange(value.filter((item) => item !== selectedValue)), [
    onChange,
    value
  ]);

  const formattedOptions = useMemo(() => {
    if (isMulti) {
      return options.filter((option) => !value.includes(option.value));
    }
    return options;
  }, [isMulti, options, value]);

  // UP
  usePressKey({ which: 38, ref: wrapperRef, onSelect: () => !isOpen && setOpen() });

  // DOWN
  usePressKey({ which: 40, ref: wrapperRef, onSelect: () => !isOpen && setOpen() });

  // BACKSPACE
  usePressKey({
    which: 8,
    ref: wrapperRef,
    onSelect: () => isMulti && !search && value.length > 0 && handleRemoveChip(value[value.length - 1]),
    preventDefault: false
  });

  useEffect(() => {
    if (search) {
      setOpen();
    }
  }, [search, setOpen]);

  const handleClick = isDisabled ? undefined : setToggle;

  return (
    <Label title={hideTitle ? '' : title} required={required} className={className}>
      <Wrapper
        $isDisabled={isDisabled}
        $noBorder={noBorder}
        ref={wrapperRef}
        error={error}
        isOpen={isOpen}
        onBlur={(e) => {
          onBlur?.(e);
          !isOpen && setSearch('');
        }}>
        {isMulti ? (
          <MultiValue
            value={value}
            options={options}
            placeholder={placeholder ?? t('searchPlaceholder')}
            search={search}
            setSearch={setSearch}
            onClick={handleClick}
            isDisabled={isDisabled}
            searchable={searchable}
            onRemoveChip={handleRemoveChip}
            isOpen={isOpen}
            {...restProps}
          />
        ) : (
          <Value
            inputRef={inputRef}
            isRaw={isRaw}
            value={value}
            onClick={handleClick}
            options={options}
            formatValue={formatValue}
            placeholder={placeholder ?? t('searchPlaceholder')}
            search={search}
            setSearch={setSearch}
            isDisabled={isDisabled}
            searchable={searchable}
            valuesComparator={valuesComparator}
            isOpen={isOpen}
            clearable={clearable}
            onClear={() => handleSelect()}
            handleSelect={handleSelect}
            {...restProps}
          />
        )}

        {isLoading ? (
          <Loader />
        ) : (
          <ArrowContainer $isDisabled={isDisabled} onClick={handleClick}>
            <Arrow $isOpen={isOpen} $isDisabled={isDisabled} />
          </ArrowContainer>
        )}
        {isOpen && !!formattedOptions?.length && (
          <Portal>
            <Options
              isMulti={isMulti}
              inputRef={inputRef}
              value={value}
              formatOption={formatOption}
              defaultValue={defaultValue}
              handleSelect={handleSelect}
              search={search}
              filterFunc={filterFunc}
              options={formattedOptions}
              creatable={creatable}
              wrapperRef={wrapperRef}
              menuRef={menuRef}
              style={style}
              optionStyle={optionStyle}
              getOptionSpecificStyle={getOptionSpecificStyle}
              className={menuClassName}
            />
            {isOpen && (!formattedOptions?.length ? <NoResults style={style}>{t('noOptions')}</NoResults> : null)}
          </Portal>
        )}

        {!!error && <ErrorText error={error} />}
      </Wrapper>
    </Label>
  );
});

export default BaseSelect;
