import React, { useMemo, useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import DropDown from '../DropDown';
import { Small } from '../Typography';
import { flexColumnCenter } from '../Flex';
import { counterFormatter, formatOptions, applyFilter } from '../../utils/filters';
import ConfigContext from '../../contexts/ConfigContext';
import PositionsFilter from './CustomFilters/PositionsFilter';
import AgeFilter from './CustomFilters/AgeFilter';
import RatingFilter from './CustomFilters/RatingFilter';
import EmployeeTypeFilter from './CustomFilters/EmployeeTypeFilter';
import AdminTypeFilter from './CustomFilters/AdminTypeFilter';
import AdminFilter from './CustomFilters/AdminFilter';
import TagsFilter from './CustomFilters/TagsFilter';
import ClientsFilter from './CustomFilters/ClientsFilter';
import ApprovalFilter from './CustomFilters/ApprovalFilter';
import LocationsFilter from './CustomFilters/LocationsFilter';
import HapAppFilter from './CustomFilters/HasAppFilter';
import TempHasAppFilter from './CustomFilters/TempHasAppFilter';

const COMPONENTS_MAP = {
  positions: PositionsFilter,
  age: AgeFilter,
  rating: RatingFilter,
  employeeType: EmployeeTypeFilter,
  adminType: AdminTypeFilter,
  eventBooker: AdminFilter,
  restrictedClient: ClientsFilter,
  previouslyWorkedClient: TempHasAppFilter,
  tags: TagsFilter,
  clients: ClientsFilter,
  approved: ApprovalFilter,
  locations: LocationsFilter,
  hasApp: HapAppFilter
};

const Counter = styled(Small)`
  ${flexColumnCenter};
  width: 17px;
  height: 14px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.gray400};
  color: ${({ theme }) => theme.colors.surface};
  margin-left: 7px;
`;

const TempFilterOptions = ({ filters, setFilters, selectedOpenFilter, disabledFilters }) => {
  const { t } = useContext(ConfigContext);
  const [openFilter, setOpenFilter] = useState(selectedOpenFilter);

  const data = useMemo(
    () => ({
      temp: {
        basic: [
          { id: 10, kind: 'events', slug: 'Previously Worked for client' },
          { id: 11, kind: 'events', slug: 'locations' },
          { id: 1, kind: 'employees', slug: 'rating' }
          //   { id: 5, kind: 'employees', slug: 'employeeType' },
          //   { id: 6, kind: 'employees', slug: 'adminType' },
          //   { id: 12, kind: 'employees', slug: 'restrictedClient' },
          //   { id: 13, kind: 'employees', slug: 'hasApp' },
          //   { id: 15, kind: 'employees', slug: 'bookedInProjects' },
          //   { id: 16, kind: 'employees', slug: 'isShiftType' }
        ],
        fields: []
      }
    }),
    []
  );

  const basicOptions = useMemo(() => formatOptions({ options: data.temp, type: 'basic', filters }), [data, filters]);

  const getCounter = useCallback(
    (filter) => (counterFormatter(filter.value) ? <Counter>{counterFormatter(filter.value)}</Counter> : null),
    []
  );

  const handleApply = useCallback(
    (value) => {
      const { type, id, slug } = openFilter;
      setFilters(applyFilter({ type, id, value, slug, allFilters: filters }));
      !selectedOpenFilter && setOpenFilter();
    },
    [filters, openFilter, selectedOpenFilter, setFilters]
  );

  if (openFilter) {
    const Component = COMPONENTS_MAP[openFilter.slug] || TempHasAppFilter;

    return (
      <>
        <Component
          id={openFilter.id}
          defaultValue={openFilter.value}
          handleClose={selectedOpenFilter ? undefined : () => setOpenFilter()}
          onApply={handleApply}
        />
      </>
    );
  }

  return (
    <>
      {basicOptions.map((filter, index) => (
        <DropDown.Item
          key={index}
          onClick={() => setOpenFilter(filter)}
          closeOnClick={false}
          isDisabled={disabledFilters.includes(filter.slug)}>
          {t(filter.slug)} {getCounter(filter)}
        </DropDown.Item>
      ))}
    </>
  );
};

export default TempFilterOptions;
