import { useMemo, useContext } from 'react';
import { useQuery } from 'react-query';
import { merge } from 'lodash';
import createCachedSelector from 're-reselect';
import PrivilegesContext from '@ubeya/shared-web/contexts/PrivilegesContext';
import privileges from '@ubeya/shared-web/privileges';
import * as api from '../../services/api';
import useCRUD from '../useCRUD';
import useOptimisticMutation from '../useOptimisticMutation';
import { SORT_BY_STRING } from '../../utils/sorting';
import useAccount from './useAccount';

const COLORS = [
  { id: 1, color: '#1abc9c' },
  { id: 2, color: '#2ecc71' },
  { id: 3, color: '#3498db' },
  { id: 4, color: '#9b59b6' },
  { id: 5, color: '#34495e' },
  { id: 6, color: '#f1c40f' },
  { id: 7, color: '#e67e22' },
  { id: 8, color: '#e74c3c' },
  { id: 9, color: '#bdc3c7' },
  { id: 10, color: '#9c88ff' },
  { id: 11, color: '#273c75' },
  { id: 12, color: '#192a56' }
];

const filterBranchesByPrivileges = (branches, myPrivileges) => {
  if (myPrivileges[privileges.ACCOUNT_ADMIN]) {
    return branches;
  }

  if (myPrivileges[privileges.BRANCH_ADMIN]) {
    return branches.filter(({ isAdmin }) => isAdmin);
  }

  return [];
};

const selector = createCachedSelector(
  (data) => data.data,
  (data, myPrivileges) => myPrivileges,
  (data, myPrivileges) => {
    const mappedColors = COLORS.reduce((prev, { id, color }) => ({ ...prev, [id]: color }), {});

    const branches = data || [];

    const branchesOptions = (filterBranchesByPrivileges(branches, myPrivileges) || [])
      .map(({ id, name }) => ({
        value: id,
        label: name
      }))
      ?.sort(SORT_BY_STRING('label'));

    const mappedBranches = branches.reduce(
      (prev, branch) => ({ ...prev, [branch.id]: { ...branch, color: mappedColors[branch.id % COLORS.length] } }),
      {}
    );

    return { branches, branchesOptions, mappedBranches };
  }
)({
  keySelector: (data, myPrivileges, storeKey) => `${storeKey.join('#')}`
});

const useBranches = () => {
  const { accountId } = useAccount();
  const storeKey = useMemo(() => ['branches', accountId], [accountId]);

  const myPrivileges = useContext(PrivilegesContext);

  const { isLoading, data } = useQuery(storeKey, () => api.fetchBranches({ accountId }), {
    enabled: !!accountId,
    select: (branchesData) => selector(branchesData, myPrivileges, storeKey)
  });

  const { branches = [], branchesOptions = [], mappedBranches = {} } = data || {};

  const { addItem } = useCRUD(storeKey, {
    addApi: (branch) => api.addBranch({ accountId, branch })
  });

  const { mutateAsync: editItem } = useOptimisticMutation(
    storeKey,
    ({ branchId, branch }) => api.updateBranch({ accountId, branchId, branch }),
    ({ previousData, branchId, branch }) => ({
      ...previousData,
      data: previousData.data.map((item) => (item.id !== branchId ? item : merge(item, branch)))
    })
  );

  return {
    isLoading,
    branches,
    branchesOptions,
    mappedBranches,
    addBranch: addItem,
    editBranch: editItem
  };
};

export default useBranches;
