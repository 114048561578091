import { controlledColumnsMenuOpenData } from '@ubeya/shared/atoms/shared';
import { useCallback, useEffect, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useRecoilState } from 'recoil';
import usePersistedState from './usePersistedState';

const useVisibleColumn = (allColumns = [], storageName) => {
  const [openColumnsMenuData, setOpenColumnsMenuData] = useRecoilState(controlledColumnsMenuOpenData);

  const getVisibleColumn = useCallback((columns) => {
    return columns
      .filter((column) => column)
      .reduce((prev, { dataKey, visible }) => ({ ...prev, [dataKey]: visible !== false }), {});
  }, []);

  const [visibleColumns, setVisibleColumns] = usePersistedState(storageName, () => getVisibleColumn(allColumns));

  const updateVisibleColumns = (selectedVisibleColumns) => {
    setVisibleColumns(selectedVisibleColumns.reduce((prev, dataKey) => ({ ...prev, [dataKey]: true }), {}));
    setOpenColumnsMenuData(null);
  };

  const setIsMenuOpen = (value) => setOpenColumnsMenuData((prev) => ({ ...prev, isMenuOpen: value }));

  const columns = useMemo(
    () =>
      allColumns.filter(
        (column) => column && visibleColumns[column.dataKey] && (isMobileOnly ? !column.hideOnMobile : true)
      ),
    [allColumns, visibleColumns]
  );

  const resetToDefault = useCallback(() => {
    setVisibleColumns(getVisibleColumn(allColumns));
    localStorage.removeItem(storageName);
  }, [allColumns, getVisibleColumn, setVisibleColumns, storageName]);

  useEffect(() => {
    if (!storageName) {
      setVisibleColumns(getVisibleColumn(allColumns));
    }
  }, [allColumns, getVisibleColumn, setVisibleColumns, storageName]);

  const setColumnStorageName = useCallback(
    (value) => setOpenColumnsMenuData((prev) => ({ ...prev, columnStorageName: value })),
    [setOpenColumnsMenuData]
  );

  return {
    visibleColumns: columns,
    updateVisibleColumns,
    isMenuOpen: openColumnsMenuData?.isMenuOpen || false,
    setIsMenuOpen,
    resetToDefault,
    columnStorageName: openColumnsMenuData?.columnStorageName || null,
    setColumnStorageName
  };
};

export default useVisibleColumn;
