import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { viewAsClient } from '../../atoms/shared';
import * as api from '../../services/api';
import { SORT_BY_STRING } from '../../utils/sorting';
import useInactivityTimeout from './useInactivityTimeout';
import { removeLastActivityTime } from './inactivityUtils';

const BASE_AUTH_URL = process.env.AUTH_URL;
const storeKey = 'user';

const useUser = (options) => {
  const queryClient = useQueryClient();
  const [ubeyaAdminViewAsClient, setUbeyaAdminViewAsClient] = useRecoilState(viewAsClient);

  const { isLoading, isSuccess: isAuth, data, refetch } = useQuery(storeKey, api.fetchUser, {
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options
  });

  const logout = useCallback(
    (type) => {
      removeLastActivityTime();
      queryClient.clear();
      window.location = `${BASE_AUTH_URL}/logout/${type}`;
    },
    [queryClient]
  );

  const isUbeyaAdmin = data?.isUbeyaAdmin;

  const { startInactivityInterval, stopInactivityInterval } = useInactivityTimeout({
    isAuth,
    logout,
    isUbeyaAdmin
  });

  const sortedAccounts = useMemo(() => {
    const nameSorter = SORT_BY_STRING('name');
    return (data?.accounts || []).sort(nameSorter);
  }, [data?.accounts]);

  return {
    isLoading,
    ubeyaAdminViewAsClient,
    setUbeyaAdminViewAsClient,
    isAuth,
    language: data?.language || 'en',
    accounts: sortedAccounts,
    data: data || {},
    logout,
    refetch,
    isUbeyaAdmin,
    startInactivityInterval,
    stopInactivityInterval
  };
};

export default useUser;
