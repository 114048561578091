import React, { useCallback, forwardRef, useContext, useMemo } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import styled, { createGlobalStyle } from 'styled-components';
// Look for when a dynamic import is supported
import { AG_GRID_LOCALE_IL, AG_GRID_LOCALE_DE, AG_GRID_LOCALE_NL, AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';

import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const SHIFT_AG_GRID_NEW_ROW_ID = 'new-row';

const locales = {
  he: AG_GRID_LOCALE_IL,
  de: AG_GRID_LOCALE_DE,
  es: AG_GRID_LOCALE_ES,
  nl: AG_GRID_LOCALE_NL
};

ModuleRegistry.registerModules([
  RowGroupingModule,
  ClientSideRowModelModule,
  MenuModule,
  FiltersToolPanelModule,
  SetFilterModule,
  MultiFilterModule,
  ColumnsToolPanelModule
]);

const GlobalAgGridStyles = createGlobalStyle`
  .ag-theme-alpine .ag-cell-inline-editing {
    border: none;
    box-shadow: none;
    background: transparent;
  }

  .ag-theme-alpine .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
  .ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-alpine .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
  .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
  .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-theme-alpine .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
  .ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-alpine .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
  .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
  .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border-color: transparent !important;
    border-style: solid;
    border-width: 1px;
  }

  .ag-theme-alpine .ag-ltr .ag-cell,
  .ag-theme-alpine .ag-rtl .ag-cell{
    display: flex;
    align-items: center;
    font-family: ${({ $isRtl }) => ($isRtl ? 'Rubik' : 'Roboto')};
    color: ${({ theme }) => theme.colors.tabLink};
  }
`;
const StyledAGGrid = styled(AgGridReact)`
  font-family: ${({ $isRtl }) => ($isRtl ? 'Rubik' : 'Roboto')};

  /* Cell Editing */
  .ag-editable-cell {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray200} !important;
  }

  /* Centering */
  .ag-header-cell-label,
  .ag-cell,
  .ag-rtl .ag-cell,
  .ag-header-cell-comp-wrapper {
    justify-content: center;
  }
  .justify-start {
    justify-content: flex-start;
  }

  /* Cell dynamic Styling */
  .ag-header-cell-filter-button:hover {
    color: ${({ theme }) => theme.colors.dashboard.purple} !important;
  }
  .ag-icon:hover {
    color: ${({ theme }) => theme.colors.dashboard.purple} !important;
  }
  .ag-cell-value-modified {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }

  [row-id='${SHIFT_AG_GRID_NEW_ROW_ID}'] {
    background-color: ${({ theme }) => theme.colors.bottomStickyTab};
  }

  /* Row marking */
  .ag-grid__row-marker:before {
    position: absolute;
    height: 100%;
    display: block;
    width: 6px;
    left: 0;
    top: 0;
    content: '';
  }

  .ag-grid__row-marker.ag-grid__${SHIFT_AG_GRID_NEW_ROW_ID}:before {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .ag-grid__row-marker.ag-grid__scroll-to-row:before {
    background-color: ${({ theme }) => theme.colors.selectedShiftIndicator};
  }
  .ag-grid__overbooked {
    background-color: ${({ theme }) => theme.colors.eventModal.overbookingShift};
  }

  .ag-cell:hover [data-grid='ag-carrot-arrow'] {
    display: block !important;
  }
`;

const AgGrid = forwardRef(({ gridOptions }, gridRef) => {
  const { isRtl } = useContext(ConfigContext);
  const { language } = gridOptions;
  const languageModule = useMemo(() => (locales[language] ? locales[language] : undefined), [language]);

  const onGridReady = useCallback(
    (event) => {
      if (gridRef) {
        gridRef.current = event.api;
      }
      gridOptions?.onGridReady?.(event);
    },
    [gridOptions, gridRef]
  );

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      <GlobalAgGridStyles $isRtl={isRtl} />
      <StyledAGGrid
        $isRtl={isRtl}
        modules={[
          RowGroupingModule,
          ClientSideRowModelModule,
          MenuModule,
          FiltersToolPanelModule,
          SetFilterModule,
          MultiFilterModule,
          ColumnsToolPanelModule
        ]}
        gridOptions={{
          ...gridOptions,
          onGridReady,
          enableRtl: isRtl,
          localeText: languageModule
        }}
      />
    </div>
  );
});

export default AgGrid;
