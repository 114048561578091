import React, { useCallback } from 'react';
import styled from 'styled-components';
import { List as BaseList } from 'react-virtualized';
import scrollbar from '../../style/scrollbar';

const StyledList = styled(BaseList)`
  outline: none;
  ${scrollbar};
  direction: ltr !important;
`;

const List = ({ innerRef, ...props }) => {
  const overscanIndicesGetter = useCallback(
    ({ cellCount, overscanCellsCount, startIndex, stopIndex }) => ({
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount)
    }),
    []
  );

  return <StyledList {...props} ref={innerRef} overscanIndicesGetter={overscanIndicesGetter} />;
};

export default List;
