import React, { useCallback, useRef } from 'react';
import { CellWrapper, SortButtons, SortArrow, ResizeHandler } from './styles';

const HeaderCell = ({ column, sortBy, sortDirection, onSort, onResize, HeaderCellRenderer, isRtl, gridApi }) => {
  const onPointerDown = useCallback(
    (event) => {
      if (event.pointerType === 'mouse' && event.buttons !== 1) {
        return;
      }

      const { currentTarget, pointerId } = event;
      const { right, left } = currentTarget.parentNode.getBoundingClientRect();
      const offset = isRtl ? left - event.clientX : right - event.clientX;

      if (offset > 11) {
        // +1px to account for the border size
        return;
      }

      const onPointerMove = (pointerMoveEvent) => {
        if (pointerMoveEvent.pointerId !== pointerId) return;
        if (pointerMoveEvent.pointerType === 'mouse' && pointerMoveEvent.buttons !== 1) {
          onPointerUp();
          return;
        }
        const width = isRtl
          ? currentTarget.parentNode.getBoundingClientRect().right - pointerMoveEvent.clientX + offset
          : pointerMoveEvent.clientX + offset - currentTarget.parentNode.getBoundingClientRect().left;
        if (width > 0) {
          onResize(column, width);
        }
      };

      const onPointerUp = () => {
        if (event.pointerId !== pointerId) return;
        window.removeEventListener('pointermove', onPointerMove);
        window.removeEventListener('pointerup', onPointerUp);
      };

      event.preventDefault();
      window.addEventListener('pointermove', onPointerMove);
      window.addEventListener('pointerup', onPointerUp);
    },
    [column, isRtl, onResize]
  );

  const { Header, dataKey, headerComponentProps, resizable, sortable, className = '' } = column;

  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  const handleSort = useCallback(
    () =>
      onSort?.({
        sortBy: dataKey,
        sortDirection: sortDirection === 'desc' ? 'asc' : 'desc'
      }),
    [dataKey, onSort, sortDirection]
  );

  const wrapper = useRef();

  return (
    <CellWrapper
      ref={wrapper}
      className={`header ${className}`}
      column={column}
      onClick={sortable ? handleSort : undefined}>
      <HeaderCellRenderer>
        {typeof Header === 'string' ? Header : <Header {...headerComponentProps} gridApi={gridApi} />}

        {sortable && (
          <SortButtons isActive={dataKey === sortBy}>
            <SortArrow direction="asc" $isActive={dataKey === sortBy && sortDirection === 'asc'} />
            <SortArrow direction="desc" $isActive={dataKey === sortBy && sortDirection === 'desc'} />
          </SortButtons>
        )}
      </HeaderCellRenderer>

      {resizable && <ResizeHandler onClick={stopPropagation} onPointerDown={resizable ? onPointerDown : undefined} />}
    </CellWrapper>
  );
};

export default HeaderCell;
