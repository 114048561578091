import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import BaseReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import BaseModal from '../Modal';

const Modal = styled(BaseModal)`
  width: auto;
  max-height: 70vh;
`;

const ReactCrop = styled(BaseReactCrop)`
  img {
    max-height: 53vh;
  }
`;

const ImageCrop = ({ image, onClose, onConfirm, aspect = 1 / 1, squareCrop = true }) => {
  const imageRef = useRef();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [crop, setCrop] = useState(() => ({ unit: '%', aspect }));

  const dataURLtoFile = useCallback((dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppedImage = new File([u8arr], filename, { type: mime });
    setCroppedImageUrl(croppedImage);
  }, []);

  const getCroppedImg = useCallback(
    (croppedImage, cropData) => {
      const canvas = document.createElement('canvas');
      const scaleX = croppedImage.naturalWidth / croppedImage.width;
      const scaleY = croppedImage.naturalHeight / croppedImage.height;
      canvas.width = cropData.width;
      canvas.height = cropData.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        croppedImage,
        cropData.x * scaleX,
        cropData.y * scaleY,
        cropData.width * scaleX,
        cropData.height * scaleY,
        0,
        0,
        cropData.width,
        cropData.height
      );

      const reader = new FileReader();
      canvas.toBlob((blob) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          dataURLtoFile(reader.result, 'cropped.jpg');
        };
      });
    },
    [dataURLtoFile]
  );

  const onImageLoaded = useCallback(
    (loadedImage) => {
      imageRef.current = loadedImage;
      const smaller = Math.min(loadedImage.width, loadedImage.height);
      const cropWidth = squareCrop ? smaller : loadedImage.width;
      const cropHeight = squareCrop ? smaller : loadedImage.height;

      const cropData = { width: cropWidth, height: cropHeight, x: 0, y: 0, aspect: squareCrop ? 1 : aspect };
      setCrop(cropData);
      getCroppedImg(loadedImage, cropData);
      return false;
    },
    [aspect, getCroppedImg, squareCrop]
  );

  const onCropComplete = useCallback(
    (cropData) => {
      if (imageRef.current && cropData.width && cropData.height) {
        getCroppedImg(imageRef.current, cropData);
      }
    },
    [getCroppedImg]
  );

  const onCrop = useCallback(async () => {
    setIsUploading(true);
    await onConfirm(croppedImageUrl);
    setIsUploading(false);
    onClose();
  }, [croppedImageUrl, onClose, onConfirm]);

  return (
    <Modal
      onClose={onClose}
      loadingConfirm={isUploading}
      onConfirm={onCrop}
      title="cropImage"
      confirmText="crop"
      isPaddingContent={false}
      showCancelButton
      size="medium"
      usePortal>
      <ReactCrop src={image} crop={crop} onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={setCrop} />
    </Modal>
  );
};

export default ImageCrop;
