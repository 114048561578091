import React, { useState, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from '@ubeya/shared-web/assets/error.svg';
import { ReactComponent as WarningIcon } from '@ubeya/shared-web/assets/warning.svg';
import { ReactComponent as OKIcon } from '@ubeya/shared-web/assets/ok.svg';
import DropDown from '../../DropDown';
import BaseCheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';
import { FlexCenter } from '../../Flex';
import {
  BOOKING_ISSUES_MAPPING,
  NOTICEABLE_BOOKING_ISSUE,
  OK_BOOKING_ISSUE,
  WARNING_BOOKING_ISSUE
} from '../../../constants';

const CheckBox = styled(BaseCheckBox)`
  flex: 1;
`;

const Option = styled(FlexCenter)`
  gap: 4px;
`;

const IconStatusMap = {
  error: <ErrorIcon />,
  warning: <WarningIcon />,
  ok: <OKIcon />
};

const ProjectShiftStatusFilter = ({ defaultValue, handleClose, onApply, hideLayout = false, search, onChange }) => {
  const { t } = useContext(ConfigContext);
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const options = useMemo(
    () => [
      {
        label: t(BOOKING_ISSUES_MAPPING.error),
        value: WARNING_BOOKING_ISSUE
      },
      {
        label: t(BOOKING_ISSUES_MAPPING.warning),
        value: NOTICEABLE_BOOKING_ISSUE
      },
      {
        label: t(BOOKING_ISSUES_MAPPING.ok),
        value: OK_BOOKING_ISSUE
      }
    ],
    [t]
  );

  const filteredOptions = useMemo(
    () => (search ? options.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase())) : options),
    [options, search]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout name={t('issues')} handleClose={handleClose} onApply={() => onApply(values)} hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox name={`status-${value}`} value={values.includes(value)} onChange={() => handleChange(value)}>
            <Option>
              {IconStatusMap?.[value] ? IconStatusMap[value] : <></>}
              {label}
            </Option>
          </CheckBox>
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ProjectShiftStatusFilter;
