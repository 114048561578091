import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router';
import useAccount from '@ubeya/shared/hooks/account/useAccount';
import { useSetRecoilState } from 'recoil';
import { schedulingTimeRange } from '@ubeya/figaro/src/shared/atoms/scheduling';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import CircleIcon from '@ubeya/shared-web/components/CircleIcon';
import { ReactComponent as CloseIcon } from '@ubeya/shared-web/assets/close.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { TextWithTooltip } from '../Tooltip';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin: 20px;
`;

const GridCell = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.supplierModal.border};
  border-right: 1px solid ${({ theme }) => theme.colors.supplierModal.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.supplierModal.border};
  padding: 16px;
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const ShiftNameCell = styled(GridCell)`
  border-left: 1px solid ${({ theme }) => theme.colors.supplierModal.border};
  max-width: 120px;
  justify-content: flex-start;
`;

const GridLabel = styled.div`
  grid-column: 1 / -1;
  background: ${({ theme }) => theme.colors.supplierModal.label};
  margin: 10px 0;
  padding: 10px 16px;
  font-weight: 500;
  border-radius: 2px;
`;

const Details = styled.ul`
  margin: 0 20px;
  padding: 0;
  list-style: none;

  li {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
    display: flex;
    padding: 10px;
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 12rem;
  width: 130px;
`;

const Title = styled.div`
  display: flex;
  font-size: 14rem;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
  margin-bottom: 10px;
  padding: 10px 30px;
  line-height: 1.5;
`;

const CloseIconComponent = styled(CircleIcon)`
  margin-left: auto;
  width: 23px;
  height: 23px;
  padding: 6px;
`;

const Location = styled.div`
  max-width: 200px;
`;

const ProjectName = styled.button.attrs(() => ({ type: 'button' }))`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const OrdersTabDetails = ({
  order: { project, createdAt, clientProjectId, supplierProjectId },
  onClose,
  isClient = false
}) => {
  const { accountId } = useAccount();
  const { t, timeFormat, shortDateFormat } = useContext(ConfigContext);
  const ref = useRef();
  const { push } = useHistory();
  const setTimeRange = useSetRecoilState(schedulingTimeRange);

  useOnClickOutside(ref, () => {
    onClose();
  });

  const handleProjectNameClick = () => {
    const startDate = moment(project.date).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(project.date).endOf('month').format('YYYY-MM-DD');
    setTimeRange({ key: 'monthly', startDate, endDate });
    push(
      `/${accountId}/scheduling?key=monthly&projectId=${
        isClient ? clientProjectId : supplierProjectId
      }&startDate=${startDate}&endDate=${endDate}`
    );
  };

  const dateFormat = `${shortDateFormat}, ddd`;

  const dates = project.isMultiDay
    ? `${moment(project.date).format(dateFormat)} - ${moment(project.endDate).format(dateFormat)}`
    : moment(project.date).format(dateFormat);

  const { shifts } = project;
  let prevShiftDate = null;

  return (
    <div ref={ref}>
      <Title>
        <ProjectName onClick={handleProjectNameClick}>{project.name || t('eventName')}</ProjectName>
        <CloseIconComponent icon={CloseIcon} onClick={() => onClose()} />
      </Title>
      <Details>
        <li>
          <Label>{t('dates')}</Label>
          <span>{dates}</span>
        </li>
        <li>
          <Label>{t('location')}</Label>
          <Location>
            <TextWithTooltip>{project.locationAddress}</TextWithTooltip>
          </Location>
        </li>
        <li>
          <Label>{t('orderedAt')}</Label>
          <span>{createdAt}</span>
        </li>
      </Details>
      <Grid>
        {shifts.map((shift) => {
          const showLabel = prevShiftDate !== moment(shift.startTime).format(dateFormat);
          prevShiftDate = moment(shift.startTime).format(dateFormat);
          return (
            <React.Fragment key={shift.id}>
              {showLabel && <GridLabel>{moment(shift.startTime).format(dateFormat)}</GridLabel>}
              <ShiftNameCell>
                <TextWithTooltip>{t(shift.positionName)}</TextWithTooltip>
              </ShiftNameCell>
              <GridCell>
                <span>
                  {moment(shift.startTime).format(timeFormat)} {moment(shift.endTime).format(timeFormat)}
                </span>
                <span>
                  (
                  {`${Number(
                    moment
                      .duration(moment(shift.endTime).diff(moment(shift.startTime), 'milliseconds'))
                      .asHours()
                      .toFixed(2)
                  )}${t('h')}`}
                  )
                </span>
              </GridCell>
              <GridCell>{shift.needed}</GridCell>
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};
