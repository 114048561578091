import React from 'react';
import useSuppliers from '@ubeya/shared/hooks/account/useSuppliers';
import { FlexMiddle } from '../../Flex';

const SuppliersByBranchChip = ({ value }) => {
  const { mappedSupplierBranches } = useSuppliers();

  return <FlexMiddle>{value.map((item) => mappedSupplierBranches[item]?.name).join(', ')}</FlexMiddle>;
};

export default SuppliersByBranchChip;
