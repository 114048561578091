import React from 'react';
import { Field } from 'react-final-form';
import PlacesAutocomplete from '../PlacesAutocomplete';

const AddressPlacesField = ({ title, name, validate, ...restProps }) => {
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta }) => (
        <PlacesAutocomplete
          {...input}
          onChange={(value, location) => {
            input.onChange({ address: value, ...location });
          }}
          title={title}
          error={meta.touched && meta.error}
          {...restProps}
        />
      )}
    />
  );
};

export default AddressPlacesField;
