import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Flex, Select as BaseSelect } from '@ubeya/shared-web/components';
import { ReactComponent as ArrowIcon } from '@ubeya/shared-web/assets/arrow.svg';

const Select = styled(BaseSelect)`
  width: 100%;
`;

const AgGridSelectInputEditor = React.forwardRef(({ value, options, api, onValueChange }, ref) => {
  const onChange = useCallback(
    (newValue) => {
      if (!newValue) return;
      onValueChange(newValue);
      api.stopEditing();
    },
    [api, onValueChange]
  );

  // todo change to use directly UniformSelectField
  return (
    <Flex style={{ width: '100%', height: '100%', flex: 1 }}>
      <Select ref={ref} usePortal options={options} value={value} onChange={onChange} icon={ArrowIcon} noBorder />
    </Flex>
  );
});

export default AgGridSelectInputEditor;
