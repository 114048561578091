import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';

const OPTIONS = {
  fully_booked: 'statusFullyBooked',
  fully_confirmed: 'statusFullyConfirmed',
  partially_booked: 'statusPartiallyBooked',
  no_employees_booked: 'statusNoEmployeesBooked',
  supplier_order_pending_approval: 'statusPendingSupplierApproval'
};

const BookingStatusChip = ({ value }) => {
  const { t } = useContext(ConfigContext);

  return <>{value.map((item) => t(OPTIONS[item])).join(', ')}</>;
};

export default BookingStatusChip;
