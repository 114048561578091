import { get } from 'lodash';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import { CellWrapper } from './styles';

const Cell = (
  {
    rowIdx,
    column,
    column: {
      Aggregate,
      Cell: ColumnCell,
      formatter,
      dataKey,
      columnData,
      captureRowClick = true,
      className,
      cellRendererProps,
      cellWrapperProps
    },
    row,
    CellRenderer,
    onRowClick,
    toggleRow: handleToggleRow,
    isExpended,
    gridApi
  },
  ref
) => {
  const toggleRow = useCallback(() => handleToggleRow(row.parentIndex), [handleToggleRow, row.parentIndex]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      onRowClick?.({ rowData: row, index: rowIdx, toggleRow });
    },
    [onRowClick, row, rowIdx, toggleRow]
  );

  const { isGroup } = row;
  const isCaptureRowClick = useMemo(
    () =>
      onRowClick
        ? typeof captureRowClick === 'function'
          ? captureRowClick({ rowData: row })
          : captureRowClick
        : false,
    [captureRowClick, onRowClick, row]
  );

  return (
    <CellWrapper
      ref={ref}
      column={column}
      onClick={isCaptureRowClick ? handleClick : undefined}
      className={className}
      {...cellWrapperProps}>
      <CellRenderer {...cellRendererProps}>
        {isGroup && Aggregate ? (
          <Aggregate
            index={rowIdx}
            rowData={row}
            columnData={typeof columnData === 'function' ? columnData({ rowData: row }) : columnData}
            dataKey={dataKey}
            toggleRow={toggleRow}
            isExpended={isExpended}
            gridApi={gridApi}
          />
        ) : ColumnCell ? (
          <ColumnCell
            index={rowIdx}
            rowData={row}
            columnData={typeof columnData === 'function' ? columnData({ rowData: row }) : columnData}
            dataKey={dataKey}
            toggleRow={toggleRow}
            gridApi={gridApi}
          />
        ) : formatter ? (
          <>{formatter(get(row, dataKey), row)}</>
        ) : (
          <>{get(row, dataKey)}</>
        )}
      </CellRenderer>
    </CellWrapper>
  );
};

export default memo(forwardRef(Cell));
