import React from 'react';
import styled from 'styled-components';
import { FlexCenter } from '../Flex';
import Loader from './Loader';

const LoaderWrapper = styled(FlexCenter)`
  flex: 1;
`;

const LoaderArea = () => (
  <LoaderWrapper>
    <Loader />
  </LoaderWrapper>
);

export default LoaderArea;
