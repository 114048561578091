import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { FlexMiddle } from '../Flex';
import Label from '../Label';
import Slider from '../Slider';

const ToggleWrapper = styled(FlexMiddle)`
  min-height: 44px;
`;

const SliderField = ({ title, name, validate, className, ...restProps }) => (
  <Field
    name={name}
    validate={validate}
    render={({ input }) => (
      <Label title={title} className={className}>
        <ToggleWrapper>
          <Slider {...input} {...restProps} />
        </ToggleWrapper>
      </Label>
    )}
  />
);

export default SliderField;
