import React from 'react';
import styled from 'styled-components';
import Container from '../Container';
import { FlexMiddle, flexSpace } from '../Flex';
import { card } from '../Card';

const Wrapper = styled(Container).attrs(() => ({ className: 'sticky-bar-wrapper' }))`
  ${card};
  ${flexSpace};
  position: sticky;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StickyBar = ({ leftSide, rightSide }) => (
  <Wrapper>
    <FlexMiddle>{leftSide}</FlexMiddle>

    <FlexMiddle>{rightSide}</FlexMiddle>
  </Wrapper>
);

export default StickyBar;
