import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { FlexMiddle, TimeAgoRenderer } from '..';

const Container = styled(FlexMiddle)`
  padding: 5px 10px;
  align-items: flex-start;
`;

const Body = styled.div`
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
`;

const Space = styled.div`
  margin-top: 3%;
`;

const AuditLogContainer = ({ values = {}, text, createdAt, Icon }) => {
  return (
    <Container>
      <Icon />
      <Body>
        <Trans i18nKey={text} values={values} components={{ bold: <strong /> }} />
        <Space />
        <TimeAgoRenderer rowData={{ createdAt }} dataKey="createdAt" />
      </Body>
    </Container>
  );
};

export default AuditLogContainer;
