import React, { useContext } from 'react';
import styled from 'styled-components';
import useUser from '@ubeya/shared/hooks/auth/useUser';
import { DropDown, Avatar as BaseAvatar } from '@ubeya/shared-web/components';
import { ReactComponent as LogoutIcon } from '@ubeya/shared-web/assets/logout.svg';
import { media } from '@ubeya/shared-web/style';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const BigAvatar = styled(BaseAvatar)`
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  ${media.tablet`
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  `}
`;

const Profile = ({ isMobileOnly }) => {
  const { t } = useContext(ConfigContext);
  const {
    data: { firstName, lastName, image },
    logout
  } = useUser();

  return (
    <DropDown
      showArrow={false}
      openFromRight={!!isMobileOnly}
      menuMinWidth={260}
      menuOffset={isMobileOnly ? { top: 10 } : { top: -30, left: 40 }}
      renderValue={<BigAvatar image={image} size="small" firstName={firstName} lastName={lastName} />}>
      <DropDown.Item>{t('helloName', { name: firstName })}</DropDown.Item>

      <DropDown.Item icon={LogoutIcon} onClick={() => logout('admin')}>
        {t('logout')}
      </DropDown.Item>
    </DropDown>
  );
};

export default Profile;
