import React from 'react';
import useSuppliers from '@ubeya/shared/hooks/account/useSuppliers';
import { FlexMiddle } from '../../Flex';

const SuppliersByAccountChip = ({ value }) => {
  const { mappedSuppliers } = useSuppliers();

  return <FlexMiddle>{value.map((item) => mappedSuppliers[item]?.name).join(', ')}</FlexMiddle>;
};

export default SuppliersByAccountChip;
