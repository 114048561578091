import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { FlexMiddle } from '../Flex';
import CheckBox from '../CheckBox';

const Wrapper = styled(FlexMiddle)`
  padding: 9px 0;
`;

const CheckBoxField = ({ name, title, children, disabled, validate, className }) => (
  <Field
    name={name}
    validate={validate}
    render={({ input, meta }) => (
      <Wrapper className={className}>
        <CheckBox {...input} label={title} disabled={disabled} error={meta.touched && meta.error}>
          {children}
        </CheckBox>
      </Wrapper>
    )}
  />
);

export default CheckBoxField;
