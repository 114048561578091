import { useEffect } from 'react';

const useOnBrowserSearch = (ref, handler, enabled = true, portalId) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const listener = (event) => {
      if (
        !ref ||
        !ref.current ||
        ref.current.contains(event.target) ||
        (portalId && document.getElementById(portalId).contains(event.target))
      ) {
        return;
      }

      if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
        event.preventDefault();
        handler?.(event);
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      if (listener) {
        document.removeEventListener('keydown', listener);
      }
    };
  }, [ref, handler, enabled, portalId]);
};

export default useOnBrowserSearch;
