import React, { useContext } from 'react';
import styled from 'styled-components';
import { ellipsis, semiExtraSmall } from '../Typography';
import { TextWithTooltip } from '../Tooltip';
import ConfigContext from '../../contexts/ConfigContext';

const Wrapper = styled(TextWithTooltip)`
  max-width: 280px;
  min-width: 60px;
  ${semiExtraSmall};
  border: none;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.gray400};
  background: transparent;
  outline: none;
  padding: 0;
  ${ellipsis};
  text-overflow: clip;
  height: 20px;
  -webkit-user-select: text;
  user-select: text;

  &[placeholder]:empty::before {
    content: attr(placeholder);
    color: #555;
  }

  &[placeholder]:empty:focus::before {
    content: '';
  }
`;

const EditableNotes = ({ defaultValue, placeholder = 'addNotes', onBlur, onChange, className, notesRef }) => {
  const { t } = useContext(ConfigContext);

  return (
    <Wrapper
      wrapperRef={notesRef}
      placeholder={t(placeholder)}
      className={className}
      contentEditable
      enabled={!!defaultValue}
      onBlur={(e) => {
        onBlur?.(e);
        onChange(e.target.textContent);
      }}
      onKeyPress={(e) => {
        if (e.which === 13) {
          e.preventDefault();
          onChange(e.target.innerText);
        }
      }}
      suppressContentEditableWarning>
      {defaultValue}
    </Wrapper>
  );
};

export default EditableNotes;
