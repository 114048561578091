import React, { useContext, useState } from 'react';
import DropDown from '../../DropDown';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';
import Slider from '../../Slider';

const min = 0;
const max = 5;

const RatingFilter = ({ defaultValue, handleClose, onApply, search, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);

  const value = Array.isArray(defaultValue) ? defaultValue : [defaultValue || min, defaultValue || max];
  const [range, setRange] = useState(value);

  if (search && !t('rating').toLowerCase().includes(search.toLowerCase())) {
    return null;
  }

  return (
    <FilterLayout
      name={t('rating')}
      handleClose={handleClose}
      hideLayout={hideLayout}
      onApply={() => (range ? onApply(range) : handleClose())}>
      <DropDown.Item>
        <Slider value={range} onChange={setRange} min={min} max={max} showValue />
      </DropDown.Item>
    </FilterLayout>
  );
};

export default RatingFilter;
