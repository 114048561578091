import React from 'react';
import styled, { keyframes } from 'styled-components';
import useTimeout from '@ubeya/shared/hooks/useTimeout';
import useShowToaster from '@ubeya/shared/hooks/useShowToaster';
import { Card, flexMiddle } from '@ubeya/shared-web/components';
import { ReactComponent as CloseIcon } from '@ubeya/shared-web/assets/Toast/close.svg';
import { ReactComponent as SuccessIcon } from '@ubeya/shared-web/assets/Toast/success.svg';
import { ReactComponent as FailureIcon } from '@ubeya/shared-web/assets/Toast/failure.svg';
// import { ReactComponent as InfoIcon } from '@ubeya/shared-web/assets/Toast/info.svg';

const TYPES = {
  success: { Icon: SuccessIcon, color: 'success' },
  error: { Icon: FailureIcon, color: 'error' }
  // info: { Icon: InfoIcon, color: 'info' }  // Commented due to no implementation created yet
};

const animation = keyframes`
  0%{transform: translateX(-630px);}
	6%{transform: translateX(0px);}
	94%{transform: translateX(0px);}
	100%{transform: translateX(-630px);}
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10;
`;

const Container = styled(Card)`
  ${flexMiddle};
  gap: 24px;
  justify-content: space-between;
  background: ${({ $type, theme }) => theme.colors.toast[TYPES[$type].color]};
  color: ${({ theme }) => theme.colors.surface};
  padding: 8px 16px;
  border-radius: 4px;
  animation: ${animation} 4s ease-out forwards;
  border: none;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  max-height: 64px;
  position: relative;
`;

const Message = styled.div`
  ${flexMiddle};
  gap: 8px;
  padding-block: 10px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  max-width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Toaster = () => {
  const { data, removeFirst, removeByIndex } = useShowToaster();

  useTimeout(removeFirst, 4000, [data]);

  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      {data?.map((message, index) => {
        const Icon = TYPES[message.type].Icon;
        return (
          <Container key={index} $type={message.type}>
            <Message>
              <Icon />
              <Text>{message.text}</Text>
            </Message>

            <CloseIcon onClick={() => removeByIndex(index)} />
          </Container>
        );
      })}
    </Wrapper>
  );
};

export default Toaster;
