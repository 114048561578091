import { useContext, useMemo } from 'react';
import getFormattingMap from '@ubeya/shared/utils/formatting';
import ConfigContext from '../contexts/ConfigContext';

const useFormattingMap = () => {
  const { t, dateFormat, timeFormat, currency, shortDateFormat, billingCurrency } = useContext(ConfigContext);

  const formattingMap = useMemo(
    () =>
      getFormattingMap({
        dateFormat,
        timeFormat,
        currency,
        billingCurrency,
        t,
        shortDateFormat
      }),
    [dateFormat, timeFormat, billingCurrency, currency, t, shortDateFormat]
  );

  return formattingMap;
};

export default useFormattingMap;
