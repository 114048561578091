import React, { useMemo, useCallback, useContext, useState } from 'react';
import moment from 'moment';
import { convertTimeStringToMoment } from '@ubeya/shared/utils/time';
import { API_TIME_FORMAT, API_DATE_TIME_FORMAT, API_DATE_FORMAT } from '@ubeya/shared/constants';
import ConfigContext from '../../contexts/ConfigContext';
import Select from '../Select';

const CREATE_OPTIONS = (interval) =>
  [...Array(24 * 4)].map((_, index) =>
    moment()
      .startOf('day')
      .add(index * interval, 'minutes')
  );

const OPTIONS = CREATE_OPTIONS(15);

const convertToMoment = (value, timeOnly) => {
  if (!value) {
    return;
  }

  if (timeOnly) {
    return convertTimeStringToMoment(value);
  }

  return moment(value, API_DATE_TIME_FORMAT);
};

// value should be YYYY-MM-DD HH:mm:ss or HH:mm:ss
const TimePicker = React.forwardRef(
  (
    {
      title,
      required,
      className,
      value,
      tooltipBody,
      onChange,
      startTime,
      defaultValue,
      timeOnly,
      usePortal,
      error = null,
      small,
      isDisabled,
      placeholder = '__:__',
      clearable,
      onOutsideClickSave,
      onBlur,
      interval,
      defaultOptions,
      ...restSelectProps
    },
    ref
  ) => {
    const { t, timeFormat } = useContext(ConfigContext);
    const [controlledSearch, setControlledSearch] = useState('');

    const timeMoment = useMemo(() => convertToMoment(value, timeOnly) || moment(), [timeOnly, value]);
    const timeString = useMemo(() => (value ? timeMoment.format(timeFormat) : ''), [timeFormat, timeMoment, value]);

    const defaultString = useMemo(() => defaultValue && convertToMoment(defaultValue, timeOnly).format(timeFormat), [
      defaultValue,
      timeFormat,
      timeOnly
    ]);

    const startMoment = useMemo(() => convertToMoment(startTime, timeOnly), [startTime, timeOnly]);

    const options = useMemo(() => defaultOptions || (!interval ? OPTIONS : CREATE_OPTIONS(interval)), [
      defaultOptions,
      interval
    ]);

    const multipleOptionsBySearch = useMemo(() => (controlledSearch ? OPTIONS : [...OPTIONS, ...OPTIONS]), [
      controlledSearch
    ]);

    const multipleOptions = useMemo(() => (!interval ? multipleOptionsBySearch : [...options, ...options]), [
      multipleOptionsBySearch,
      interval,
      options
    ]);

    const handleSelect = useCallback(
      (newTime) => {
        if (!newTime) {
          onChange(null, null);
        } else {
          const date = timeMoment.format(API_DATE_FORMAT);
          const newDate = moment(`${date} ${newTime}`, `${API_DATE_FORMAT} ${timeFormat}`);

          onChange(
            newDate.isValid()
              ? timeOnly
                ? newDate.format(API_TIME_FORMAT)
                : newDate.format(API_DATE_TIME_FORMAT)
              : null,
            newTime
          );
        }
      },
      [onChange, timeMoment, timeFormat, timeOnly]
    );

    const formatOption = useCallback(
      (optionValue) => {
        if (!optionValue) {
          return { label: '__:__', value: '' };
        }

        const label = optionValue.format(timeFormat);

        if (!startMoment) {
          return { label, value: label };
        }
        let newValue;

        if (timeOnly) {
          newValue = convertTimeStringToMoment(label);
        } else {
          const date = startMoment.format(API_DATE_FORMAT);
          newValue = moment(`${date} ${optionValue.format(timeFormat)}`, `${API_DATE_FORMAT} ${timeFormat}`);
        }

        let totalMinutes = newValue.diff(startMoment, 'minutes');
        totalMinutes = totalMinutes < 0 ? totalMinutes + 60 * 24 : totalMinutes;

        const hours = parseInt(totalMinutes / 60, 10);
        const minutes = totalMinutes % 60;

        return {
          label: `${label} (${hours} ${t('hours')}${minutes > 0 ? ` ${minutes} ${t('minutes')}` : ''})`,
          value: label
        };
      },
      [startMoment, t, timeFormat, timeOnly]
    );

    const filterFunc = useCallback(
      (val, search) => {
        const label = val?.format?.(timeFormat) || '';
        return label?.replace(/^0/, '')?.startsWith?.(search.replace(/^0/, ''));
      },
      [timeFormat]
    );

    const handleOnBlur = (e) => {
      if (onOutsideClickSave && e.target.value) {
        return handleSelect(e.target.value);
      }
      return onBlur;
    };

    return (
      <Select
        ref={ref}
        isRaw
        setControlledSearch={setControlledSearch}
        title={title}
        tooltipBody={tooltipBody}
        required={required}
        className={className}
        placeholder={placeholder}
        value={timeString}
        error={error}
        onChange={handleSelect}
        formatOption={formatOption}
        onBlur={handleOnBlur}
        filterFunc={filterFunc}
        defaultValue={defaultString}
        options={startTime ? multipleOptions : options}
        usePortal={usePortal}
        small={small}
        isDisabled={isDisabled}
        menuClassName={startMoment ? 'time-with-duration' : 'time'}
        clearable={clearable}
        creatable
        {...restSelectProps}
      />
    );
  }
);

export default TimePicker;
