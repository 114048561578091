import React from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import ConfirmationModal from '../ConfirmationModal';
import Button from '../Button';

const LeaveButton = styled(Button)`
  margin-right: 10px;
`;

export class RouteLeavingGuard extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false
  };

  showModal = (location) => this.setState({ modalVisible: true, lastLocation: location });

  closeModal = (callback, executeDiscard = false) => {
    callback && typeof callback === 'function' ? callback?.() : executeDiscard ? this.discard() : undefined;
    return this.setState({ modalVisible: false });
  };

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    if (!confirmedNavigation) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  discard = () => {
    const {
      history: { push: navigate }
    } = this.props;

    const { lastLocation } = this.state;
    if (lastLocation) {
      this.setState({ confirmedNavigation: true }, () => navigate(lastLocation.pathname));
    }
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const {
        history: { push: navigate },
        onSubmit
      } = this.props;
      const { lastLocation } = this.state;

      onSubmit?.();
      if (lastLocation) {
        this.setState({ confirmedNavigation: true }, () => navigate(lastLocation.pathname));
      }
    }, true);

  renderButtons = () => {
    const { canLeaveWithoutSaving = false } = this.props;
    return canLeaveWithoutSaving ? (
      <LeaveButton secondary onClick={this.discard} paddingVertical={0}>
        <Trans>leaveWithoutSaving</Trans>
      </LeaveButton>
    ) : undefined;
  };

  render() {
    const { when, title = 'changesNotSaved', body = 'areYouSureLeave', canLeaveWithoutSaving = false } = this.props;
    const { modalVisible } = this.state;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        {modalVisible && (
          <ConfirmationModal
            onClose={this.closeModal}
            onConfirm={this.handleConfirmNavigationClick}
            title={title}
            body={body}
            confirmText="save"
            usePortal
            showCancelButton={!canLeaveWithoutSaving}
            renderButtons={this.renderButtons}
          />
        )}
      </>
    );
  }
}
export default withRouter(RouteLeavingGuard);
