import styled, { css } from 'styled-components';

export const card = css`
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid #eee;
`;

export const Card = styled.div`
  ${card};
`;
