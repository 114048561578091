import moment from 'moment';

const LAST_ACTIVITY_CACHE_KEY = 'settings:last-activity-time';

const toFixedNumber = (number) => parseInt(parseFloat(number.toString()).toFixed(0), 0);

const convertToMs = (timeout, period) => {
  return moment.duration(timeout, period).asMilliseconds();
};

const setLastActivityTime = () => {
  localStorage.setItem(LAST_ACTIVITY_CACHE_KEY, Date.now());
};

const getLastActivityTime = () => {
  const value = localStorage.getItem(LAST_ACTIVITY_CACHE_KEY);
  return value ? Number(value) : 0;
};

const removeLastActivityTime = () => {
  localStorage.removeItem(LAST_ACTIVITY_CACHE_KEY);
};

export { toFixedNumber, convertToMs, setLastActivityTime, getLastActivityTime, removeLastActivityTime };
