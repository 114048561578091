import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
`;

const Masonry = ({ breakPoints = [0, 800, 1200], children }) => {
  const [current, setCurrent] = useState({ columns: 1 });
  const ref = useRef(null);

  const getColumns = useCallback((w) => breakPoints.reduceRight((p, c, i) => (c < w ? p : i), breakPoints.length), [
    breakPoints
  ]);

  const onResize = useCallback(() => {
    const columns = getColumns(ref.current.offsetWidth);
    if (columns !== current.columns) {
      setCurrent({ columns });
    }
  }, [current.columns, getColumns]);

  const mapChildren = useCallback(() => {
    const col = [];
    const numC = current.columns;
    for (let i = 0; i < numC; i++) {
      col.push([]);
    }

    return children.reduce((p, c, i) => {
      p[i % numC].push(c);
      return p;
    }, col);
  }, [children, current.columns]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return (
    <Container ref={ref}>
      {mapChildren().map((col, ci) => (
        <Column key={ci}>
          {col.map((child, i) => {
            return <div key={i}>{child}</div>;
          })}
        </Column>
      ))}
    </Container>
  );
};

export default Masonry;
