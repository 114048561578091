import { css } from 'styled-components';

const editable = css`
  &:hover .label,
  & .editable {
    display: none;
  }

  & .label,
  &:hover .editable {
    display: flex;
  }

  ${'' /* TIMESHEET */}
  &:hover .actual {
    .select-label[data-is-empty='true']:before {
      content: '__:__';
    }
    .select-label[data-is-empty='false'] {
      text-decoration: underline;
    }
  }
`;

export default editable;
