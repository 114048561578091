import React from 'react';
import useCRM from '@ubeya/shared/hooks/account/useCRM';
import { FlexMiddle } from '../../Flex';

const ClientsChip = ({ value }) => {
  const { mappedClientBranches } = useCRM();

  return <FlexMiddle>{value.map((item) => mappedClientBranches[item]?.name).join(', ')}</FlexMiddle>;
};

export default ClientsChip;
