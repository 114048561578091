import React, { useState, useCallback, useMemo, useContext } from 'react';
import useAdmins from '@ubeya/shared/hooks/account/useAdmins';
import { SORT_BY_STRING } from '@ubeya/shared/utils/sorting';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const AdminFilter = ({ defaultValue, handleClose, onApply, search, onChange, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const [values, setValues] = useState(defaultValue || []);

  const { admins } = useAdmins();

  const adminsOptions = useMemo(() => {
    const sorter = SORT_BY_STRING('label');
    return admins
      .map(({ id, firstName, lastName }) => ({ value: id, label: [firstName, lastName].join(' ') }))
      .sort(sorter);
  }, [admins]);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search ? adminsOptions.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase())) : adminsOptions,
    [adminsOptions, search]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout name={t('admins')} handleClose={handleClose} onApply={() => onApply(values)} hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            name={`admin-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default AdminFilter;
