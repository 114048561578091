import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { ImageField as BaseImageField } from '../FormFields';

const ImageField = styled(BaseImageField)`
  grid-column: 1/-1;
  margin: 0 auto 10px !important;
`;

const LogoUpload = ({ className }) => (
  <ImageField name="logo" className={className}>
    <>
      <Trans>logo</Trans>
      <br />
      <br />
      <Trans>addPhoto</Trans>
    </>
  </ImageField>
);

export default LogoUpload;
