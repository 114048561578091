import React, { useCallback, useContext } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { refreshNeededModal } from '@ubeya/shared/atoms/shared';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { ReactComponent as BaseRefreshIcon } from '@ubeya/shared-web/assets/restore-outline.svg';
import Banner from '../Banner';

const RefreshIcon = styled(BaseRefreshIcon)`
  fill: ${({ theme }) => theme.colors.surface};
`;

const RefreshBanner = () => {
  const { t } = useContext(ConfigContext);
  const [refreshNeeded, setRefreshNeeded] = useRecoilState(refreshNeededModal);

  const refreshPage = useCallback(() => {
    setRefreshNeeded(false);
    window.location.reload();
  }, [setRefreshNeeded]);

  if (!refreshNeeded) {
    return <></>;
  }

  return (
    <Banner
      Icon={RefreshIcon}
      headerText={t('updateAvailableHeader')}
      bodyText={t('updateAvailableBody')}
      buttonText={t('updateAvailableButton')}
      onClick={refreshPage}
      showButton
    />
  );
};

export default RefreshBanner;
