import { useCallback, useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { toaster } from '../atoms/shared';

const useToaster = () => {
  const { t } = useContext(ConfigContext);
  const setMessages = useSetRecoilState(toaster);

  const addMessage = useCallback(
    (message) => {
      let text = message?.text;

      if (message?.type === 'error' && !navigator.onLine) {
        text = t('oopsYoureOffline');
      }
      const formattedMessage = { ...message, text };

      setMessages((messages) => [...messages, formattedMessage]);
    },
    [setMessages, t]
  );

  const addError = useCallback((message) => addMessage({ text: message, type: 'error' }), [addMessage]);

  const addSuccess = useCallback((message) => addMessage({ text: message, type: 'success' }), [addMessage]);

  // const addInfo = useCallback((message) => addMessage({ text: message, type: 'info' }), [addMessage]); // Commented due to no implementation created yet

  const addResponseError = useCallback(
    (error) => {
      return addMessage({
        text:
          // single error
          t(error?.response?.data?.message) ||
          //date validation error (yup)
          t(error?.response?.data?.errors?.body?.[0]?.message) ||
          // array of errors
          t(error?.response?.data?.errors?.[0]?.message),
        type: 'error'
      });
    },
    [t, addMessage]
  );

  return { addError, addSuccess, addResponseError };
};

export default useToaster;
