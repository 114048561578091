import { useCallback, useEffect, useMemo, useState } from 'react';

const flattenData = (data, extendedRows = {}) =>
  data.reduce(
    (all, item, parentIndex) => [
      ...all,
      { ...item, parentIndex, isGroup: true },
      ...(extendedRows[parentIndex]
        ? (item.subRows || []).map((subRowItem) => ({ ...subRowItem, parentIndex, isGroup: false }))
        : [])
    ],
    []
  );

const useDrawer = ({ rows, defaultExtendsRows }) => {
  const [extendedRows, setExtendedRows] = useState(() => defaultExtendsRows || {});
  const [firstVisibleRowIndex, setFirstVisibleRowIndex] = useState();

  useEffect(() => {
    setExtendedRows(defaultExtendsRows || {});
  }, [defaultExtendsRows]);

  const flatRows = useMemo(() => flattenData(rows, extendedRows), [rows, extendedRows]);

  const handleToggleRow = useCallback((index) => setExtendedRows((prev) => ({ ...prev, [index]: !prev[index] })), []);

  return { flatRows, extendedRows, toggleRow: handleToggleRow, firstVisibleRowIndex, setFirstVisibleRowIndex };
};

export default useDrawer;
