export const SHARE_TO_SOCIAL = 'Share to social';
export const CREATE_ORDER = 'Add order';
export const CREATE_ACCOUNT_PROFILE = 'Create account profile';
export const SYSTEM_ERROR = 'System error';
export const CREATE_SUPPLIER_FREEMIUM = 'Create supplier freemium';
export const SUPPLIER_FREEMIUM_FEATURE_GATE_ERROR = 'Supplier freemium feature gate error';
export const SUPPLIER_FREEMIUM_EVENT_WITH_CLIENT = 'Supplier freemium event with client';
export const SUPPLIER_FREEMIUM_PREMIUM_FEATURE_ACCESS = 'Supplier freemium premium feature access';
export const UNBOOK_EMPLOYEE_ERROR = 'Unbook employee error';
export const CLIENT_LOCATION_FIELD_DEBUG = 'Location field debug';
