import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';
import { BOOKING_ISSUES_MAPPING } from '../../../constants';

const ProjectBookingIssuesStatusChip = ({ value }) => {
  const { t } = useContext(ConfigContext);

  return <>{value.map((item) => t(BOOKING_ISSUES_MAPPING[item])).join(', ')}</>;
};

export default ProjectBookingIssuesStatusChip;
