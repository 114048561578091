import styled from 'styled-components';
import { media } from '../../style';

const SIZES = {
  xsmall: { default: 8, tablet: 10 },
  small: { default: 6, tablet: 20 },
  large: { default: 4, tablet: 2 }
};

const Container = styled.div`
  padding: 0 ${({ size = 'large' }) => SIZES[size].default}%;
  ${media.tablet`padding: 0 ${({ size = 'large' }) => SIZES[size].tablet}%;`}

  margin: 0 auto;
  width: 100%;
`;

export default Container;
