import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { SORT_BY_STRING } from '@ubeya/shared/utils/sorting';
import useBranches from '@ubeya/shared/hooks/account/useBranches';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const EmptyOptions = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
`;

const BranchesFilter = ({ defaultValue, handleClose, onApply, search, onChange, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const { branchesOptions } = useBranches();
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      (
        (search
          ? branchesOptions.filter(
              ({ label }) =>
                t('branches').toLowerCase().includes(search.toLowerCase()) ||
                label.toLowerCase().includes(search.toLowerCase())
            )
          : branchesOptions) || []
      ).sort(SORT_BY_STRING('label')),
    [branchesOptions, search, t]
  );

  return (
    <FilterLayout
      name={t('branches')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions && filteredOptions?.length > 0 ? (
        filteredOptions.map(({ value, label }) => (
          <DropDown.Item key={value}>
            <CheckBox
              name={`branch-${value}`}
              value={values.includes(value)}
              label={label}
              onChange={() => handleChange(value)}
            />
          </DropDown.Item>
        ))
      ) : (
        <EmptyOptions>{t('noOptions')}</EmptyOptions>
      )}
    </FilterLayout>
  );
};

export default BranchesFilter;
