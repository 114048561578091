import styled, { css } from 'styled-components';
import { ReactComponent as BaseBookIcon } from '../../assets/book-action.svg';

const BookIcon = styled(BaseBookIcon)`
  cursor: 'pointer';

  > g {
    ${({ $isActive }) =>
      $isActive
        ? css`
            > path:first-child {
              fill: ${({ $color, theme }) => $color || theme.colors.secondary};
              stroke: ${({ $color, theme }) => $color || theme.colors.secondary};
            }
            > path:last-child {
              stroke: ${({ theme }) => theme.colors.surface};
            }
          `
        : css`
            > path:first-child {
              stroke: ${({ $color, theme }) => $color || theme.colors.secondary};
            }
            > path:last-child {
              stroke: ${({ $color, $showArrow = true, theme }) =>
                $showArrow ? $color || theme.colors.secondary : 'transparent'};
            }
          `}
  }
`;

export default BookIcon;
