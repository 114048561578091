import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import * as api from '@ubeya/shared/services/api';
import { ALLOWED_PHOTO_TYPES, ALLOWED_VIDEOS_TYPES, ALLOWED_DOCUMENT_EXTENSIONS } from '@ubeya/shared/constants';
import { FileUpload as BaseFileUpload, Button, FlexSpace, FlexMiddle } from '@ubeya/shared-web/components';
import { responsiveShow, media } from '@ubeya/shared-web/style';
import { ReactComponent as PhotoIcon } from '@ubeya/shared-web/assets/photo.svg';
import { ReactComponent as VideoIcon } from '@ubeya/shared-web/assets/video.svg';
import { ReactComponent as FileIcon } from '@ubeya/shared-web/assets/file.svg';

const SecondaryButton = styled(Button).attrs(() => ({ type: 'button', secondary: true, narrow: true }))`
  border-color: ${({ theme }) => theme.colors.gray700};
  color: #7c7c7c;
  margin-right: 10px;
  ${responsiveShow.mobile}

  >svg {
    margin-right: 8px;
    margin-left: -8px;
  }
`;

const Actions = styled(FlexSpace).attrs(() => ({ className: 'actions' }))`
  padding: 10px;
  ${media.mobile`padding: 20px`}
`;

const AttachmentsActions = ({ handleAddItems }) => {
  return (
    <Actions>
      <FlexMiddle>
        <BaseFileUpload
          apiFunc={api.uploadImage}
          onUploaded={handleAddItems}
          accept={ALLOWED_PHOTO_TYPES}
          loaderSize="small">
          <SecondaryButton>
            <PhotoIcon />
            <Trans>photo</Trans>
          </SecondaryButton>
        </BaseFileUpload>

        <BaseFileUpload
          apiFunc={api.uploadImage}
          onUploaded={handleAddItems}
          accept={ALLOWED_VIDEOS_TYPES}
          loaderSize="small">
          <SecondaryButton>
            <VideoIcon />
            <Trans>video</Trans>
          </SecondaryButton>
        </BaseFileUpload>

        <BaseFileUpload
          apiFunc={api.uploadImage}
          onUploaded={handleAddItems}
          accept={ALLOWED_DOCUMENT_EXTENSIONS}
          loaderSize="small">
          <SecondaryButton>
            <FileIcon />
            <Trans>file</Trans>
          </SecondaryButton>
        </BaseFileUpload>
      </FlexMiddle>
    </Actions>
  );
};

export default AttachmentsActions;
