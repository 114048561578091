import React, { useMemo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { ALLOWED_FILE_EXTENSIONS } from '@ubeya/shared/constants';
import * as api from '@ubeya/shared/services/api';
import { ImagesCarousel, Flex, FileUpload as BaseFileUpload, flexCenter, PdfModal } from '@ubeya/shared-web/components';
import { ReactComponent as Plus } from '@ubeya/shared-web/assets/plus.svg';
import { ReactComponent as AttachedFileIcon } from '@ubeya/shared-web/assets/attached-file.svg';
import { ReactComponent as BaseDeleteIcon } from '@ubeya/shared-web/assets/file-actions-delete.svg';
import { ReactComponent as BaseViewIcon } from '@ubeya/shared-web/assets/file-actions-view.svg';
import { ReactComponent as BaseDownloadIcon } from '@ubeya/shared-web/assets/file-actions-download.svg';
import { FlexSpace } from '../../Flex';
import { TextWithTooltip } from '../../Tooltip';

const Container = styled(Flex)`
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const attachment = css`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-top: 10px;
  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }
`;

const FileUpload = styled(BaseFileUpload)`
  ${attachment};
  ${flexCenter};
  color: ${({ theme }) => theme.colors.gray400};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23DCDCDCFF' stroke-width='4' stroke-dasharray='9%2c 10' stroke-dashoffset='40' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
`;

const FileWrapper = styled(FlexSpace)`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  width: 100% !important;
  height: 46px;
  outline: 0;
  border-radius: 3px;
  background-color: rgba(0, 186, 141, 0.1);

  &:hover {
    background-color: rgba(0, 186, 141, 0.25);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UploadText = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.gray300};
`;

const iconHover = css`
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
`;

const DeleteIcon = styled(BaseDeleteIcon)`
  &:hover {
    ${iconHover};
  }
`;

const ViewIcon = styled(BaseViewIcon)`
  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    cursor: pointer;
    g {
      path {
        fill: white;
        fill-opacity: 0.7;
      }
    }
  }
`;

const DownloadIcon = styled(BaseDownloadIcon)`
  &:hover {
    ${iconHover};
  }
`;

const Space = styled.div`
  height: 20px;
  width: 8px;
`;

const Doc = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 10px;
  padding: 2px;
`;

const SubTitle = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.gray400};
`;

const Actions = ({ index, id, showView, src, handleViewItem, handleDeleteItems, showDelete = true }) => {
  const onDownload = useCallback(() => window.open(src), [src]);
  const onDelete = useCallback(() => handleDeleteItems(id), [handleDeleteItems, id]);
  const onView = useCallback(() => handleViewItem(index), [handleViewItem, index]);

  return (
    <>
      {showDelete && <DeleteIcon onClick={onDelete} />}
      {showView ? <ViewIcon onClick={onView} /> : <Space />}
      <DownloadIcon onClick={onDownload} />
    </>
  );
};

const AttachmentsList = ({ data, handleDeleteItems, handleAddItems, singleFile = false, showDelete = true }) => {
  const [isOpenIndex, setIsOpenIndex] = useState();
  const [openPdf, setOpenPdf] = useState();

  const mediaAttachments = useMemo(
    () => data?.filter(({ type }) => type === 'image').map(({ src }) => ({ source: src })),
    [data]
  );

  return (
    <>
      <Container>
        {(data || []).map(({ src, type, title, extension, id }, index) => {
          const handleViewItem = type === 'image' ? setIsOpenIndex : () => setOpenPdf(src);

          return (
            <FileWrapper key={index}>
              <Wrapper>
                <AttachedFileIcon />
                <UploadText>
                  <Doc>
                    <Title>
                      <TextWithTooltip>{title?.slice(0, 30)}</TextWithTooltip>
                    </Title>
                    {extension && <SubTitle>{extension.toUpperCase()}</SubTitle>}
                  </Doc>
                </UploadText>
              </Wrapper>
              <ActionsWrapper>
                <Actions
                  showView={type === 'image' || extension === 'pdf'}
                  index={index}
                  id={id}
                  src={src}
                  handleDeleteItems={handleDeleteItems}
                  handleViewItem={handleViewItem}
                  showDelete={showDelete}
                />
              </ActionsWrapper>
            </FileWrapper>
          );
        })}

        {!singleFile && (
          <FileUpload apiFunc={api.uploadImage} onUploaded={handleAddItems} accept={ALLOWED_FILE_EXTENSIONS}>
            <Plus />
          </FileUpload>
        )}
      </Container>

      {!!openPdf && <PdfModal url={openPdf} onClose={() => setOpenPdf(undefined)} />}

      <ImagesCarousel views={mediaAttachments} currentIndex={isOpenIndex} onClose={() => setIsOpenIndex()} />
    </>
  );
};

export default AttachmentsList;
