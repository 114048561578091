import React from 'react';
import { Field } from 'react-final-form';
import { DatePickerInput } from '../DatePicker';

const DateField = ({
  title,
  name,
  validate,
  onChange,
  useInputOnChange = true,
  showInitialErrorWithoutTouch = false,
  ...restProps
}) => (
  <Field
    name={name}
    validate={validate}
    render={({ input, meta }) => (
      <DatePickerInput
        title={title}
        {...restProps}
        {...input}
        onChange={(value) => {
          onChange?.(value);
          useInputOnChange && input.onChange(value);
        }}
        error={(meta.touched || showInitialErrorWithoutTouch) && meta.error}
      />
    )}
  />
);

export default DateField;
