import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';
import { Loader as BaseLoader } from '../Loader';
import { TextArea as BaseTextArea } from '../Input';

const TextArea = styled(BaseTextArea)``;

const Wrapper = styled(FlexMiddle)`
  position: relative;

  textarea {
    padding: 3px;
    background-color: ${({ color }) => color || 'auto'};
    border-color: ${({ color }) => color || 'auto'};
  }

  &:hover ${TextArea} > textarea {
    border-color: ${({ theme }) => theme.colors.gray700};
    background-color: ${({ theme }) => theme.colors.surface};
  }
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  left: 0;
  right: 0;
`;

const EditableTextAreaInput = ({
  isLoading,
  value,
  onChange,
  onSubmitChange,
  prefix,
  color,
  allowOnKeyPress = true,
  placeholder = ''
}) => (
  <Wrapper color={color}>
    {prefix && <span>{prefix}</span>}

    <TextArea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyPress={(e) => (allowOnKeyPress ? e.which === 13 && onSubmitChange() : undefined)}
      onBlur={onSubmitChange}
      cols={50}
    />

    {isLoading && <Loader size="xsmall" />}
  </Wrapper>
);

export default EditableTextAreaInput;
