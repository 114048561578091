import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { CAPTAIN_ICONS_BY_TYPE } from '@ubeya/shared/constants';
import useFormattingMap from '../../hooks/useFormattingMap';
import { FlexMiddle, FlexColumn, FlexCenter } from '../Flex';
import { body, smallBody, small } from '../Typography';
import { ReactComponent as Star } from '../../assets/star.svg';
import privileges from '../../privileges';
import { TextWithTooltip, Tooltip } from '../Tooltip';
import ConfigContext from '../../contexts/ConfigContext';
import CircleIcon from '../CircleIcon';
import BaseAvatar from './Avatar';

const SIZES = {
  xs: small,
  small: smallBody,
  medium: smallBody,
  big: body,
  large: body
};

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'user' }))`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  &:hover .name {
    ${({ $withUnderline, onClick }) => onClick && $withUnderline && 'text-decoration: underline;'}
  }
`;

const Avatar = styled(BaseAvatar)`
  margin-right: ${({ size }) => (size === 'xs' ? 6 : 15)}px;
`;

const Content = styled(FlexColumn)`
  overflow: hidden;
`;

const Name = styled(TextWithTooltip).attrs(() => ({ className: 'name' }))`
  ${smallBody};
  ${({ size }) => SIZES[size]};
`;

const RatingRow = styled(FlexMiddle)`
  ${small};
  > svg {
    margin-right: 5px;
    > path {
      fill: ${({ theme }) => theme.colors.success};
    }
  }
`;

const NameRow = styled(FlexCenter)``;

const User = ({
  firstName,
  lastName,
  image,
  isPending,
  rating,
  isAdmin,
  adminPrivileges = [],
  captainType = null,
  showCaptain = false,
  showRating = false,
  enableTooltip = true,
  size,
  onClick,
  className,
  renderBottom,
  renderNext,
  withUnderline,
  warnings,
  showOnAvatarData
}) => {
  const formattingMap = useFormattingMap();
  const { t } = useContext(ConfigContext);

  const warningSlugs = useMemo(() => [...new Set((warnings || []).map(({ slug }) => slug))], [warnings]);

  return (
    <Wrapper onClick={onClick} className={className} $withUnderline={withUnderline}>
      <Avatar
        image={image}
        firstName={firstName}
        lastName={lastName}
        size={size}
        showStar={isAdmin}
        showBranchAdmin={isAdmin && adminPrivileges.includes(privileges.BRANCH_ADMIN)}
        isPending={isPending}
        warning={warningSlugs?.[0]}
        showOnAvatarData={showOnAvatarData}
      />
      <span>
        <Content>
          <NameRow>
            <Name enabled={enableTooltip} size={size}>
              {[firstName, lastName].join(' ')}
            </Name>
            {showCaptain && !!captainType && (
              <Tooltip body={t('captain')}>
                <CircleIcon icon={CAPTAIN_ICONS_BY_TYPE[captainType]} />
              </Tooltip>
            )}

            {renderNext}
          </NameRow>
          {showRating && rating > 0 && (
            <RatingRow>
              <Star />
              {formattingMap.number(rating)}
            </RatingRow>
          )}

          {renderBottom}
        </Content>
      </span>
    </Wrapper>
  );
};

export default User;
