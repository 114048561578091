import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { sortArray } from '@ubeya/shared/utils/sorting';
import useFields from '@ubeya/shared/hooks/account/useFields';
import { FlexColumn, FlexSpace, FlexMiddle } from '../Flex';
import { body } from '../Typography';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as BasePayrollIcon } from '../../assets/payroll-dollar.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { SortableHandler } from '../Table';
import useFormFields from '../../hooks/useFormFields';
import { scrollbar } from '../../style';
import BaseCircleIcon from '../CircleIcon';
import Toggle from '../Toggle';
import { Tooltip } from '../Tooltip';
import { PromptClick } from '..';
import ConfigContext from '../../contexts/ConfigContext';

const SEQUENCE_FIELDS_TYPE = {
  EMPLOYEE_FIELD: 0,
  PAYROLL_FIELD: 1
};

const Wrapper = styled(FlexColumn)`
  overflow: auto;
  ${scrollbar}
`;

const CircleIcon = styled(BaseCircleIcon)`
  visibility: hidden;
`;

const Item = styled(FlexMiddle)`
  margin-bottom: 10px;
  z-index: 5;

  &:hover ${CircleIcon} {
    visibility: visible;
  }
`;

const Name = styled(FlexSpace)`
  ${body}
  flex: 1;
  padding: 4px 13px;
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  border-radius: 5px;
  min-height: 46px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  > div:first-child > svg {
    margin-right: 16px;
    min-width: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
const Left = styled.div`
  margin-top: 1.5%;
  width: 100%;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const Space = styled.div`
  margin: 0 1%;
`;

const PayrollIcon = styled(BasePayrollIcon)`
  width: 15px;
  height: 15px;
`;

const SortableItem = SortableElement(({ sortIndex, field, onEdit, onDelete, onToggleRequire, isDisabled }) => {
  const { t } = useContext(ConfigContext);

  const { fieldsMap } = useFormFields();
  const { mappedFields } = useFields();

  const Icon = fieldsMap[field?.fieldTypeId]?.icon;

  const textName = field.metadata?.text?.replace?.(/\*|_|~|\+|>|`|#/g, '');

  return (
    <Item key={sortIndex}>
      {!isDisabled && <SortableHandler />}

      <Name>
        <Container>
          <Left
            isClickable={!field.isGeneric}
            onClick={isDisabled || field.isGeneric ? undefined : () => onEdit(field)}>
            <FlexMiddle>
              {field?.type === SEQUENCE_FIELDS_TYPE.PAYROLL_FIELD ? <PayrollIcon /> : Icon && <Icon />}

              <Space />
              <div>
                {field.isGeneric
                  ? t(field.name)
                  : field.name || t(field.slug) || `${textName?.slice?.(0, 20)}${textName.length > 20 ? '...' : ''}`}
                {mappedFields?.[field.id] ? ` (${t('profileField')})` : ''}
              </div>
            </FlexMiddle>
          </Left>
          {!isDisabled && (
            <FlexMiddle>
              {!field.isGeneric && <CircleIcon icon={EditIcon} onClick={() => onEdit(field)} tooltip={t('edit')} />}
              <PromptClick
                onClick={() => onDelete(sortIndex)}
                body={t('areYouSureDeleteField')}
                confirmText="delete"
                usePortal>
                <CircleIcon icon={DeleteIcon} tooltip={t('delete')} />
              </PromptClick>

              {onToggleRequire && !fieldsMap[field?.fieldTypeId]?.content && (
                <Tooltip body={t('required')}>
                  <div>
                    <Toggle
                      value={field.isRequired}
                      onChange={(isRequired, e) => {
                        e.stopPropagation();
                        onToggleRequire(sortIndex, isRequired);
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </FlexMiddle>
          )}
        </Container>
      </Name>
    </Item>
  );
});

const SortableList = SortableContainer(({ items, onEdit, onDelete, onToggleRequire, isDisabled }) => (
  <div>
    {items.map?.((field, index) => (
      <SortableItem
        key={index}
        index={index}
        {...{ field, sortIndex: index, onEdit, onDelete, onToggleRequire, isDisabled }}
      />
    ))}
  </div>
));

const FieldsList = ({ fields, onEdit, onDelete, onToggleRequire, onSort, isDisabled, className }) => {
  const handleSort = useCallback(({ oldIndex, newIndex }) => onSort(sortArray(fields, oldIndex, newIndex)), [
    fields,
    onSort
  ]);

  if (!fields.length) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <SortableList
        {...{ items: fields, onEdit, onDelete, onToggleRequire, isDisabled }}
        onSortEnd={handleSort}
        useDragHandle
      />
    </Wrapper>
  );
};

export default FieldsList;
