import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '@ubeya/shared-web/components/Flex';
import { Loader as BaseLoader } from '@ubeya/shared-web/components/Loader';
import { EditableInput } from '../Input';
import { Tooltip } from '../Tooltip';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'editable-input-wrapper' }))`
  position: relative;

  input {
    margin-left: 5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    text-align: center;
  }
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  left: 0;
`;

const PrefixText = styled.span`
  width: ${({ $length }) => ($length <= 1 ? '10px' : '20px')};
`;

const ReadOnlyValue = styled.span`
  ${({ $addMargin }) => $addMargin && 'margin-left:10px;'};
`;

const EditableInputRenderer = ({
  isLoading,
  value,
  onChange,
  onSubmitChange,
  prefix,
  color,
  type,
  readOnly = false,
  placeholder = '',
  tooltip
}) => {
  const prefixStr = prefix || '';
  const allowEdit = !readOnly && !isLoading;

  return (
    <Tooltip disabled={!tooltip} body={tooltip}>
      <Wrapper color={color}>
        <PrefixText $length={prefixStr?.length || 0}>{prefixStr}</PrefixText>

        {!allowEdit && <ReadOnlyValue $addMargin={isLoading}>{value}</ReadOnlyValue>}
        {allowEdit && (
          <EditableInput
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onSubmit={onSubmitChange}
          />
        )}
        {isLoading && <Loader size="xsmall" />}
      </Wrapper>
    </Tooltip>
  );
};

export default EditableInputRenderer;
