import React, { useContext, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Tooltip } from '../Tooltip';
import ConfigContext from '../../contexts/ConfigContext';
import useFormattingMap from '../../hooks/useFormattingMap';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = WEEK * 52;

const getSeconds = (value) => moment().diff(moment.utc(value), 'seconds');

const Container = ({ tooltipContent, children, disabledTooltip = false }) => {
  return (
    <Tooltip body={tooltipContent} disabled={disabledTooltip}>
      <span>{children}</span>
    </Tooltip>
  );
};

const TimeAgoRenderer = ({ dataKey, rowData, disabledTooltip, showDateInBody = false }) => {
  const { t } = useContext(ConfigContext);
  const { parsedLocalTime } = useFormattingMap();
  const value = get(rowData, dataKey);
  const [seconds, setSeconds] = useState(() => getSeconds(value));

  useEffect(() => {
    setSeconds(getSeconds(value));
    const intervalId = setInterval(() => {
      setSeconds(getSeconds(value));
    }, 100000);
    return () => clearInterval(intervalId);
  }, [value]);

  const date = parsedLocalTime(value);

  const label = useMemo(() => {
    if (!value) {
      return null;
    }
    if (seconds < MINUTE) {
      return t('aFewSecondsAgo');
    }

    const minutes = Math.floor(seconds / 60);
    if (seconds < HOUR) {
      return t('minutesAgo', { value: minutes });
    }

    const hours = Math.floor(minutes / 60);
    if (seconds < DAY) {
      return t('hoursAgo', { value: hours });
    }

    const days = Math.floor(hours / 24);
    if (seconds < WEEK) {
      return t('daysAgo', { value: days });
    }

    const weeks = Math.floor(days / 7);
    if (seconds < YEAR) {
      return t('weeksAgo', { value: weeks });
    }

    const years = Math.floor(weeks / 52);
    return t('yearsAgo', { value: years });
  }, [seconds, t, value]);

  const tooltipContent = useMemo(() => (showDateInBody ? label : date), [showDateInBody, label, date]);
  const bodyContent = useMemo(() => (showDateInBody ? date : label), [showDateInBody, label, date]);

  if (!value) {
    return <></>;
  }

  return (
    <Container tooltipContent={tooltipContent} disabledTooltip={disabledTooltip}>
      {bodyContent}
    </Container>
  );
};

export default TimeAgoRenderer;
