import React, { useMemo, useContext } from 'react';
import { AutoSizer } from 'react-virtualized';
import { lighten } from 'polished';
import { PieChart as BasePieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { useTheme } from 'styled-components';
import ConfigContext from '../../contexts/ConfigContext';

const EMPTY_COLOR = 'rgba(153, 195, 255)';

const PieChart = ({
  data,
  nameKey,
  dataKey = 'value',
  isDonut = false,
  legend = true,
  labels = true,
  valueFormatter,
  labelsLimit = 0,
  sideLegend = false,
  showTotal = false,
  tooltip = false,
  labelLine = true,
  tooltipAnimation = true,
  tooltipFormatter,
  totalFormatter,
  donutSize = 30,
  onClick,
  selectedIndex,
  useDataColor = false,
  isAnimationActive,
  defs
}) => {
  const theme = useTheme();
  const { isRtl } = useContext(ConfigContext);

  const total = useMemo(() => (showTotal ? data.reduce((sum, { value }) => sum + value, 0) : 0), [data, showTotal]);
  const allZeros = data.every((entry) => entry.value === 0);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <BasePieChart
          width={width}
          height={height}
          margin={{ top: 20, bottom: 20 }}
          style={{ direction: isRtl ? 'ltr' : undefined }}>
          <defs>
            {defs ??
              data.map((_, index) => (
                <radialGradient gradientUnits="userSpaceOnUse" key={index} id={index}>
                  <stop
                    offset="40%"
                    stopColor={theme.colors.reportPalette[index % theme.colors.reportPalette.length]}
                  />
                  <stop
                    offset="100%"
                    stopColor={lighten(0.9, theme.colors.reportPalette[index % theme.colors.reportPalette.length])}
                  />
                </radialGradient>
              ))}
          </defs>

          {showTotal && (
            <g>
              {totalFormatter ? (
                totalFormatter({ total, x: width / 2, y: height / 2 })
              ) : (
                <text x={width / 2} y={height / 2} textAnchor="middle" dominantBaseline="middle">
                  {total.toLocaleString()}
                </text>
              )}
            </g>
          )}

          <Pie
            isAnimationActive={isAnimationActive}
            data={allZeros ? [{ value: 1 }] : data}
            innerRadius={isDonut ? height / 3 - donutSize / 2 : undefined}
            outerRadius={isDonut ? height / 3 + donutSize / 2 : undefined}
            nameKey={nameKey}
            fill={allZeros ? EMPTY_COLOR : ''}
            fillOpacity={allZeros ? 0.3 : 1}
            dataKey={dataKey}
            labelLine={labelLine}
            label={
              labels
                ? ({ name, value, index }) => {
                    if (index && labelsLimit && index > labelsLimit) {
                      return undefined;
                    }
                    return `${name} - ${valueFormatter ? valueFormatter(value) : value}`;
                  }
                : undefined
            }
            onClick={onClick}
            style={onClick ? { cursor: 'pointer' } : undefined}>
            {data.map((entity, index) => (
              <Cell
                key={index}
                style={entity?.onClick ? { cursor: 'pointer' } : undefined}
                onClick={(e) => {
                  if (entity.onClick) {
                    e.stopPropagation(); // Prevents the Pie click handler from being triggered
                    entity.onClick();
                  }
                }}
                fill={
                  useDataColor
                    ? entity.color
                    : selectedIndex === index
                    ? `url(#${index})`
                    : theme.colors.reportPalette[index % theme.colors.reportPalette.length]
                }
                stroke=""
              />
            ))}
          </Pie>

          {legend && (
            <Legend
              wrapperStyle={{
                lineHeight: '25px',
                ...(sideLegend && { right: '25%', overflow: 'auto' })
              }}
              layout="vertical"
              verticalAlign="middle"
              align="right"
              iconType="circle"
              payload={data
                .sort((a, b) => (a[dataKey] > b[dataKey] ? -1 : 1))
                .map((entry, index) => ({
                  value: `${entry.name} - ${entry[dataKey]}`,
                  color: theme.colors.reportPalette[index % theme.colors.reportPalette.length]
                }))}
            />
          )}
          {tooltip && !allZeros && <Tooltip isAnimationActive={tooltipAnimation} formatter={tooltipFormatter} />}
        </BasePieChart>
      )}
    </AutoSizer>
  );
};

export default PieChart;
