import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';
import { BOOKING_STATUS_MAPPING } from '../../../constants';

const ProjectBookingStatusChip = ({ value }) => {
  const { t } = useContext(ConfigContext);

  return <>{value.map((item) => t(BOOKING_STATUS_MAPPING[item])).join(', ')}</>;
};

export default ProjectBookingStatusChip;
