import React, { useCallback, useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { API_DATE_FORMAT, API_DATE_TIME_FORMAT } from '@ubeya/shared/constants';
import ConfigContext from '../../contexts/ConfigContext';
import { TextInput } from '../Input';
import AutoCompleteOff from '../AutoCompleteOff';
import useMenu from '../../hooks/useMenu';
import BaseDatePicker from './DatePicker';

const Wrapper = styled.div`
  position: relative;
`;

const DatePicker = styled(BaseDatePicker)`
  z-index: 5;
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}
`;

const DatePickerInput = ({
  name,
  title,
  required,
  value,
  onChange,
  usePortal,
  isDisabled,
  placeholder,
  openFromRight,
  error,
  placeholderText,
  isOutsideRange,
  className,
  readOnly,
  withDay = false,
  clearable = true,
  allowInitialEmptyValue = false,
  onClear: handleClearValue = undefined
}) => {
  const { t, dateFormat } = useContext(ConfigContext);

  const { Portal, wrapperRef, isOpen, setOpen, setClose, style, menuRef } = useMenu({ usePortal, openFromRight });

  const handleChange = useCallback(
    (date) => {
      onChange(date);
      setClose();
    },
    [onChange, setClose]
  );

  const handleInputChange = useCallback(
    (date) => {
      /* before :only  handleChange(moment(date, dateFormat).toISOString());  */

      if (!date) {
        return handleChange(date);
      }

      const formattedDate = moment(date, dateFormat, true).isValid()
        ? moment(date, dateFormat)
        : moment(`${date}0/00/0000`, dateFormat);

      return handleChange(formattedDate.format(API_DATE_FORMAT));
    },
    [dateFormat, handleChange]
  );

  const onClear = useCallback(
    () => (clearable ? (handleClearValue ? handleClearValue() : handleInputChange(null)) : null),
    [handleClearValue, clearable, handleInputChange]
  );

  //here : value  with date format, from back end API_DATE_TIME_FORMAT format
  const validValue = useMemo(
    () =>
      value && (moment(value, API_DATE_TIME_FORMAT).isValid() || moment(value, dateFormat).isValid())
        ? value
        : undefined,
    [value, dateFormat]
  );

  const format = useMemo(() => (withDay ? `ddd, ${dateFormat}` : dateFormat), [dateFormat, withDay]);
  const mask = useMemo(() => (withDay ? undefined : '00/00/0000'), [withDay]);

  const showInitialEmptyState = useMemo(() => !validValue && allowInitialEmptyValue, [
    allowInitialEmptyValue,
    validValue
  ]);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <AutoCompleteOff />
      <TextInput
        name={name}
        placeholder={placeholder}
        title={title}
        required={required}
        value={
          validValue
            ? moment(validValue).format(format)
            : showInitialEmptyState
            ? placeholderText || t('chooseTimeRange')
            : ''
        }
        onChange={handleInputChange}
        onFocus={setOpen}
        disabled={isDisabled}
        mask={!showInitialEmptyState && mask}
        error={error}
        clearable={clearable}
        onClear={onClear}
        hideAutoComplete
        readOnly={readOnly}
      />

      {isOpen && (
        <Portal>
          <DatePicker
            isOutsideRange={isOutsideRange}
            allowInitialEmptyValue={allowInitialEmptyValue}
            value={validValue}
            onChange={handleChange}
            menuRef={menuRef}
            innerStyle={style}
          />
        </Portal>
      )}
    </Wrapper>
  );
};

export default DatePickerInput;
