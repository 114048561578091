import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
// workaround for webpack
// eslint-disable-next-line import/no-webpack-loader-syntax
import pdfjsWorker from 'file-loader!pdfjs-dist/build/pdf.worker';
import { ReactComponent as ErrorIcon } from '@ubeya/shared-web/assets/error.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import usePortalHook from '../../hooks/usePortal';
import { FlexCenter } from '../Flex';
import CircleIcon from '../CircleIcon';
import { useModal } from '../Modal';
import ConfigContext from '../../contexts/ConfigContext';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Wrapper = styled(FlexCenter)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1200;
  overflow: auto;
  padding: 34px 0;
  align-items: flex-start;
`;

const CloseButton = styled(CircleIcon)`
  position: fixed;
  top: 28px;
  right: 33px;

  svg path {
    fill: ${({ theme }) => theme.colors.surface};
  }
`;

const Error = styled.div`
  width: 100%;
  height: 200px;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 20rem;
  padding: 20px;

  svg {
    width: 80px;
    height: 80px;
  }
`;

const Content = styled.div`
  text-align: center;
  width: min(800px, 100%);
  margin: 0 auto;
  display: flex;
  ${({ isError }) =>
    isError &&
    css`
      height: 100%;
      align-items: center;
    `}
`;

const PageWrapper = styled.div`
  margin-bottom: 20px;

  .annotationLayer {
    display: none;
  }
`;

const Link = styled.a``;

const PdfModal = ({ url, onClose, closeOnClickOutside = true }) => {
  const { t } = useContext(ConfigContext);
  const [totalPages, setTotalPages] = useState(0);
  const [isError, setIsError] = useState(false);

  const pages = new Array(totalPages).fill('');

  const { Portal } = usePortalHook({
    enabled: true,
    closeOnClickOutside: false,
    portalId: 'modal'
  });

  const { attachModal, detachModal } = useModal();
  useEffect(() => {
    attachModal({ onClose });

    return () => {
      detachModal({ onClose });
    };
  }, [attachModal, detachModal, onClose]);

  return (
    <Portal>
      <Wrapper onClick={() => closeOnClickOutside && onClose()}>
        <CloseButton icon={CloseIcon} onClick={() => onClose()} />

        <Content onClick={(e) => e.stopPropagation()} isError={isError}>
          {isError && (
            <Error>
              <ErrorIcon />
              {t('anErrorOccurred')}
              <Link href={url} target="_blank">
                {t('openInNewTab')}
              </Link>
            </Error>
          )}
          {!isError && (
            <Document
              file={url}
              onSourceError={() => setIsError(true)}
              onLoadError={() => setIsError(true)}
              onLoadSuccess={({ numPages }) => setTotalPages(numPages)}>
              {pages.map((_, page) => (
                <PageWrapper key={page}>
                  <Page pageNumber={page + 1} width={800} />
                </PageWrapper>
              ))}
            </Document>
          )}
        </Content>
      </Wrapper>
    </Portal>
  );
};

export default PdfModal;
