import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, validPhoneNumber } from '@ubeya/shared/utils/form';
import PhoneInput from '../PhoneInput';

const validPhone = (value) => validPhoneNumber(value);

const PhoneField = ({ name, validate, type, title, className, ...props }) => (
  <Field
    name={name}
    validate={validate ? composeValidators(validPhone, validate) : validPhone}
    type={type}
    validateFields={[]}
    render={({ input, meta }) => (
      <PhoneInput {...input} title={title} className={className} error={meta.touched && meta.error} {...props} />
    )}
  />
);

export default PhoneField;
