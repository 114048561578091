import React from 'react';
import useLocations from '@ubeya/shared/hooks/account/useLocations';
import { FlexMiddle } from '../../Flex';

const LocationsChip = ({ value }) => {
  const { mappedLocations } = useLocations();

  if (!Array.isArray(value)) {
    return <FlexMiddle>{mappedLocations[value]?.name || value}</FlexMiddle>;
  }

  return <FlexMiddle>{value.map((item) => mappedLocations[item]?.name).join(', ')}</FlexMiddle>;
};

export default LocationsChip;
