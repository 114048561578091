import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import usePayrollFields from '@ubeya/shared/hooks/account/usePayrollFields';
import { ReactComponent as BaseInfoIcon } from '@ubeya/shared-web/assets/help.svg';
import { CheckBoxField } from '../FormFields';
import FormModal from '../FormModal';
import ConfigContext from '../../contexts/ConfigContext';

const Text = styled.div`
  margin: 0 0 10px 20px;
`;

const BottomLink = styled.a`
  margin: 20px 0 0 20px;
`;

const InfoIcon = styled(BaseInfoIcon)`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;

const PayrollInputModal = ({ accountId, onClose, onConfirm }) => {
  const { t } = useContext(ConfigContext);

  const { fields, mappedFields } = usePayrollFields();
  const fieldsNotDeleted = useMemo(() => (fields || []).filter(({ deletedAt }) => !deletedAt), [fields]);

  return (
    <FormModal
      validate={(values) =>
        Object.entries(values || {}).filter(([, value]) => !!value).length > 0 ? undefined : { required: true }
      }
      onSubmit={(values) => {
        const selectedFields = Object.entries(values || {})
          .filter(([, value]) => !!value)
          .map(([key]) => {
            const id = parseInt(key.replace('payroll-field-', ''), 10);
            return {
              name: t(mappedFields[id].slug),
              ...mappedFields[id],
              isRequired: true,
              type: 1
            };
          });

        onConfirm(selectedFields);
        onClose();
      }}
      onClose={onClose}
      size="small"
      title={t('payrollField')}
      confirmText="add"
      disabledOnInvalid>
      <Text>
        <InfoIcon />
        {t('payrollFieldTooltip')}
      </Text>

      {fieldsNotDeleted?.length > 0 ? (
        fieldsNotDeleted.map(({ id, isGeneric, name, slug }) => (
          <CheckBoxField key={id} name={`payroll-field-${id}`} title={isGeneric ? t(slug) : name} />
        ))
      ) : (
        <BottomLink href={`${accountId}/settings/account/payroll/salary-fields`} target="_blank">
          {t('addFirstPayrollField')}
        </BottomLink>
      )}
    </FormModal>
  );
};

export default PayrollInputModal;
