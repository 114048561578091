import { useContext } from 'react';
import { createCachedSelector } from 're-reselect';
import { useQuery } from 'react-query';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import * as api from '../../services/api';
import useOptimisticMutation from '../useOptimisticMutation';
import { SORT_BY_STRING } from '../../utils/sorting';
import useCRUD from '../useCRUD';
import { mappedArray } from '../../utils/array';
import {
  CONSTELATION_ACCOUNT_ID,
  CONSTELLATION_ACCOUNT_IDS,
  CONSTELLATION_IRELAND_ACCOUNT_ID
} from '../../utils/accounts';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data, t) => t,
  (data, t, currency) => currency,
  (data, t, currency, accountId) => accountId,
  (data, t, currency, accountId) => {
    const labelSorter = SORT_BY_STRING('label');
    const nameSorter = SORT_BY_STRING('slug');

    const formattedPositions = (data || []).map((position) => {
      const payRate = position?.wage?.hourlyWage;

      const slugWithPayRate = CONSTELLATION_ACCOUNT_IDS.includes(accountId)
        ? `${t(position.slug)}${payRate ? ` (${currency}${payRate})` : ``}`
        : t(position.slug);

      const slug =
        accountId === CONSTELATION_ACCOUNT_ID || accountId === CONSTELLATION_IRELAND_ACCOUNT_ID
          ? slugWithPayRate
          : `${slugWithPayRate}${position.createdByClientId ? ` (${position.clientAccountName})` : ``}`;

      return {
        ...position,
        slug,
        prevSlug: position.slug
      };
    });

    const positions = formattedPositions.filter(({ isDeleted }) => !isDeleted).sort(nameSorter);

    const positionsOptions = positions
      .map(({ id, slug }) => ({
        value: id,
        label: slug
      }))
      .sort(labelSorter);

    const mappedPositions = mappedArray(
      formattedPositions,
      ({ id }) => id,
      (position) => ({ ...position, label: position.slug })
    );

    return { positions, positionsOptions, positionsIncludeDeleted: formattedPositions, mappedPositions };
  }
)({
  keySelector: (data, t, currency, accountId, storeKey) => storeKey
});

const usePositions = () => {
  const { t, currency } = useContext(ConfigContext);
  const { accountId } = useAccount();

  const storeKey = ['positions', accountId];
  const { isLoading, data, refetch } = useQuery(storeKey, () => api.fetchPositions({ accountId }), {
    enabled: !!accountId,
    select: (positionsData) => selector(positionsData, t, currency, accountId, storeKey.join('#'))
  });

  const { mappedPositions = {}, positions = [], positionsOptions = [], positionsIncludeDeleted = [] } = data || {};

  const { addItem, editItem, deleteItem } = useCRUD(
    storeKey,
    {
      addApi: (position) => api.addPosition({ accountId, position }),
      editApi: ({ id, ...position }) => api.updatePosition({ accountId, positionId: id, position }),
      deleteApi: ({ id }) => api.updatePositions({ accountId, positions: { deleted: [id] } })
    },
    { refetchOnSuccessDelete: true }
  );

  const { mutateAsync: asyncAttachments } = useOptimisticMutation(
    storeKey,
    (positionsData) => api.updatePositions({ accountId, positions: positionsData }),
    ({ previousData, deleted = [], addedWithName = [] }) => ({
      ...previousData,
      data: [...addedWithName, ...previousData.data.filter((position) => !deleted.includes(position.id))]
    })
  );

  return {
    isLoading,
    refetch,
    mappedPositions,
    positionsIncludeDeleted,
    positions,
    positionsOptions,
    addPosition: addItem,
    deletePosition: deleteItem,
    editPosition: editItem,
    asyncAttachments
  };
};

export default usePositions;
