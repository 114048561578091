import { useEffect, useRef } from 'react';

const useTimeout = (callback, timeout, deps = []) => {
  const refCallback = useRef();
  const refTimer = useRef();

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const timerID = setTimeout(refCallback.current, timeout);
    refTimer.current = timerID;

    return () => clearTimeout(timerID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  function cancelTimer() {
    return clearTimeout(refTimer.current);
  }

  return cancelTimer;
};

export default useTimeout;
