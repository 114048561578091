import React, { useCallback, useContext } from 'react';
import useSafeState from '@ubeya/shared/hooks/useSafeState';
import DropDownContext from '../../contexts/DropDownContext';
import ConfirmationModal from '../ConfirmationModal';

const PromptClick = ({
  onClick,
  children,
  title = 'areYouSure',
  body = 'areYouSure',
  renderBody,
  confirmText,
  disabledConfirm,
  showCancelButton = true,
  usePortal,
  stopPropagation = false,
  disabledConfirmTitle = false,
  enable = true,
  isOpen: defaultIsOpen = false,
  onClose,
  isWaitingForResponse = true,
  blockTypeModal = false,
  confirmationModalContainerStyle,
  confirmationModalWrapperStyle
}) => {
  const { closeMenu } = useContext(DropDownContext);
  const [isOpen, setIsOpen] = useSafeState(defaultIsOpen);
  const [isLoading, setIsLoading] = useSafeState(false);

  const handleClose = useCallback(() => {
    if (defaultIsOpen) {
      onClose();
    } else {
      setIsOpen(false);
      closeMenu?.();
      onClose?.();
    }
  }, [closeMenu, defaultIsOpen, onClose, setIsOpen]);

  if (!enable) {
    return React.cloneElement(React.Children.only(children), {
      onClick: (e) => {
        if (stopPropagation) {
          e.stopPropagation();
          e.preventDefault();
        }
        onClick?.(e);
      }
    });
  }

  if (!isOpen) {
    return React.cloneElement(React.Children.only(children), {
      onClick: (e) => {
        if (stopPropagation) {
          e.stopPropagation();
          e.preventDefault();
        }
        setIsOpen(true);
      }
    });
  }
  return (
    <>
      {children}
      <ConfirmationModal
        confirmationModalContainerStyle={confirmationModalContainerStyle}
        confirmationModalWrapperStyle={confirmationModalWrapperStyle}
        onClose={handleClose}
        title={!disabledConfirmTitle ? title : ''}
        body={renderBody ? undefined : body}
        onConfirm={async (e) => {
          try {
            if (isWaitingForResponse) {
              setIsLoading(true);
              await onClick(e);
              setIsLoading(false);
            } else {
              onClick(e);
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          } finally {
            handleClose();
          }
        }}
        confirmText={confirmText}
        loadingConfirm={isLoading}
        disabledConfirm={disabledConfirm}
        blockTypeModal={blockTypeModal}
        showCancelButton={showCancelButton}
        usePortal={usePortal}>
        {renderBody}
      </ConfirmationModal>
    </>
  );
};

export default PromptClick;
