import React, { useContext } from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';
import { Ellipsis } from '../Typography';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { Tooltip } from '../Tooltip';
import ConfigContext from '../../contexts/ConfigContext';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'filters-row-chip-wrapper' }))`
  font-weight: 400;
  font-size: 13px;
  line-height: 20rem;
  background-color: ${({ $isDisabled, theme }) => ($isDisabled ? '#E1E5FD' : theme.colors.filtersTags)};
  cursor: ${({ $isDisabled }) => $isDisabled && 'not-allowed'};
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Value = styled(Ellipsis).attrs(() => ({ className: 'filters-row-chip-value' }))`
  max-width: 150px;
  margin-left: 5px;
`;

const RemoveButton = styled(CloseIcon)`
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

const Chip = ({ label, value, onRemove, isDisabled: isFilterDisabled }) => {
  const { t } = useContext(ConfigContext);
  return (
    <Tooltip disabled={!isFilterDisabled} body={t('filterIsDisabled', { label })}>
      <Wrapper $isDisabled={isFilterDisabled}>
        {label}: <Value>{value}</Value>
        {onRemove && <RemoveButton onClick={onRemove} />}
      </Wrapper>
    </Tooltip>
  );
};

export default Chip;
