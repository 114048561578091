import React, { useContext, useMemo } from 'react';
import ConfigContext from '../../contexts/ConfigContext';
import useFormFields from '../../hooks/useFormFields';

const DynamicField = ({ name, title, type = 'text', fieldTypeId, options = [], allowMultiple, ...extraData }) => {
  const { t } = useContext(ConfigContext);

  const formattedOptions = useMemo(
    () =>
      options.map(({ id, value, label, title: optionTitle }) => ({
        label: t(optionTitle) || t(label),
        value: id || value
      })),
    [t, options]
  );

  const { fieldsMap, fieldsBySlug } = useFormFields();
  const selectedComponent = fieldTypeId ? fieldsMap[fieldTypeId] : fieldsBySlug[type];

  const { Component } = selectedComponent;

  return (
    <Component
      title={title}
      name={name}
      options={formattedOptions}
      type={type}
      usePortal
      isMulti={allowMultiple}
      {...extraData}
    />
  );
};

export default DynamicField;
