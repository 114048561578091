import React from 'react';
import { Field } from 'react-final-form';
import NumberInput from '../NumberInput';

const NumberField = ({ name, validate, showErrorMessage = true, ...restProps }) => (
  <Field
    name={name}
    validate={validate}
    render={({ input, meta }) => (
      <NumberInput
        {...input}
        {...restProps}
        error={meta.touched && meta.error && (showErrorMessage ? meta.error : ' ')}
      />
    )}
  />
);

export default NumberField;
