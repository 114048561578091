import { useMutation, useQueryClient } from 'react-query';
import useToaster from './useToaster';

const useOptimisticMutation = (
  key,
  fetch,
  mutateData,
  { refetchOnSuccess = true, refetchOnError = true, onSuccess, onError, successToasterMessage = null } = {}
) => {
  const { addResponseError, addSuccess } = useToaster();
  const queryClient = useQueryClient();

  const mutateResponse = useMutation(fetch, {
    onMutate: (payload) => {
      // cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(key, { exact: true });

      const previousData = queryClient.getQueryData(key);

      queryClient.setQueryData(key, mutateData?.({ previousData, ...payload }) || previousData);

      return () => queryClient.setQueryData(key, previousData);
    },
    // eslint-disable-next-line handle-callback-err
    onError: (err, variables, rollback) => {
      onError?.(err, variables);
      addResponseError(err);
      rollback();
    },
    onSettled: (newData, error, variables) => {
      if (!error && onSuccess) {
        onSuccess(variables, newData);
      }

      if (!error && successToasterMessage) {
        addSuccess(successToasterMessage);
      }
      if ((!error && refetchOnSuccess) || (error && refetchOnError)) {
        return queryClient.invalidateQueries(key, { exact: true });
      }
    }
  });

  return mutateResponse;
};

export default useOptimisticMutation;
