import React, { useContext } from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { required } from '@ubeya/shared/utils/form';
import { PromptClick } from '@ubeya/shared-web/components';
import { TextInput as BaseTextInput } from '../../Input';
import InputField from '../../FormFields/InputField';
import CheckBoxField from '../../FormFields/CheckBoxField';
import { FlexMiddle } from '../../Flex';
import Button from '../../Button';
import CircleIcon from '../../CircleIcon';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import ConfigContext from '../../../contexts/ConfigContext';
import { EditableByAdmins } from './EditableByAdmins';

const CloseButton = styled(CircleIcon)`
  margin-left: 5px;
  z-index: 1;
  &&& {
    width: 36px;
  }
`;

const TextInput = styled(BaseTextInput)`
  width: 100%;
`;

const DropDownFieldForm = ({ showLimitEditAdminsToggle = false }) => {
  const { t } = useContext(ConfigContext);

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        validate={required}
        autoFocus
        required
        className="full"
      />

      <Field
        name="options"
        validate={(values = []) => (values.filter(({ title }) => title).length === 0 ? 'error' : undefined)}
        render={({ input, meta }) => (
          <>
            {input.value?.map?.((_, index) => (
              <FlexMiddle key={index} className="full">
                <TextInput
                  placeholder={`${t('option')} ${index + 1}`}
                  value={input.value[index].title}
                  onChange={(value) =>
                    input.onChange(
                      input.value.map((option, optionIndex) =>
                        index === optionIndex ? { ...option, title: value } : option
                      )
                    )
                  }
                  error={meta.touched && meta.error}
                />
                <PromptClick
                  usePortal
                  onClick={() => input.onChange([...input.value.slice(0, index), ...input.value.slice(index + 1)])}
                  body="areYouSureYouWantToDeleteOption"
                  confirmText="delete">
                  <CloseButton icon={CloseIcon} />
                </PromptClick>
              </FlexMiddle>
            ))}

            <div className="full">
              <Button type="button" secondary onClick={() => input.onChange([...(input.value || []), { title: '' }])}>
                {t('addOption')}
              </Button>
            </div>
          </>
        )}
      />

      <CheckBoxField name="allowMultiple" title={t('allowMultiple')} className="full" />
      {showLimitEditAdminsToggle && <EditableByAdmins />}
    </>
  );
};

export default DropDownFieldForm;
