import { css } from 'styled-components';

export const defaultMediaBreakpoints = {
  mobile: '680px',
  tablet: '1000px',
  desktop: '1280px',
  wide: '1440px'
};

const media = Object.keys(defaultMediaBreakpoints).reduce((memo, val) => {
  // eslint-disable-next-line no-param-reassign
  memo[val] = (...args) => css`
    @media (min-width: ${defaultMediaBreakpoints[val]}) {
      ${css(...args)};
    }
  `;
  return memo;
}, {});

export default media;
