import React from 'react';
import useBranches from '@ubeya/shared/hooks/account/useBranches';
import { FlexMiddle } from '../../Flex';

const BranchesChip = ({ value }) => {
  const { mappedBranches } = useBranches();

  return <FlexMiddle>{value.map((item) => mappedBranches[item]?.name).join(', ')}</FlexMiddle>;
};

export default BranchesChip;
