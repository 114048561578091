import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import ReactSlider from 'react-slider';
import { Small } from '../Typography';

const StyledThumb = styled.div`
  margin-top: -7px;
  height: 16px;
  width: 16px;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  position: relative;
  background: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const disabledThumb = css`
  ${StyledThumb} {
    cursor: no-drop;
    background-color: ${({ theme }) => theme.colors.backgroundLight};
  }
`;

const Text = styled(Small)`
  position: absolute;
  color: ${({ theme }) => theme.colors.gray200};
  top: 18px;
  transform: translateX(-50%);
  left: 50%;
`;

const StyledSlider = styled(ReactSlider)`
  width: 230px;
  height: 3px;
  margin: 10px 0 27px;
  ${({ disabled }) => disabled && disabledThumb}
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${({ theme, index, isRange }) =>
    (isRange && index === 1) || (!isRange && index === 0) ? theme.colors.primary : theme.colors.gray900};
  border-radius: 999px;
`;

const thumbRenderer = (props, state, showValue, valueSuffix) => (
  <StyledThumb {...props}>{showValue && <Text>{`${state.valueNow} ${valueSuffix || ''}`}</Text>}</StyledThumb>
);

const Track = (props, state) => <StyledTrack {...props} index={state.index} isRange={Array.isArray(state.value)} />;

const Slider = ({
  value,
  onChange,
  min,
  max,
  valueSuffix = '',
  disabled,
  className,
  showValue = true,
  isFloat = false
}) => {
  const sliderRef = useRef(null);

  // The slider needs to be resized when a parent container becomes visible (e.g. via a display: block)
  // We'll use a ResizeObserver to detect this and check the offsetParent property on the wrapping div
  // because this will only be null if the slider is hidden
  useEffect(() => {
    if (sliderRef.current.slider === null) return;

    function _handleResize() {
      // If offsetParent is null, the element is not visible
      if (sliderRef.current.slider.offsetParent !== null) {
        sliderRef.current.resize();
      }
    }

    // Observe the slider dom element which will trigger when it's shown via a parent container visibility
    const ro = new ResizeObserver(_handleResize);
    ro.observe(sliderRef.current.slider);

    // Cleanup
    return () => {
      ro.disconnect();
    };
  }, []);

  return (
    <StyledSlider
      ref={sliderRef}
      className={className}
      renderTrack={Track}
      renderThumb={(props, state) => thumbRenderer(props, state, showValue, valueSuffix)}
      value={value || min}
      onChange={onChange}
      min={min}
      max={max}
      step={isFloat ? 0.1 : 1}
      disabled={disabled}
      showValue={showValue}
    />
  );
};

export default Slider;
