import React, { useContext } from 'react';
import { required } from '@ubeya/shared/utils/form';
import InputField from '../../FormFields/InputField';
import ConfigContext from '../../../contexts/ConfigContext';
import { EditableByAdmins } from './EditableByAdmins';

const SimpleFieldForm = ({ showLimitEditAdminsToggle = false }) => {
  const { t } = useContext(ConfigContext);

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        validate={required}
        required
        autoFocus
        className="full"
      />
      {showLimitEditAdminsToggle && <EditableByAdmins />}
    </>
  );
};

export default SimpleFieldForm;
