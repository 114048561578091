import styled, { css } from 'styled-components';
import { flex } from './Flex';

export const flexEnd = css`
  ${flex};
  justify-content: flex-end;
`;

export const FlexEnd = styled.div`
  ${flexEnd};
`;
