import React, { useEffect } from 'react';
import styled from 'styled-components';
import BaseModal from './index';
import { TextareaAutosize } from '../Input';

const Modal = styled(BaseModal)`
  max-width: 400px;

  .modal-content {
    padding: 10px;
  }
`;

export const TextPreviewModal = ({ onClose, textAreaRef, inputParams }) => {
  useEffect(() => {
    if (textAreaRef?.current) {
      textAreaRef?.current?.focus();
      textAreaRef?.current?.setSelectionRange(textAreaRef.current.value.length, textAreaRef.current.value.length);
    }
  }, [textAreaRef]);

  return (
    <Modal size="small" onClose={onClose} showConfirmButton={false} isPaddingContent={false} usePortal>
      <TextareaAutosize {...inputParams} $isPreview autofocus textAreaRef={textAreaRef} />
    </Modal>
  );
};
