import { useState, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';

// defaultParams - initialized state
// params - listen to changes to reset page

const usePagination = ({ defaultParams, params } = {}) => {
  const [page, setPage] = useState(1);
  const [state, setState] = useState({ ...defaultParams, ...params });

  const handleSetState = useCallback((newParams) => {
    setState((prevParams) => ({ ...prevParams, ...newParams }));
    setPage(1);
  }, []);

  useEffect(() => {
    if (!isEqual(params, state) && !isEqual({ ...defaultParams, ...params }, state)) {
      handleSetState(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return { page, setPage, state, setState: handleSetState };
};

export default usePagination;
