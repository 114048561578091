import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { API_DATE_FORMAT } from '@ubeya/shared/constants';
import { Tooltip } from '../Tooltip';
import TimeAgoRenderer from './TimeAgoRenderer';

const DateWithTimeRenderer = ({ rowData, dataKey }) => {
  const value = get(rowData, dataKey);

  return value ? (
    <Tooltip body={<TimeAgoRenderer rowData={rowData} dataKey={dataKey} />}>
      <>{moment(value).format(API_DATE_FORMAT)}</>
    </Tooltip>
  ) : (
    <></>
  );
};

export default DateWithTimeRenderer;
