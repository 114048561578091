import { useContext, useMemo } from 'react';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// FORMS
import { DescriptionFieldForm } from '../components/Fields/FieldsForm';
import { DocumentFieldForm } from '../components/Fields/FieldsForm';
import { ImageFieldForm } from '../components/Fields/FieldsForm';
import { DropDownFieldForm } from '../components/Fields/FieldsForm';
import { RatingFieldForm } from '../components/Fields/FieldsForm';
import { SimpleFieldForm } from '../components/Fields/FieldsForm';
import { SimpleFieldFormWithExpiration } from '../components/Fields/FieldsForm';
import { SliderFieldForm } from '../components/Fields/FieldsForm';
import { TextFieldForm } from '../components/Fields/FieldsForm';
import { NumberFieldForm } from '../components/Fields/FieldsForm';

// FIELDS
import DateField from '../components/FormFields/DateField';
import InputField from '../components/FormFields/InputField';
import SelectField from '../components/FormFields/SelectField';
import AddressPlacesField from '../components/FormFields/AddressPlacesField';
import SliderField from '../components/FormFields/SliderField';
import PhoneField from '../components/FormFields/PhoneField';
import RatingField from '../components/FormFields/RatingField';
import CheckBoxField from '../components/FormFields/CheckBoxField';
import DescriptionField from '../components/Fields/DescriptionField';
import ImageField from '../components/Fields/ImageField';
import UploadField from '../components/Fields/UploadField';
import DocumentField from '../components/Fields/DocumentField';
import NumberField from '../components/FormFields/NumberField';

//ICONS
import { ReactComponent as TextFieldIcon } from '../assets/text-field.svg';
import { ReactComponent as NumberFieldIcon } from '../assets/number-field.svg';
import { ReactComponent as DateFieldIcon } from '../assets/date-field.svg';
import { ReactComponent as DropdownFieldIcon } from '../assets/dropdown-field.svg';
import { ReactComponent as LocationFieldIcon } from '../assets/location-field.svg';
import { ReactComponent as SliderFieldIcon } from '../assets/slider-field.svg';
import { ReactComponent as PhoneFieldIcon } from '../assets/phone-field.svg';
import { ReactComponent as RatingFieldIcon } from '../assets/rating-field.svg';
import { ReactComponent as ParagraphFieldIcon } from '../assets/paragraph-field.svg';
import { ReactComponent as ImageFieldIcon } from '../assets/photo.svg';
import { ReactComponent as SignatureFieldIcon } from '../assets/signature.svg';
import { ReactComponent as UploadIcon } from '../assets/upload.svg';
import { ReactComponent as UploadFileIcon } from '../assets/file-upload.svg';
import { ReactComponent as CheckboxFieldIcon } from '../assets/checkbox-field.svg';

import PrivilegesContext from '../contexts/PrivilegesContext';
import privileges from '../privileges';
import ConfigContext from '../contexts/ConfigContext';

export const FIELDS_MAP = {
  1: {
    slug: 'text',
    FormComponent: TextFieldForm,
    initialValues: { metadata: { limitType: 'max' } },
    Component: InputField,
    viewFormatter: (value) => value,
    supported: true,
    icon: TextFieldIcon,
    index: 1
  },
  2: {
    slug: 'date',
    FormComponent: SimpleFieldForm,
    Component: DateField,
    viewFormatter: (value, { format }) => (value ? moment(value).format(format) : ''),
    supported: true,
    icon: DateFieldIcon,
    index: 2
  },
  3: {
    slug: 'dropdown',
    FormComponent: DropDownFieldForm,
    initialValues: { options: [{ title: '' }, { title: '' }] },
    Component: SelectField,
    viewFormatter: (value, { mapOptions }) =>
      value && Array.isArray(value) ? value.map((option) => mapOptions[option]) : mapOptions[value],
    supported: true,
    icon: DropdownFieldIcon,
    index: 3
  },
  4: {
    slug: 'image',
    FormComponent: ImageFieldForm,
    Component: ImageField,
    content: true,
    icon: ImageFieldIcon,
    index: 5
  },
  5: {
    slug: 'paragraph',
    FormComponent: DescriptionFieldForm,
    Component: DescriptionField,
    content: true,
    icon: ParagraphFieldIcon,
    index: 4
  },
  6: {
    slug: 'file',
    FormComponent: DocumentFieldForm,
    Component: DocumentField,
    icon: UploadFileIcon,
    content: true,
    index: 6
  },
  // 7: {
  //   slug: 'yesNo',
  //   FormComponent: CheckboxFieldForm,
  //   Component: ToggleField,
  //   viewFormatter: (value) => (value ? 'YES' : 'NO'),
  //   icon: ToggleFieldIcon,
  //   supported: true,
  //   index: 7
  // },
  8: {
    slug: 'location',
    FormComponent: SimpleFieldForm,
    Component: AddressPlacesField,
    icon: LocationFieldIcon,
    supported: true,
    supportOnlyAdmins: true,
    index: 8
  },
  7: {
    slug: 'checkbox',
    FormComponent: SimpleFieldForm,
    Component: CheckBoxField,
    icon: CheckboxFieldIcon,
    supported: true,
    index: 7
  },
  12: {
    slug: 'rating',
    FormComponent: RatingFieldForm,
    Component: RatingField,
    initialValues: { stars: 5 },
    icon: RatingFieldIcon,
    index: 12
  },
  10: {
    slug: 'signature',
    FormComponent: SimpleFieldForm,
    Component: InputField,
    icon: SignatureFieldIcon,
    supported: true,
    index: 10
  },
  9: {
    slug: 'uploadDocument',
    FormComponent: SimpleFieldFormWithExpiration,
    Component: UploadField,
    icon: UploadIcon,
    supported: true,
    viewFormatter: (value) => (Array.isArray(value) ? value[0] : value) || null,
    index: 14
  },
  15: {
    slug: 'slider',
    FormComponent: SliderFieldForm,
    initialValues: { min: 0, max: 10 },
    Component: SliderField,
    icon: SliderFieldIcon,
    index: 15
  },
  16: {
    slug: 'phone',
    FormComponent: SimpleFieldForm,
    Component: PhoneField,
    viewFormatter: (value) => (value ? parsePhoneNumberFromString(`+${value}`).formatNational() : ''),
    icon: PhoneFieldIcon,
    index: 16
  },
  11: {
    slug: 'number',
    FormComponent: NumberFieldForm,
    Component: NumberField,
    viewFormatter: (value) => parseInt(value),
    supported: true,
    icon: NumberFieldIcon,
    index: 1
  }
};

export const SHOW_AND_NOT_SUPPORTED = Object.entries(FIELDS_MAP)
  .filter(([, { showAndNotSupported }]) => showAndNotSupported)
  .map((key) => parseInt(key, 10));

export const CONTENT_DOCUMENT_TYPE_ID = 6;

const useFormFields = (
  includeUnsupportedInputs,
  includeContent,
  includeShowNotSupportedInputs,
  includeOnlyAdminSupported
) => {
  const { t } = useContext(ConfigContext);
  const myPrivileges = useContext(PrivilegesContext);

  const fieldTypesOptions = useMemo(() => {
    const inputFields = Object.entries(FIELDS_MAP)
      .sort(([, { index: a }], [, { index: b }]) => a - b)
      .filter(([, { content }]) => !content)
      .filter(([, { supported, showAndNotSupported }]) =>
        includeUnsupportedInputs
          ? true
          : myPrivileges[privileges.UBEYA_ADMIN] && includeShowNotSupportedInputs
          ? supported || showAndNotSupported
          : supported
      )
      .filter(([, { supportOnlyAdmins }]) => !supportOnlyAdmins || (supportOnlyAdmins && includeOnlyAdminSupported));

    const contentFields = Object.entries(FIELDS_MAP)
      .sort(([, { index: a }], [, { index: b }]) => a - b)
      .filter(([, { content }]) => content);

    const allFields = includeContent ? [...inputFields, ...contentFields] : inputFields;

    return allFields.map(([key, value]) => ({
      value: parseInt(key, 10),
      label: t(value?.slug),
      content: value?.content || false,
      icon: value?.icon
    }));
  }, [
    includeContent,
    includeUnsupportedInputs,
    myPrivileges,
    includeShowNotSupportedInputs,
    includeOnlyAdminSupported,
    t
  ]);

  const fieldsBySlug = useMemo(
    () => Object.values(FIELDS_MAP).reduce((prev, { slug, ...rest }) => ({ ...prev, [slug]: rest }), {}),
    []
  );

  return { fieldsMap: FIELDS_MAP, fieldTypesOptions, fieldsBySlug };
};

export default useFormFields;
