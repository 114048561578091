import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '../Tooltip';
import { TagLabel, TagRoot } from './styled';

const Tag = ({ type, onClick, icon, label, deleteIcon, maxWidth, style }) => {
  return (
    <TagRoot type={type} onClick={onClick} maxWidth={maxWidth} style={style}>
      {icon}
      <Tooltip body={label} mouseEnterDelay={0.3}>
        <TagLabel>{label}</TagLabel>
      </Tooltip>
      {deleteIcon}
    </TagRoot>
  );
};

Tag.propTypes = {
  type: PropTypes.oneOfType([PropTypes.oneOf(['filled', 'outlined']), PropTypes.string]),
  className: PropTypes.string,
  clickable: PropTypes.bool,
  deleteIcon: PropTypes.element,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.node,
  onClick: PropTypes.func,
  onDelete: PropTypes.func
};

export default Tag;
