import React from 'react';
import styled from 'styled-components';
import { FlexCenter } from '../Flex';
import { Small } from '../Typography';
import { CircleChip } from '../Chip';

const COLORS = {
  full: '#10B07C',
  almost: '#FEB11F',
  notFull: '#f24f63'
};

const Total = styled(Small)`
  font-size: 14px;
  text-align: center;
  line-height: 1.57;
`;

const Label = styled.span`
  font-size: 11px;
  margin-left: 7px;
  color: ${({ theme, $color, $noColor }) => $color || ($noColor ? theme.colors.surface : theme.colors.grey000)};
`;

const ProgressRenderer = ({ columnData: { total, quantity, noStyle = false } }) => {
  const percentage = total === 0 ? 0 : Math.round((quantity * 100) / total);
  const chipColor = percentage >= 100 ? COLORS.full : percentage > 0 ? COLORS.almost : COLORS.notFull;

  return noStyle ? (
    <Total>
      {quantity}
      <Label>({percentage}%)</Label>
    </Total>
  ) : (
    <FlexCenter>
      <CircleChip $color={chipColor}>
        <Total>
          {quantity}
          {percentage === 100 && <Label $noColor="true">({percentage}%)</Label>}
        </Total>
      </CircleChip>
      {percentage !== 100 && <Label $color={chipColor}>({percentage}%)</Label>}
    </FlexCenter>
  );
};

export default ProgressRenderer;
