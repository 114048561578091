import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  max-width: 100%;
`;

const ImageField = ({ title, src, metadata: { path } }) => <Image src={src || path} title={title} />;

export default ImageField;
