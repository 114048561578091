import React from 'react';
import styled from 'styled-components';

const Range = styled.input.attrs(() => ({ type: 'range' }))`
  /*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/

  height: 28px;
  width: 100%;
  -webkit-appearance: none;

  /*progress support*/

  --min: ${({ min }) => min};
  --max: ${({ max }) => max};
  --value: ${({ value }) => value};
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 16px + var(--ratio) * (100% - 16px));

  &:focus {
    outline: none;
  }

  /*webkit*/
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #ffffff;
    border: 2px solid ${({ theme }) => theme.colors.dashboard.purple};
    box-shadow: none;
    margin-top: calc(2px * 0.5 - max(16px * 0.5, 2px));
  }

  &::-webkit-slider-runnable-track {
    height: 2px;
    border: none;
    border-radius: 1px;
    background: ${({ theme }) => theme.colors.dashboard.progressBackground};
    box-shadow: none;
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
          ${({ theme }) => theme.colors.dashboard.purple},
          ${({ theme }) => theme.colors.dashboard.purple}
        )
        0 / var(--sx) 100% no-repeat,
      ${({ theme }) => theme.colors.dashboard.progressBackground};
  }

  /*mozilla*/
  &::-moz-range-thumb {
    width: max(calc(16px - 2px - 2px), 0px);
    height: max(calc(16px - 2px - 2px), 0px);
    border-radius: 16px;
    background: #ffffff;
    border: 2px solid ${({ theme }) => theme.colors.dashboard.purple};
    box-shadow: none;
  }

  &::-moz-range-track {
    height: 2px;
    border: none;
    border-radius: 1px;
    background: ${({ theme }) => theme.colors.dashboard.progressBackground};
    box-shadow: none;
  }

  &::-moz-range-track {
    background: linear-gradient(
          ${({ theme }) => theme.colors.dashboard.purple},
          ${({ theme }) => theme.colors.dashboard.purple}
        )
        0 / var(--sx) 100% no-repeat,
      ${({ theme }) => theme.colors.dashboard.progressBackground};
  }

  /*ms*/
  &::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
  }

  &::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
  }

  &::-ms-thumb {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #ffffff;
    border: 2px solid ${({ theme }) => theme.colors.dashboard.purple};
    box-shadow: none;
    margin-top: 0;
    box-sizing: border-box;
  }

  &::-ms-track {
    height: 2px;
    border-radius: 1px;
    background: ${({ theme }) => theme.colors.dashboard.progressBackground};
    border: none;
    box-shadow: none;
    box-sizing: border-box;
  }

  &::-ms-fill-lower {
    height: 2px;
    border-radius: 1px 0 0 1px;
    margin: 0;
    background: ${({ theme }) => theme.colors.dashboard.purple};
    border: none;
    border-right-width: 0;
  }
`;

const Timeline = (props) => {
  return <Range min={0} max={100} {...props} />;
};

export default Timeline;
