import React, { useCallback, useEffect, useState } from 'react';
import ModalContext from './ModalContext';

const ModalProvider = ({ children }) => {
  // stack for modals callbacks functions (onClose)
  const [modalCallbacks, setModalCallbacks] = useState([]);

  useEffect(() => {
    const keydown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();

        // call last opened callback on escape button hit
        const lastModal = modalCallbacks.at(-1);

        if (lastModal && !lastModal?.modalOptions?.disableCloseClickOutside) {
          lastModal.onClose();
        }
      }
    };

    window.addEventListener('keydown', keydown);

    return () => {
      window.removeEventListener('keydown', keydown);
    };
  });

  const value = {
    attachModal: useCallback(({ onClose, modalOptions = {} }) => {
      // append new function (eg. onClose) to stack if not present
      setModalCallbacks((prev) => {
        if (prev.find(({ onClose: cb }) => cb === onClose)) {
          return prev;
        }
        return [...prev, { onClose, modalOptions }];
      });
    }, []),

    detachModal: useCallback(({ onClose }) => {
      // remove function (eg. onClose) from our stack
      setModalCallbacks((prev) => prev.filter(({ onClose: cb }) => cb !== onClose));
    }, [])
  };
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
