import React from 'react';
import styled from 'styled-components';

const SeparatorLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
  margin: 11px -10px;
`;

const DropDownSeparator = () => <SeparatorLine />;

export default DropDownSeparator;
