import React from 'react';
import useTranslatedOptions from '@ubeya/shared/hooks/useTranslatedOptions';

const ADMIN_TYPE_OPTIONS = [
  { label: 'managerAdmin', value: 0 },
  { label: 'branch_owner', value: 1 },
  { label: 'account_owner', value: 2 }
];

const AdminChip = ({ value }) => {
  const adminTypeOptions = useTranslatedOptions(ADMIN_TYPE_OPTIONS);

  return (
    <>
      {value
        .map((item) => adminTypeOptions.find((adminTypeOption) => adminTypeOption.value === item)?.label)
        .join(', ')}
    </>
  );
};

export default AdminChip;
