import { useMemo } from 'react';
import { useQuery } from 'react-query';
import * as api from '../../services/api';
import useAccount from './useAccount';

const useAdmins = () => {
  const { accountId } = useAccount();

  const storeKey = ['admins', accountId];
  const { isLoading, refetch, data } = useQuery(storeKey, () => api.fetchAdmins({ accountId }));

  const mappedAdmins = useMemo(
    () =>
      (data?.data || []).reduce((all, admin) => {
        all[admin.id] = admin;
        return all;
      }, {}),
    [data?.data]
  );

  return { isLoading, refetch, mappedAdmins, admins: data?.data || [] };
};

export default useAdmins;
