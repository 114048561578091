import styled from 'styled-components';

export const LoaderWrapper = styled.div.attrs(() => ({ className: 'loader' }))`
  position: absolute;
  top: var(--header-row-height);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  align-items: center;
`;
