import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash';
import { FlexColumn, FlexSpace } from '../Flex';
import CircleIcon from '../CircleIcon';
import { Header2, smallBodySemiBold } from '../Typography';
import { ReactComponent as BaseArrow } from '../../assets/arrow.svg';

const BoxWrapper = styled(FlexSpace)`
  ${smallBodySemiBold};
  border: 1px solid ${({ theme }) => theme.colors.gray900};
  background: ${({ theme }) => theme.colors.background};
  padding: 4px 20px;
  cursor: pointer;
  &:not(:first-child) {
    border-top: none;
  }
`;

const BoxContent = styled(FlexColumn).attrs(() => ({ className: 'accordion-box-content' }))`
  border-left: 1px solid ${({ theme }) => theme.colors.gray900};
  border-right: 1px solid ${({ theme }) => theme.colors.gray900};
  background: ${({ theme }) => theme.colors.surface};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  box-shadow: inset 0px 7px 7px -5px rgba(47, 60, 79, 0.12);
`;

const BoxTitle = styled.span.attrs(() => ({ className: 'accordion-box-title' }))``;

const Arrow = styled(BaseArrow)`
  transition: transform 100ms ease-in;
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 90)}deg);
`;

export const AccordionSummaryContainer = styled(FlexSpace)`
  background: ${({ theme }) => theme.colors.fields.drawer};
  padding: 6px;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  height: 48px;
  ${({ sticky }) =>
    sticky &&
    `position: sticky;
  top: 0;
  z-index: 1;`}
`;

const AccordionDetailsContainer = styled(FlexSpace)`
  width: 100%;
  background: ${({ theme }) => theme.colors.surface};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
`;

const AccordionSummary = ({ title, isOpen, onClick, sticky }) => (
  <AccordionSummaryContainer onClick={onClick} sticky={sticky}>
    <Header2>{title}</Header2>
    <CircleIcon icon={Arrow} $isOpen={isOpen} hideBackground />
  </AccordionSummaryContainer>
);

const AccordionDetails = ({ isOpen, children, lazy }) => {
  return (
    <AccordionDetailsContainer isOpen={isOpen}>
      {lazy && isFunction(children) ? children({ isOpen }) : children}
    </AccordionDetailsContainer>
  );
};

const AccordionItem = ({ title, isOpen, onClick, children, lazy, sticky }) => (
  <div>
    <AccordionSummary {...{ title, isOpen, onClick, sticky }} />
    <AccordionDetails {...{ isOpen, children, lazy }} />
  </div>
);

const Box = ({ title, isOpen, onClick, children, lazy, onItemClick }) => {
  const onClickHandler = useCallback(() => {
    onItemClick?.(isOpen);
    onClick?.();
  }, [isOpen, onClick, onItemClick]);
  return (
    <>
      <BoxWrapper onClick={onClickHandler} className="group">
        <BoxTitle>{title}</BoxTitle>
        <CircleIcon icon={Arrow} $isOpen={isOpen} />
      </BoxWrapper>
      <BoxContent isOpen={isOpen} className="content">
        {lazy && isFunction(children) ? children({ isOpen }) : children}
      </BoxContent>
    </>
  );
};

const Accordion = ({ className, defaultOpenRows, children, sticky = false }) => {
  const [openRows, setOpenRows] = useState(() => defaultOpenRows || {});
  const childWithProps = React.Children.toArray(children).filter((child) => !!child.props);

  const createElement = (child, index) => {
    return React.cloneElement(child, {
      sticky,
      isOpen: openRows[index],
      onClick: () => setOpenRows({ ...openRows, [index]: !openRows[index] })
    });
  };

  return <FlexColumn className={className}>{React.Children.map(childWithProps, createElement)}</FlexColumn>;
};

Accordion.Box = Box;
Accordion.Item = AccordionItem;

export default Accordion;
