import { useContext, useMemo } from 'react';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const useTranslatedOptions = (options) => {
  const { t } = useContext(ConfigContext);

  const translatedOptions = useMemo(() => options.map((option) => ({ ...option, label: t(option.label) })), [
    options,
    t
  ]);

  return translatedOptions;
};

export default useTranslatedOptions;
