import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { SORT_BY_STRING } from '@ubeya/shared/utils/sorting';
import useCRM from '@ubeya/shared/hooks/account/useCRM';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const EmptyOptions = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
`;

const PreferredClientsFilter = ({ defaultValue, handleClose, onApply, search, onChange, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const { clients } = useCRM();
  const [values, setValues] = useState(defaultValue || []);

  const clientsOptions = useMemo(() => (clients || []).map(({ id, name }) => ({ value: id, label: name })), [clients]);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      (
        (search
          ? clientsOptions.filter(
              ({ label }) =>
                t('preferredClients').toLowerCase().includes(search.toLowerCase()) ||
                label.toLowerCase().includes(search.toLowerCase())
            )
          : clientsOptions) || []
      ).sort(SORT_BY_STRING('label')),
    [clientsOptions, search, t]
  );

  if (filteredOptions?.length === 0) {
    return null;
  }

  return (
    <FilterLayout
      name={t('preferredClients')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions && filteredOptions?.length > 0 ? (
        filteredOptions.map(({ value, label }) => (
          <DropDown.Item key={value}>
            <CheckBox
              name={`client-${value}`}
              value={values.includes(value)}
              label={label}
              onChange={() => handleChange(value)}
            />
          </DropDown.Item>
        ))
      ) : (
        <EmptyOptions>{t('noOptions')}</EmptyOptions>
      )}
    </FilterLayout>
  );
};

export default PreferredClientsFilter;
