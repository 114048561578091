import { createCachedSelector } from 're-reselect';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import * as api from '../../services/api';
import { mappedArray } from '../../utils/array';
import { SORT_BY_STRING } from '../../utils/sorting';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data, t) => t,
  (data, t) => {
    const suppliers = data || [];
    const activeSuppliers = suppliers.filter(({ isDeleted }) => !isDeleted);
    const inactiveSuppliers = suppliers.filter(({ isDeleted }) => isDeleted);
    const mappedSuppliers = mappedArray(suppliers, (item) => item.accountId);
    const mappedActiveSuppliers = mappedArray(activeSuppliers, (item) => item.accountId);

    const suppliersOptions = activeSuppliers.reduce(
      (prev, { name, branches }) =>
        [
          ...prev,
          ...branches.map((branch) => ({
            value: branch.id,
            label: name === branch.name ? name : `${name} - ${branch.name}`
          }))
        ]?.sort(SORT_BY_STRING('label')),
      []
    );

    const suppliersAccountsOptions = activeSuppliers
      .reduce((prev, { name, accountId }) => [...prev, { value: accountId, label: name }], [])
      ?.sort(SORT_BY_STRING('label'));

    const mappedSupplierBranches = suppliers.reduce(
      (prev, supplier) => ({
        ...prev,
        ...(supplier?.branches || []).reduce(
          (all, { id, name, timesheetConfig }) => ({
            ...all,
            [id]: {
              ...supplier,
              timesheetConfig,
              name: supplier.name === name ? supplier.name : `${supplier.name} - ${name}`
            }
          }),
          {}
        )
      }),
      {}
    );

    const mappedSupplierBranchesByAccount = activeSuppliers.reduce(
      (all, { accountId, branches }) => ({
        ...all,
        [accountId]: branches.map((branch) => ({
          value: branch.id,
          label: branch.name
        }))
      }),
      {}
    );

    const mappedSupplierPositions = suppliers.reduce(
      (prev, supplier) => ({
        ...prev,
        ...(supplier?.positions || []).reduce(
          (all, { id, ...position }) => ({
            ...all,
            [id]: { ...position, slug: t(position.slug), label: t(position.slug) }
          }),
          {}
        )
      }),
      {}
    );

    const hasSuppliers = suppliers.length > 0;

    const hasEngagedSuppliers = activeSuppliers.filter(({ isActivated }) => !!isActivated)?.length > 0;

    return {
      suppliers,
      activeSuppliers,
      inactiveSuppliers,
      mappedActiveSuppliers,
      mappedSuppliers,
      suppliersOptions,
      mappedSupplierBranchesByAccount,
      suppliersAccountsOptions,
      mappedSupplierBranches,
      mappedSupplierPositions,
      hasSuppliers,
      hasEngagedSuppliers
    };
  }
)({
  keySelector: (data, t, storeKey) => storeKey.join('#')
});

const useSuppliers = (clientBranchId) => {
  const { t } = useContext(ConfigContext);
  const { accountId } = useAccount();
  const storeKey = ['suppliers', accountId, clientBranchId];
  const { isLoading, data } = useQuery(storeKey, () => api.fetchSuppliers({ accountId, clientBranchId }), {
    enabled: !!accountId,
    select: (suppliersData) => selector(suppliersData, t, storeKey)
  });

  const {
    suppliers = [],
    hasSuppliers,
    hasEngagedSuppliers,
    inactiveSuppliers = [],
    activeSuppliers = [],
    suppliersOptions = [],
    mappedSupplierBranchesByAccount = {},
    suppliersAccountsOptions = [],
    mappedSupplierBranches = {},
    mappedSupplierPositions = {},
    mappedActiveSuppliers = {},
    mappedSuppliers = {}
  } = data || {};

  return {
    isLoading,
    suppliers,
    hasSuppliers,
    hasEngagedSuppliers,
    inactiveSuppliers,
    activeSuppliers,
    mappedSupplierBranchesByAccount,
    suppliersOptions,
    suppliersAccountsOptions,
    mappedSupplierBranches,
    mappedSuppliers,
    mappedSupplierPositions,
    mappedActiveSuppliers
  };
};

export default useSuppliers;
