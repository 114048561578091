import React from 'react';
import styled from 'styled-components';
import BaseRichTextEditor from 'react-rte';

const RichTextEditor = styled(BaseRichTextEditor)`
  border: none;
  font-family: inherit;

  span[class^='ImageSpan_'] {
    letter-spacing: 0 !important;
    background-size: contain !important;
    width: 100% !important;
    height: auto !important;
    font-size: medium !important;
    line-height: 200px !important;
  }

  .public-DraftEditor-content {
    padding: 0;
  }
`;

const DescriptionField = ({ metadata }) => {
  const { text } = metadata || {};

  if (!text) {
    return null;
  }

  return <RichTextEditor value={RichTextEditor.createValueFromString(text, 'markdown')} readOnly />;
};

export default DescriptionField;
