import React, { useMemo } from 'react';
import styled, { StyleSheetManager, useTheme } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import { useTranslation } from 'react-i18next';
import useUser from '@ubeya/shared/hooks/auth/useUser';
import { FlexColumn, FlexCenter, Loader, ErrorBoundary, ModalProvider } from '@ubeya/shared-web/components';
import useTranslatedApp from '@ubeya/shared/hooks/useTranslatedApp';
import GlobalStyle from '@ubeya/shared-web/style/global';
import { media } from '@ubeya/shared-web/style';
import { ConfigContextProvider, contextDefaultValues } from '@ubeya/shared-web/contexts/ConfigContext';
import SideBar from './SideBar';

const Dashboard = React.lazy(() => import('dashboard'));
const Accounts = React.lazy(() => import('accounts'));
const Logos = React.lazy(() => import('logos'));
const Admins = React.lazy(() => import('admins'));
const Users = React.lazy(() => import('users'));
const B2BClients = React.lazy(() => import('b2bClients'));
const Reports = React.lazy(() => import('reports'));
const Account = React.lazy(() => import('account'));
const Admin = React.lazy(() => import('admin'));
const Billing = React.lazy(() => import('billing'));
const BillingHistory = React.lazy(() => import('billingHistory'));
const Operations = React.lazy(() => import('operations'));

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  ${media.tablet`grid-template-columns: auto 1fr; grid-template-rows: 1fr;`}

  .app-side-bar-wrapper {
    background: ${({ theme }) => theme.colors.backgroundDark};
  }
`;

const Container = styled(FlexColumn)`
  flex: 1;
  overflow: hidden;
`;

const LoaderWrapper = styled(FlexCenter)`
  height: 100vh;
`;

const AuthorizedApp = React.memo(({ language, children }) => {
  const isRtl = useTranslatedApp(language);
  const rtlSupport = isRtl ? [stylisRTLPlugin] : undefined;

  return (
    <StyleSheetManager stylisPlugins={rtlSupport}>
      <>
        <GlobalStyle />

        <Wrapper>
          <SideBar />
          <Container>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Container>
        </Wrapper>
      </>
    </StyleSheetManager>
  );
});

const AppShell = ({ children }) => {
  const { isLoading: userLoading, isAuth, language } = useUser();

  if (userLoading || !isAuth) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return <AuthorizedApp language={language}>{children}</AuthorizedApp>;
};

const App = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const configContext = useMemo(() => ({ ...contextDefaultValues, t, theme }), [t, theme]);

  return (
    <ConfigContextProvider config={configContext}>
      <AppShell>
        <React.Suspense
          fallback={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }>
          <ModalProvider>
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/accounts/:accountId" component={Account} />
              <Route path="/accounts" component={Accounts} />
              <Route path="/logos" component={Logos} />
              <Route path="/admins/:adminId" component={Admin} />
              <Route path="/admins" component={Admins} />
              <Route path="/appUsers" component={Users} />
              <Route path="/b2bClients" component={B2BClients} />
              <Route path="/reports" component={Reports} />
              <Route path="/billing" component={Billing} />
              <Route path="/billingHistory" component={BillingHistory} />
              <Route path="/operations" component={Operations} />

              <Redirect to="/dashboard" />
            </Switch>
          </ModalProvider>
        </React.Suspense>
      </AppShell>
    </ConfigContextProvider>
  );
};

export default App;
