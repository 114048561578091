const checkSpecialChars = (value) => /^[=|+|\-|@]/.test(value);

export const removeSpecialCharsOfData = (data) => {
  (data || []).forEach((row) => {
    Object.entries(row).forEach(([key, value]) => {
      let checking = true;
      let newValue = value;

      while (checking) {
        if (typeof newValue === 'string' && checkSpecialChars(newValue)) {
          row[key] = newValue.slice(1);
          newValue = newValue.slice(1);
        } else {
          checking = false;
        }
      }
    });
  });

  return data;
};
