import { useContext, useEffect } from 'react';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import useAccount from './account/useAccount';

export const changePageTitle = (mainTitle, subTitle, t) => {
  document.title = `${mainTitle} | ${t(subTitle)}`;
};

export const usePageTitle = (subTitle, onReturnTitle = null) => {
  const { t } = useContext(ConfigContext);

  const { account } = useAccount();

  useEffect(() => {
    const name = account?.name || 'Ubeya';
    changePageTitle(name, subTitle, t);
    return () => onReturnTitle && changePageTitle(name, onReturnTitle, t);
  }, [t, subTitle, onReturnTitle, account?.name]);
};
