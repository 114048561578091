export const PLANS = [
  {
    name: 'Your current plan',
    price: 'Free',
    slug: 'Free',
    features: ['Worker app', 'Manual scheduling', 'Limited timesheet']
  },
  {
    name: 'Basic',
    price: '4$',
    slug: 'Basic',
    features: ['Scheduling', 'Availability check', 'Timeseet', 'CRM', 'Feed & Chat'],
    upgradeLink: 'basic',
    minimumRequirements: 'Minimum $50/mo.'
  },
  {
    name: 'Pro',
    showTopSeller: true,
    price: '7$',
    slug: 'Pro',
    features: ['2 branches', 'Advanced time capturing', 'Payroll', 'Workflows', 'Financial Reports'],
    upgradeLink: 'pro',
    minimumRequirements: 'Minimum $259/mo.'
  },
  {
    name: 'Super',
    slug: 'Super',
    price: '10$',
    features: ['5 branches', 'Compliance', 'Payroll integration', 'ATS integration', 'API access', 'Dedicated CSM'],
    upgradeLink: 'super',
    minimumRequirements: 'Minimum $499/mo.'
  }
];
