import React from 'react';
import styled from 'styled-components';
import { BodySemiBold } from '../Typography';

const Label = styled(BodySemiBold)`
  color: ${({ theme, score }) =>
    score < 4 ? theme.colors.error : score < 7 ? theme.colors.warning : theme.colors.success};
  border-radius: 8px;
  padding: 4px 10px;
  margin-right: 2px;
  font-size: ${({ size }) => `${size}px`};
`;

const Score = ({ score, size = 17 }) => (
  <Label score={score} size={size}>
    {score}
  </Label>
);

export default Score;
