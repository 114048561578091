import React, { useContext } from 'react';
import moment from 'moment';
import { API_TIME_FORMAT } from '@ubeya/shared/constants';
import { FlexMiddle } from '../../Flex';
import ConfigContext from '../../../contexts/ConfigContext';

const StartTimeChip = ({ value }) => {
  const { timeFormat } = useContext(ConfigContext);

  if (!Array.isArray(value)) {
    return <FlexMiddle>{moment(value, API_TIME_FORMAT).format(timeFormat)}</FlexMiddle>;
  }

  return <FlexMiddle>{value.map((item) => moment(item, API_TIME_FORMAT).format(timeFormat)).join(', ')}</FlexMiddle>;
};

export default StartTimeChip;
