import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { responsiveShow, scrollbar } from '../../style';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';
import { FlexColumn } from '../Flex';

const Wrapper = styled.div`
  position: relative;
  ${responsiveShow.tablet};
  flex: 1;
  width: 262px;
  max-width: 262px;
  min-width: 262px;
  height: 502px;
  max-height: 502px;
  min-height: 502px;

  > svg {
    width: 262px;
    height: 502px;
    position: absolute;
  }
`;

const Content = styled(FlexColumn)`
  position: absolute;
  top: 45px;
  left: 14px;
  right: 16px;
  bottom: 45px;
  overflow: hidden auto;
  padding: 10px;

  .document-wrapper {
    margin-top: 10px;
    width: 100%;
  }

  ${scrollbar};
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;

const AppPreview = ({ children, className }) => (
  <Form
    onSubmit={() => {}}
    render={() => (
      <Wrapper className={className}>
        <PhoneIcon />
        <Content>{children}</Content>
      </Wrapper>
    )}
  />
);

export default AppPreview;
