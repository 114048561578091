import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';

const OPTIONS = {
  supplier_order_approved: 'filterOrderStatusApproved',
  changes_requested: 'filterOrderStatusPendingChanges',
  pending_your_approval: 'filterOrderStatusPendingApproval',
  client_order_pending_approval: 'filterOrderStatusPendingClientApproval',
  supplier_order_pending_approval: 'filterOrderStatusPendingSupplierApproval',
  order_canceled: 'filterOrderStatusCanceledOrder'
};

const OrderStatusChip = ({ value }) => {
  const { t } = useContext(ConfigContext);

  return <>{value.map((item) => t(OPTIONS[item])).join(', ')}</>;
};

export default OrderStatusChip;
