import React from 'react';
import useAdmins from '@ubeya/shared/hooks/account/useAdmins';
import { FlexMiddle } from '../../Flex';

const EventBookerChip = ({ value }) => {
  const { mappedAdmins } = useAdmins();

  return (
    <FlexMiddle>
      {value.map((item) => `${mappedAdmins[item]?.firstName} ${mappedAdmins[item]?.lastName}`).join(', ')}
    </FlexMiddle>
  );
};

export default EventBookerChip;
