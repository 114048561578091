import React from 'react';
import styled from 'styled-components';
import { User as BaseUser } from '../Avatar';

const User = styled(BaseUser)`
  .name {
    max-width: 150px;
  }
`;

const NameRenderer = ({
  rowData: data,
  columnData: { onClick, showCaptain, showRating, withUnderline } = {},
  renderBottom,
  renderNext,
  firstName,
  lastName
}) => (
  <User
    image={data.image}
    warnings={data.warnings}
    firstName={firstName ?? data.firstName}
    lastName={lastName ?? data.lastName}
    isAdmin={data.isAdmin}
    adminPrivileges={data.adminPrivileges}
    captainType={data.captainType}
    showCaptain={showCaptain}
    showRating={showRating}
    rating={data.rating}
    isPending={data.isPending}
    onClick={onClick}
    withUnderline={withUnderline}
    renderBottom={renderBottom}
    renderNext={renderNext}
  />
);

export default NameRenderer;
